import {
  QuestionType,
  AllQuestions,
  QuestionState,
  Question
} from 'model/assessment'

export const INITIAL_QUESTIONS: AllQuestions = {
  allQuestions: [
    {
      id: '',
      questionText: '',
      description: '',
      employerFollowupQuestions: [],
      questionType: QuestionType.SINGLE_SELECT,
      order: 1,
      subOrder: null,
      selectedAnswerIds: [],
      assessmentAnswers: [
        {
          id: '',
          answerText: '',
          order: '',
          hasFollowupQuestion: false,
          followupQuestionIds: null,
          hasWeight: false
        }
      ]
    }
  ]
}

export const QUESTION_STATE_INITIAL_VALUES: QuestionState = {
  questionsToSubmit: [],
  parentQuestionSelectedIds: [],
  parentQuestionHasFollowup: false
}

export const INITIAL_QUESTION: Question = {
  id: '',
  questionText: '',
  description: '',
  questionType: QuestionType.SINGLE_SELECT,
  employerFollowupQuestions: [],
  order: 1,
  subOrder: null,
  assessmentAnswers: [
    {
      id: '',
      answerText: '',
      order: '',
      hasFollowupQuestion: false,
      followupQuestionIds: null,
      hasWeight: false
    }
  ],
  selectedAnswerIds: []
}
