/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
import React from 'react'
import { Box, Typography, styled } from '@mui/material'
import { animated, easings, useSpring } from 'react-spring'

const ProgressBenchmarkWrapper = styled('div')(() => ({
  position: 'relative',
  width: '100%'
}))

const ProgressBenchmarkContainer = styled(animated.div)(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
  height: '40px',
  background: 'transparent',
  borderRight: '4px dashed #FCA625;',
  position: 'absolute',
  top: '-36px'
}))

const ProgressBenchmarkTextContainer = styled(animated.div)(() => ({
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'flex-end',
  height: '40px'
}))

interface IProps {
  benchmarkScore: number
}

const ProgressBenchmark = ({ benchmarkScore }: IProps) => {
  // Normalize the benchmark score to a percentage based on the max width of the Foreground set at 95%
  const benchmarkScorePercentage = (benchmarkScore / 25) * 95
  const barAnimation = useSpring({
    from: {
      width: '0%'
    },
    to: {
      width: `${Number(benchmarkScorePercentage)}%`
    },
    config: { duration: 2000, easing: easings.easeInOutQuart }
  })
  const textAnimation = useSpring({
    from: {
      width: '20%'
    },
    to: {
      width:
        benchmarkScorePercentage > 10 && benchmarkScorePercentage <= 85
          ? `${Number(benchmarkScorePercentage) + 10}%`
          : benchmarkScorePercentage > 85
          ? '95%'
          : '20%'
    },
    config: { duration: 2000, easing: easings.easeInOutQuart }
  })

  return (
    <ProgressBenchmarkWrapper>
      <ProgressBenchmarkContainer style={barAnimation} />
      <ProgressBenchmarkTextContainer style={textAnimation}>
        <Typography
          sx={{
            fontFamily: 'IBM Plex Sans',
            fontSize: '12px',
            fontWeight: 700,
            textTransform: 'uppercase',
            letterSpacing: '2.04px'
          }}
        >
          <span style={{ color: '#747779' }}>Mathison Avg:</span>{' '}
          {benchmarkScore}
        </Typography>
      </ProgressBenchmarkTextContainer>
    </ProgressBenchmarkWrapper>
  )
}
export default ProgressBenchmark
