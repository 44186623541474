import React, { useContext } from 'react'
import { Paper, Grid, GridProps, PaperProps, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import SingleSelectQuestion from './SingleSelectQuestion'
import MultiSelectQuestion from './MultiSelectQuestion'
import { QuestionType } from 'model/assessment'
import { Color } from 'components/SharedStyles'
import { AssessmentContext } from 'context/Assessment'

const QuestionContainer = styled(Paper)<PaperProps>(() => ({
  width: '100%',
  padding: '32px 0'
}))

export const DividerLine = styled(Grid)<GridProps>(() => ({
  borderTop: `1px solid ${Color.border}`
}))

const QuestionContent = () => {
  const { activeQuestion, questionOrder } = useContext(AssessmentContext)

  const questionLabel = `0${questionOrder}`.slice(-2)
  return (
    <QuestionContainer variant='outlined'>
      <Typography
        variant='subtitle1'
        fontWeight={700}
        letterSpacing={'0.12em'}
        textTransform='uppercase'
        margin='0 0 16px 32px'
      >
        {activeQuestion?.employerFollowupQuestions
          ? `Question ${questionLabel}`
          : 'Follow-up'}
      </Typography>
      <DividerLine item xs={12} />
      <Typography variant='h5' margin='16px 32px 32px' width='70%'>
        {activeQuestion?.questionText}
      </Typography>
      {activeQuestion?.questionType === QuestionType.SINGLE_SELECT ? (
        <SingleSelectQuestion />
      ) : (
        <>
          <Typography
            variant='h6'
            margin='-16px 32px 24px'
            sx={{ fontWeight: 400, letterSpacing: '-0.02em' }}
          >
            Please select all that apply.
          </Typography>
          <MultiSelectQuestion />
        </>
      )}
    </QuestionContainer>
  )
}

export default QuestionContent
