import React, { useState, useContext, useEffect } from 'react'
import {
  Box,
  Grid,
  GridProps,
  Typography,
  Accordion,
  AccordionDetails,
  AccordionSummary
} from '@mui/material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import { styled } from '@mui/material/styles'
import { theme, Color } from 'components/SharedStyles'
import { AssessmentContext } from 'context/Assessment'

const StyledAccordion = styled(Accordion)(() => ({
  width: '100%',
  backgroundColor: '#F7F7F7',
  border: 'none',
  boxShadow: 'none',
  '&:before': {
    display: 'none'
  },
  '&.Mui-expanded': {
    margin: '0'
  },
  '&.MuiPaper-root': {
    borderRadius: '8px'
  }
}))

const AccordionContainer = styled(Grid)<GridProps>(() => ({
  [theme.breakpoints.up('sm')]: {
    margin: '30px 0 0 0'
  },
  [theme.breakpoints.up('lg')]: {
    margin: '0 0 0 28px'
  }
}))

const StyledAccordionDetails = styled(AccordionDetails)(() => ({
  padding: '0 24px 28px 24px',
  boxSizing: 'border-box',
  maxHeight: '100%'
}))

const QuestionTermsDropdown = () => {
  const [expanded, setExpanded] = useState(false)
  const { parentQuestion } = useContext(AssessmentContext)
  const [isLoading, setIsLoading] = useState(false)
  useEffect(() => {
    setIsLoading(true)
    setExpanded(false)
  }, [parentQuestion])
  useEffect(() => {
    if (isLoading) {
      setIsLoading(false)
    }
  }, [isLoading])
  if (isLoading) {
    return <></>
  }
  return (
    <AccordionContainer item xs={12}>
      <StyledAccordion expanded={expanded}>
        <AccordionSummary
          expandIcon={<ArrowDropDownIcon sx={{ color: '#050B0E' }} />}
          onClick={() => setExpanded(!expanded)}
          sx={{
            height: '64px',
            marginRight: '-2.5px',
            '& svg': {
              fontSize: 29
            }
          }}
        >
          <Typography
            variant='formLabel'
            sx={{ letterSpacing: 'normal', paddingLeft: '7px' }}
          >
            What you need to know
          </Typography>
        </AccordionSummary>
        <Box sx={{ height: 'inherit' }}>
          <StyledAccordionDetails>
            <Box
              sx={{
                [theme.breakpoints.up('lg')]: {
                  maxHeight: '389px'
                },
                paddingRight: '24px',
                overflowY: 'scroll',
                '&body': {
                  scrollbarWidth: '8px',
                  scrollbarColor: Color.black40
                },
                '&::-webkit-scrollbar': {
                  width: '8px'
                },
                '&::-webkit-scrollbar-track': {
                  backgroundColor: 'none'
                },
                '&::-webkit-scrollbar-thumb': {
                  backgroundColor: Color.black40,
                  borderRadius: '56px'
                }
              }}
            >
              <Typography variant='body2'>
                {parentQuestion.description}
              </Typography>
            </Box>
          </StyledAccordionDetails>
        </Box>
      </StyledAccordion>
    </AccordionContainer>
  )
}

export default QuestionTermsDropdown
