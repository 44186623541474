/* eslint-disable react/jsx-no-useless-fragment */
import React, { useContext, useState } from 'react'
import { styled } from '@mui/material/styles'
import { Grid, Typography, GridProps } from '@mui/material'
import { DashboardContext } from 'context/Dashboard'
import { useQuery } from '@apollo/client'
import { CHECK_EMPLOYER_ASSESSMENT_STATUS } from 'hooks/useDashboard/gql'
import { MicroServiceContext } from 'context/MicroService'
import { StyledSnackbar } from 'pages/Dashboard/ScoreDashboard'
import ClearIcon from '@mui/icons-material/Clear'
import DownloadDashboardButton from './DownloadDashboard'
import ScoreDashboard from './ScoreDashboard'
import PageCarousel from './PageCarousel'
import { PageSlides } from './PageCarousel/constants'

const TitleContainer = styled(Grid)<GridProps>(() => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between'
}))
const Dashboard = () => {
  const { hasCompletedAnAssessment } = useContext(DashboardContext)
  const { hasAccessToViewDashboard } = useContext(MicroServiceContext)

  const { data, loading } = useQuery(CHECK_EMPLOYER_ASSESSMENT_STATUS)

  const assessmentStarted =
    data?.employerAssessment !== null && data?.employerAssessment?.id

  const [isVisible, setIsVisible] = useState(false)
  const [downloadClicked, setDownloadClicked] = useState(false)
  const [snackbarOpen, setSnackbarOpen] = useState(false)
  const [selectedComponents, setSelectedComponents] = useState<string[]>([
    'Current Score',
    'Top Strength & Opportunity',
    'Development Stage',
    'Key Recommendations',
    'Progress by Category',
    'Key Strengths'
  ])

  if (loading) return <></>

  return (
    <Grid sx={{ width: '100%' }}>
      <StyledSnackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{ padding: '12px 17px', marginTop: '75px' }}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Typography sx={{ display: 'flex', alignItems: 'center' }}>
          PDF Downloaded
          <ClearIcon
            onClick={() => setSnackbarOpen(false)}
            sx={{
              marginLeft: '20px',
              fontSize: '16px',
              '&:hover': { cursor: 'pointer' }
            }}
          />
        </Typography>
      </StyledSnackbar>
      <TitleContainer>
        <Typography variant='h1' marginBottom={1}>
          EI Dashboard
        </Typography>
        {hasCompletedAnAssessment && hasAccessToViewDashboard && (
          <DownloadDashboardButton
            isVisible={isVisible}
            setIsVisible={setIsVisible}
            downloadClicked={downloadClicked}
            setDownloadClicked={setDownloadClicked}
            setSnackbarOpen={setSnackbarOpen}
            selectedComponents={selectedComponents}
            setSelectedComponents={setSelectedComponents}
          />
        )}
      </TitleContainer>

      {assessmentStarted || hasCompletedAnAssessment ? (
        <ScoreDashboard
          isVisible={isVisible}
          setIsVisible={setIsVisible}
          downloadClicked={downloadClicked}
          selectedComponents={selectedComponents}
        />
      ) : (
        <PageCarousel slides={PageSlides} />
      )}
    </Grid>
  )
}
export default Dashboard
