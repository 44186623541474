import React from 'react'
import { Typography, Grid } from '@mui/material'

const EmptyState = () => {
  return (
    <>
      <Grid container padding='0 32px'>
        <Typography variant='h5' margin='56px auto 87px auto'>
          This is where you’ll see your recently completed goals.
        </Typography>
      </Grid>
    </>
  )
}

export default EmptyState
