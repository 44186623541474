import { gql } from '@apollo/client'

export const EmployerAssessmentQuestion = gql`
  fragment employerAssessmentQuestion on EmployerAssessmentQuestion {
    id
    questionText
    order
    isCompleted
    description
    questionType
    selectedAnswerIds
    assessmentAnswers {
      hasFollowupQuestion
      id
      followupQuestionIds
      answerText
      order
      hasWeight
    }
    employerFollowupQuestions {
      id
      assessmentQuestionId
      questionText
      description
      questionType
      order
      subOrder
      totalScore
      selectedAnswerIds
      assessmentAnswers {
        id
        answerText
        order
        hasFollowupQuestion
        followupQuestionIds
        hasWeight
      }
    }
  }
`
export const EmployerAssessment = gql`
  fragment employerAssessment on EmployerAssessment {
    id
    totalScore
    acquiredScore
    progress
    status
    employerAssessmentCategories {
      id
      categoryId
      name
      slug
      description
      totalScore
      acquiredScore
      progress
      isCompleted
      employerAssessmentQuestions {
        id
        questionText
        order
        isCompleted
        description
        questionType
        selectedAnswerIds
        assessmentAnswers {
          hasFollowupQuestion
          id
          followupQuestionIds
          answerText
          order
          hasWeight
        }
        employerFollowupQuestions {
          id
          assessmentQuestionId
          questionText
          description
          questionType
          subOrder
          selectedAnswerIds
          assessmentAnswers {
            id
            answerText
            order
            hasFollowupQuestion
            followupQuestionIds
            hasWeight
          }
        }
      }
    }
  }
`

export const GET_EMPLOYER_ASSESSMENT = gql`
  query GetEmployerAssessment {
    employerAssessment {
      ...employerAssessment
    }
  }
  ${EmployerAssessment}
`

export const GET_FOLLOWUP_QUESTIONS = gql`
  query ($assessmentQuestionIds: ID!) {
    assessmentQuestions(assessmentQuestionIds: $assessmentQuestionIds) {
      id
      questionText
      description
      questionType
      order
      subOrder
      totalScore

      assessmentAnswers {
        id
        answerText
        order
        hasFollowupQuestion
        followupQuestionIds
        hasWeight
      }
    }
  }
`

// Mutations

export const UPDATE_QUESTION = gql`
  mutation (
    $id: ID!
    $employerAssessmentId: ID!
    $selectedAnswerIds: [ID]
    $followupQuestions: [EmployerAssessmentFollowupQuestionInput!]
  ) {
    updateEmployerAssessmentQuestion(
      input: {
        id: $id
        employerAssessmentId: $employerAssessmentId
        selectedAnswerIds: $selectedAnswerIds
        followupQuestions: $followupQuestions
      }
    ) {
      ...employerAssessmentQuestion
    }
  }
  ${EmployerAssessmentQuestion}
`
