import React, { ReactElement, useContext } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import { AssessmentProvider } from 'context/Assessment'
import { MicroServiceContext } from 'context/MicroService'
import { AssessmentOverviewProvider } from 'context/AssessmentOverview'
import AssessmentQuestion from './AssessmentQuestion'
import AssessmentOverview from './AssessmentOverview'

const Assessment = (): ReactElement => {
  const navigate = useNavigate()
  const { hasAccessToViewAssessment } = useContext(MicroServiceContext)

  if (!hasAccessToViewAssessment) {
    navigate('/')
  }

  return (
    <Routes>
      <Route
        path=''
        element={
          <AssessmentOverviewProvider>
            <AssessmentOverview />
          </AssessmentOverviewProvider>
        }
      />
      <Route
        path='/:slug/:questionOrder'
        element={
          <AssessmentProvider>
            <AssessmentQuestion />
          </AssessmentProvider>
        }
      />
    </Routes>
  )
}

export default Assessment
