import React, { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
  Grid,
  GridProps,
  Button,
  IconButton,
  Box,
  ButtonProps
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { ArrowLeftIcon, ArrowRightIcon } from 'components'
import { Color } from 'components/SharedStyles'
import FollowUpModal from '../FollowUpModal'
import { AssessmentContext } from 'context/Assessment'
import { Answer, Question } from 'model/assessment'

const SpaceBetweenContainer = styled(Grid)<GridProps>(() => ({
  display: 'flex',
  justifyContent: 'space-between'
}))

const DoneButton = styled(Button)<ButtonProps>(() => ({
  color: Color.white,
  backgroundColor: Color.black,
  marginLeft: '16px',
  '&:hover': {
    color: Color.white,
    backgroundColor: Color.black
  }
}))

const ExitButton = styled(Button)<ButtonProps>(() => ({
  minWidth: '24px',
  padding: 0,
  textDecoration: 'underline',
  '&:hover': {
    textDecoration: 'underline',
    backgroundColor: 'transparent'
  }
}))
enum Action {
  goBack = 'goBack',
  goNext = 'goNext',
  goExit = 'goExit'
}
const QuestionNavigation = () => {
  const {
    parentQuestion,
    followupIds,
    submitAnswers,
    onLoadFollowupQuestions,
    activeQuestion,
    syncSelectedAnswersToCache,
    activeSelectedAnswerIds,
    currentCategory,
    activeCachedAnswers
  } = useContext(AssessmentContext)
  const { employerFollowupQuestions } = parentQuestion
  const isFollowup = followupIds?.length > 0
  const isAllFollowupQuestionsAnswered = !employerFollowupQuestions.some(
    (employerFollowupQuestion: Question) =>
      employerFollowupQuestion.selectedAnswerIds.length === 0
  )
  const isLastQuestion =
    currentCategory!.employerAssessmentQuestions!.at(-1)!.id ===
    parentQuestion.id
  const isFollowupQuestionLoaded = employerFollowupQuestions.length !== 0
  const { slug, questionOrder }: any = useParams()
  const Redirect = {
    next: `../${slug}/${Number(questionOrder) + 1}`,
    back: `../${slug}/${Number(questionOrder) - 1}`,
    exit: '/equity-index/assessment'
  }
  const navigate = useNavigate()
  const [followupCallback, setFollowupCallback] = useState<any>()
  const [cacheUpdateCallbackAction, setCacheUpdateCallbackAction] = useState<
    string | undefined
  >()

  const validateAndSubmit = (action: string, navigateWithoutSubmit: any) => {
    const isAnswerChanged = !activeQuestion!.selectedAnswerIds.equalsUnordered(
      activeSelectedAnswerIds
    )
    const isThereAnswerToBeSubmitted = activeSelectedAnswerIds.length !== 0

    if (!isAllFollowupQuestionsAnswered && !isThereAnswerToBeSubmitted) {
      setFollowupCallback(() => navigateWithoutSubmit)
      return
    }

    const shouldLoadFollowupQuestions = activeCachedAnswers.some(
      (answer: Answer) => answer.hasFollowupQuestion
    )

    if (
      shouldLoadFollowupQuestions &&
      isFollowupQuestionLoaded === false &&
      action !== Action.goNext
    ) {
      setFollowupCallback(() => navigateWithoutSubmit)
      return
    }

    if (isAnswerChanged) {
      syncSelectedAnswersToCache()
      setCacheUpdateCallbackAction(action)
    } else {
      navigateWithoutSubmit()
    }
  }

  useEffect(() => {
    if (cacheUpdateCallbackAction === undefined) {
      return
    }
    const isCacheUpdated = activeQuestion?.selectedAnswerIds.equalsUnordered(
      activeSelectedAnswerIds
    )

    if (isCacheUpdated) {
      setCacheUpdateCallbackAction(undefined)
      switch (cacheUpdateCallbackAction) {
        case Action.goBack:
          goBack()
          return
        case Action.goNext:
          goNext()
          return
        case Action.goExit:
          goExit()
      }
    }
  }, [cacheUpdateCallbackAction, activeQuestion])
  const submitAndNavigate = (navigate: Function) => {
    if (!isFollowup || isFollowupQuestionLoaded) {
      if (isAllFollowupQuestionsAnswered) {
        submitAnswers(() => {
          navigate()
        })
      }
    }
  }
  const goNext = () => {
    if (isFollowup && !isFollowupQuestionLoaded) {
      onLoadFollowupQuestions()
      return
    }

    submitAndNavigate(() => navigate(Redirect.next))
  }
  const goBack = () => {
    submitAndNavigate(() => navigate(Redirect.back))
  }
  const goExit = () => {
    submitAndNavigate(() => navigate(Redirect.exit))
  }

  return (
    <SpaceBetweenContainer item xs={12}>
      {Number(questionOrder) !== 1 ? (
        <IconButton
          onClick={() => {
            validateAndSubmit(Action.goBack, () => {
              navigate(Redirect.back)
            })
          }}
          disableRipple
          disabled={Number(questionOrder) === 1}
          sx={{
            padding: 0,
            height: '48px',
            width: '48px',
            backgroundColor: Color.grey200,
            ':disabled': {
              '& >svg': {
                color: Color.grey200
              }
            }
          }}
        >
          <ArrowLeftIcon color='secondary' />
        </IconButton>
      ) : (
        <div />
      )}
      <Box>
        <FollowUpModal
          followupCallback={followupCallback}
          setFollowupCallback={setFollowupCallback}
        />
        <ExitButton
          variant='text'
          disableRipple
          onClick={() => {
            validateAndSubmit(Action.goExit, () => {
              navigate(Redirect.exit)
            })
          }}
        >
          Exit
        </ExitButton>
        {!isLastQuestion ? (
          <IconButton
            onClick={() => {
              validateAndSubmit(Action.goNext, () => {
                navigate(Redirect.next)
              })
            }}
            disableRipple
            sx={{
              marginLeft: '16px',
              padding: 0,
              height: '48px',
              width: '48px',
              backgroundColor: Color.black
            }}
          >
            <ArrowRightIcon color='primary' />
          </IconButton>
        ) : (
          <DoneButton
            disableRipple
            onClick={() => {
              validateAndSubmit(Action.goExit, () => {
                navigate(Redirect.exit)
              })
            }}
          >
            Done
          </DoneButton>
        )}
      </Box>
    </SpaceBetweenContainer>
  )
}

export default QuestionNavigation
