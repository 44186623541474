/* eslint-disable no-console */
import React, { useMemo } from 'react'
import { styled } from '@mui/material'
import { animated, easings, useSpring } from 'react-spring'

const CATEGORY_BAR_GRADIENT: { [key: string]: string } = {
  'defining-tracking': 'linear-gradient(270deg, #B83282 50%, #E28DC0 99.61%)',
  'attracting-sourcing':
    'linear-gradient(270.04deg, #430DA8 50.04%, #956BDB 94.1%)',
  'interviewing-engaging':
    'linear-gradient(270.03deg, #068D8E 50.03%, #6AC2C4 86.68%)',
  'onboarding-advancing':
    'linear-gradient(270.03deg, #375FC6 50.03%, #829FE9 92.29%)'
}

const ProgressBarContainer = styled(animated.div)(
  ({ gradient }: { gradient: string }) => ({
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
    height: '32px',
    background: gradient,
    borderTopRightRadius: '8px',
    borderBottomRightRadius: '8px'
  })
)

interface IProps {
  category: string | undefined
  currentScore: number | undefined
}

const ProgressBar = ({ currentScore, category }: IProps) => {
  // Normalize the current score to a percentage based on the max width of the Foreground
  const currScorePercentage = currentScore ? (currentScore / 25) * 100 : 0
  const barAnimation = useSpring({
    from: {
      width: '0%'
    },
    to: {
      width: `${Number(currScorePercentage)}%`
    },
    config: { duration: 2000, easing: easings.easeInOutQuart }
  })

  const gradient = useMemo(
    () => (category ? CATEGORY_BAR_GRADIENT[category] : '#F7F7F7'),
    [category]
  )

  return <ProgressBarContainer gradient={gradient} style={barAnimation} />
}

export default ProgressBar
