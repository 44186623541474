import React, { useContext, useEffect, useState } from 'react'
import { Grid, GridProps, Typography, Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Question } from 'model/assessment'
import ActiveQuestion from './ActiveQuestion'
import InactiveQuestion from './InactiveQuestion'
import { Color } from 'components/SharedStyles'
import { AssessmentContext } from 'context/Assessment'
import ProgressBar from './ProgressBar'

const QuestionDetailsContainer = styled(Grid)<GridProps>(() => ({
  width: '100%',
  height: 'auto',
  display: 'flex',
  flexDirection: 'row'
}))

const InactiveQuestionContainer = styled(Grid)<GridProps>(() => ({
  minWidth: '100%',
  height: 'auto',
  display: 'flex',
  flexDirection: 'row'
}))

const QuestionDetails = () => {
  const { parentQuestion, activeQuestion, followupIds } =
    useContext(AssessmentContext)

  const [answeredQuestions, setAnsweredQuestions] = useState<any>([])

  let progress = 0

  if (parentQuestion.selectedAnswerIds.length > 0) {
    progress++
  }

  parentQuestion.employerFollowupQuestions?.forEach(
    (followupQuestion: Question) => {
      if (followupQuestion.selectedAnswerIds.length > 0) {
        progress++
      }
    }
  )
  useEffect(() => {
    const answeredQuestions = []

    const { selectedAnswerIds, employerFollowupQuestions } = parentQuestion

    if (
      selectedAnswerIds.length > 0 &&
      parentQuestion.id !== activeQuestion?.id &&
      parentQuestion.employerFollowupQuestions.length > 0
    ) {
      answeredQuestions.push(parentQuestion)
    }
    employerFollowupQuestions
      ?.slice(0, -1)
      .forEach((employerAssessmentQuestion: Question) => {
        if (employerAssessmentQuestion.selectedAnswerIds.length > 0) {
          answeredQuestions.push(employerAssessmentQuestion)
        }
      })

    setAnsweredQuestions([...answeredQuestions])
  }, [parentQuestion, activeQuestion])

  return (
    <QuestionDetailsContainer container>
      {answeredQuestions.map((answeredQuestion: Question, index: number) => {
        return (
          <InactiveQuestionContainer container key={index}>
            <InactiveQuestion answeredQuestion={answeredQuestion} />
            {index === 0 && (
              <Grid item xs={12} lg={5} paddingLeft=' 27px' marginBottom='24px'>
                <Box
                  sx={{
                    height: '100%',
                    backgroundColor: Color.grey50,
                    paddingTop: '45px',
                    borderRadius: '8px'
                  }}
                >
                  <Typography
                    variant='h6'
                    sx={{
                      fontSize: '14px',
                      letterSpacing: '0.12em',
                      marginBottom: '10px',
                      textAlign: 'center'
                    }}
                  >
                    QUESTION PROGRESS
                  </Typography>
                  <Typography
                    variant='h2'
                    sx={{
                      marginBottom: '20px',
                      textAlign: 'center'
                    }}
                  >{`${progress} / ${followupIds.length + 1}`}</Typography>
                  <ProgressBar
                    progress={(progress / (followupIds.length + 1)) * 100}
                  />
                </Box>
              </Grid>
            )}
          </InactiveQuestionContainer>
        )
      })}
      <ActiveQuestion />
    </QuestionDetailsContainer>
  )
}

export default QuestionDetails
