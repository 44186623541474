import { CarouselSlide } from 'model/assessment'

export const PageSlides: CarouselSlide[] = [
  {
    title: 'Set goals and build your DEI roadmap',
    content:
      'The DEI journey spans across 4 major sections/categories: Defining and Tracking, Attracting and Sourcing, Interviewing and Engaging, and Onboarding and Advancing.',
    image: 'https://www.datocms-assets.com/54533/1680886868-es-slide-1.png'
  },
  {
    title: 'Our metrics let you track, measure, and share your progress',
    content:
      'Take advantage of our Equity Index to communicate your wins to employees, senior leaders and, if you want, externally too.',
    image: 'https://www.datocms-assets.com/54533/1680886879-es-slide-3.png'
  },
  {
    title: 'Transform data-driven insights into strategy and action',
    content:
      "In response to your answers, we'll recommend specific tailored goals. And to help you reach them, we'll equip you with best practices, checklists, case studies, and encouragement.",
    image: 'https://www.datocms-assets.com/54533/1680886874-es-slide-2.png'
  },
  {
    title: "When you're ready, let's get started",
    content:
      "Our assessment is engineered to give you the big picture, showing you where you are on your DEI journey and how you can take next steps — even if it's the first step.",
    image: 'https://www.datocms-assets.com/54533/1680886883-es-slide-4.png'
  }
]
