/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect, useRef } from 'react'
import { Box, BoxProps, Typography, Grid } from '@mui/material'
import { styled } from '@mui/material/styles'
import { dotLocator } from 'utils/dashboardUtils'
import { useTrail, animated, easings } from 'react-spring'

const ProgressCircleContainer = styled(Box)<BoxProps>(() => ({
  position: 'relative',
  width: '175px'
}))

const StyledSvg = styled('svg')(() => ({
  height: '175px',
  width: '175px'
}))

const StyledCircle = styled('circle')(() => ({
  strokeLinecap: 'round',
  fill: 'none',
  strokeWidth: '12px',
  transformOrigin: '50% 50%',
  transform: 'rotate(90deg)',
  strokeDasharray: 499.64,
  strokeDashoffset: 499.64
}))

const colors = ['#B83282', '#38009F', '#068D8E', '#375FC6']
const backgroundColors = ['#F8EBF3', '#ECE7F6', '#E6F4F4', '#E1EAFF']

interface IProps {
  categoryScore: number | undefined
  idx: number
  noAssessmentsCompleted: boolean
  isVisible: boolean
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>
  downloadClicked: boolean
}

const CategoryProgressCircle = ({
  categoryScore,
  idx,
  noAssessmentsCompleted,
  isVisible,
  setIsVisible,
  downloadClicked
}: IProps) => {
  const [totalScore, setTotalScore] = useState<number[]>(
    new Array(Number(categoryScore)).fill(1)
  )
  const myRef = useRef<HTMLDivElement>(null!)
  const [trail, api] = useTrail(Number(categoryScore), () => ({
    opacity: 0
  }))

  useEffect(() => {
    const observer = new IntersectionObserver(entries => {
      const entry = entries[0]
      setIsVisible(entry.isIntersecting)
    })
    observer.observe(myRef.current)
  }, [setIsVisible])

  useEffect(() => {
    setTotalScore(new Array(Number(categoryScore)).fill(1))
  }, [categoryScore])

  useEffect(() => {
    if (isVisible) {
      api.start({
        opacity: 1,
        config: { duration: 100, easing: easings.easeInOutQuart }
      })
    }
  }, [api, totalScore, isVisible])

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        alignItems: 'center'
      }}
    >
      <ProgressCircleContainer ref={myRef}>
        <StyledSvg>
          {dotLocator.map((coordinate, index) => (
            <StyledCircle
              key={index}
              cx={`${coordinate[0]}`}
              cy={`${coordinate[1]}`}
              r='79.65'
              style={{
                stroke: noAssessmentsCompleted
                  ? '#F7F7F7'
                  : backgroundColors[idx]
              }}
            />
          ))}
          {!noAssessmentsCompleted &&
            trail.map((styles, index: number) => (
              <animated.circle
                key={index}
                cx={`${dotLocator[index][0]}`}
                cy={`${dotLocator[index][1]}`}
                r='79.65'
                stroke={colors[idx]}
                strokeLinecap='round'
                fill='none'
                strokeWidth='12px'
                strokeDasharray={499.64}
                strokeDashoffset={499.64}
                style={
                  downloadClicked
                    ? {
                        opacity: 1,
                        transformOrigin: '50% 50%',
                        transform: 'rotate(90deg)'
                      }
                    : {
                        ...styles,
                        transformOrigin: '50% 50%',
                        transform: 'rotate(90deg)'
                      }
                }
              />
            ))}
        </StyledSvg>
        {!noAssessmentsCompleted && (
          <Grid
            container
            justifyContent='center'
            sx={{
              position: 'absolute',
              top: 70
            }}
          >
            <Typography
              variant='h1'
              sx={{ fontSize: '48px', color: colors[idx], fontWeight: 400 }}
            >
              {totalScore.length}
            </Typography>
            <Typography
              // TODO - Add this font style to Typography theme
              variant='h6'
              sx={{
                fontSize: '24px',
                color: colors[idx],
                fontWeight: 400,
                alignSelf: 'flex-end'
              }}
            >
              /25
            </Typography>
          </Grid>
        )}
      </ProgressCircleContainer>
    </Box>
  )
}

export default CategoryProgressCircle
