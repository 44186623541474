/* eslint-disable no-console */
import React from 'react'
import {
  Benchmark,
  QuarterScore,
  BenchmarkCategory,
  Category
} from 'model/assessment'
import { Box } from '@mui/material'
import { getBenchmarkTopOpportunityAndStrength } from 'utils/dashboardUtils'
import TopComponent from './TopComponent'

interface IProps {
  benchmark: Benchmark | undefined | null
  benchmarksAreLoading: boolean
  currentScore: QuarterScore | undefined
  previousScore: QuarterScore | undefined
}

const TopStrengthAndOpportunity = ({
  benchmark,
  benchmarksAreLoading,
  currentScore,
  previousScore
}: IProps) => {
  const previousQuarter: number =
    previousScore && previousScore.quarter ? previousScore.quarter : 1
  const previousQuarterCategories: Category[] =
    previousScore && previousScore.employerAssessmentCategories
      ? previousScore.employerAssessmentCategories
      : []
  const currentQuarterCategories: Category[] =
    currentScore && currentScore.employerAssessmentCategories
      ? currentScore.employerAssessmentCategories
      : []
  const previousYear: number =
    previousScore && previousScore.endAt
      ? new Date(previousScore.endAt).getFullYear()
      : 0

  const categories: any = benchmark
    ? benchmark?.benchmarkSummary?.category_avg
    : undefined

  // Get Top Opportunity and Strength
  const [topStrength, topOpportunity]: BenchmarkCategory[] =
    getBenchmarkTopOpportunityAndStrength(
      categories,
      previousQuarter,
      previousYear,
      previousQuarterCategories,
      currentQuarterCategories
    )

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        gap: '31px',
        height: '483px'
      }}
    >
      <TopComponent
        benchmarkScore={topStrength ? topStrength.benchmarkScore : undefined}
        benchmarkScoreLoading={benchmarksAreLoading}
        currentScore={topStrength ? topStrength.currentScore : undefined}
        previousScore={topStrength ? topStrength.previousScore : undefined}
        category={topStrength ? topStrength.category : undefined}
        type={topStrength ? topStrength.type : 'strength'}
        quarterDetails={
          topStrength && topStrength.previousQuarter
            ? `From ${topStrength.previousQuarter}`
            : ''
        }
      />
      <TopComponent
        benchmarkScore={
          topOpportunity ? topOpportunity.benchmarkScore : undefined
        }
        benchmarkScoreLoading={benchmarksAreLoading}
        currentScore={topOpportunity ? topOpportunity.currentScore : undefined}
        previousScore={
          topOpportunity ? topOpportunity.previousScore : undefined
        }
        category={topOpportunity ? topOpportunity.category : undefined}
        type={topOpportunity ? topOpportunity.type : 'opportunity'}
        quarterDetails={
          topOpportunity && topOpportunity.previousQuarter
            ? `From ${topOpportunity.previousQuarter}`
            : ''
        }
      />
    </Box>
  )
}
export default TopStrengthAndOpportunity
