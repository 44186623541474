import React, { useState, useContext, useEffect } from 'react'
import { Grid, Typography, Box } from '@mui/material'
import { InfoBanner } from 'components'
import { theme, Color } from 'components/SharedStyles'
import { Category } from 'model/assessment'
import { AssessmentOverviewContext } from 'context/AssessmentOverview'
import AssessmentProgress from './AssessmentProgress'
import AssessmentSubmitButton from './AssessmentSubmitButton'
import CategoryBox from './CategoryBox'
import AssessmentStartButton from './AssessmentStartButton'
import { StyledSnackbar } from 'pages/Dashboard/ScoreDashboard'
import ClearIcon from '@mui/icons-material/Clear'
import CategoryProvider from 'context/Category'

const AssessmentOverview = () => {
  const {
    hasActiveAssessment,

    employerAssessmentCategories,
    hasCompletedAnAssessment
  } = useContext(AssessmentOverviewContext)

  const [showBanner, setShowBanner] = useState(false)
  const [snackbarOpen, setSnackbarOpen] = useState(false)

  useEffect(() => {
    setShowBanner(!hasActiveAssessment && hasCompletedAnAssessment === false)
  }, [hasActiveAssessment, hasCompletedAnAssessment])

  return (
    <Grid
      container
      flexDirection='column'
      flexWrap='nowrap'
      rowSpacing={3}
      marginTop={0}
    >
      <StyledSnackbar
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{ padding: '12px 17px', marginTop: '75px' }}
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Typography sx={{ display: 'flex', alignItems: 'center' }}>
          Progress saved
          <ClearIcon
            onClick={() => setSnackbarOpen(false)}
            sx={{
              marginLeft: '20px',
              fontSize: '16px',
              '&:hover': { cursor: 'pointer' }
            }}
          />
        </Typography>
      </StyledSnackbar>
      <Typography variant='h1' marginBottom={1}>
        Assessment
      </Typography>
      <Grid item container spacing={3}>
        <Grid
          item
          xs={12}
          lg={6}
          sx={{
            [theme.breakpoints.down('lg')]: {
              marginBottom: 3
            }
          }}
        >
          <Box sx={{ bgcolor: Color.grey50, padding: 3, borderRadius: 1 }}>
            <Typography variant='body1'>
              The Equity Index asks questions in 4 categories, to help figure
              out where you are with DEI. Its goal is to identify bias and
              uncover opportunities to develop a more inclusive workplace. For
              best results, we suggest bringing a few different people and
              perspectives together to provide input. And make sure you answer
              about where you are today, not where you hope to be.{' '}
            </Typography>
          </Box>
        </Grid>
        <Grid item lg={3} xs={6}>
          {hasActiveAssessment ? (
            <AssessmentProgress />
          ) : (
            <AssessmentStartButton />
          )}
        </Grid>
        <Grid item lg={3} xs={6}>
          <AssessmentSubmitButton />
        </Grid>
      </Grid>
      {showBanner && (
        <Grid item>
          <InfoBanner
            content={
              'You don’t need to finish it all in one sitting. Feel free to jump into a category and you can save your progress and come back anytime.'
            }
            type='info'
            onClose={() => setShowBanner(false)}
            sx={{
              '& .css-7s9set-MuiGrid-root': {
                '& svg': {
                  backgroundColor: Color.black,
                  borderRadius: '50%',
                  '& path': {
                    fill: '#DCF3FF'
                  },
                  '& path:first-of-type': {
                    fill: Color.black
                  }
                }
              }
            }}
          />
        </Grid>
      )}
      <Grid item>
        {employerAssessmentCategories?.map((category: Category) => (
          <CategoryProvider category={category} key={category.id}>
            <CategoryBox />
          </CategoryProvider>
        ))}
      </Grid>
    </Grid>
  )
}
export default AssessmentOverview
