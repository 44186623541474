import React, { createContext, useEffect, useState, useMemo } from 'react'
import { Recommendation, RecommendationEdge } from 'model/recommendation'
import useRecommendations from 'hooks/useRecommendations'

export type Recommendations = {
  recommendations: Recommendation[]
  isLoading: boolean
}

export const RecommendationsContext = createContext<Recommendations>({
  recommendations: [],
  isLoading: true
})

export const RecommendationsProvider = ({ children }: any): JSX.Element => {
  const { recommendationConnection, isLoading } = useRecommendations()
  const [recommendations, setRecommendations] = useState<Recommendation[]>([])

  useEffect(() => {
    if (recommendationConnection) {
      setRecommendations(
        recommendationConnection.edges.map(
          (edge: RecommendationEdge) => edge.node
        )
      )
    }
  }, [recommendationConnection])

  const value = useMemo(
    () => ({ recommendations, isLoading }),
    [recommendations, isLoading]
  )

  return (
    <RecommendationsContext.Provider value={value}>
      {children}
    </RecommendationsContext.Provider>
  )
}
