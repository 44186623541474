import { gql } from '@apollo/client'

export const GET_ASSESSMENT = gql`
  query GetAssessment {
    assessment {
      id
      totalScore
      categories {
        id
        name
        slug
        description
        totalScore
      }
    }
  }
`

// Mutations

export const SUBMIT_ASSESSMENT = gql`
  mutation ($id: ID!) {
    submitEmployerAssessment(input: { id: $id }) {
      id
      employerId
      totalScore
      acquiredScore
      progress
      status
      startAt
      endAt
    }
  }
`
