import React, { useContext } from 'react'
import { Box, Typography } from '@mui/material'
import { Breadcrumbs } from 'components'
import { useNavigate } from 'react-router-dom'
import { BREADCRUMBS_COLOR } from './constant'
import { AssessmentContext } from 'context/Assessment'

const BreadcrumbHeader = () => {
  const { currentCategory } = useContext(AssessmentContext)
  const categoryTitle = currentCategory ? currentCategory.name : ''
  const navigate = useNavigate()
  const breadcrumbItems = [
    { label: '...', path: '/equity-index' },
    { label: 'Assessment', path: '/equity-index/assessment' },
    { label: categoryTitle.replace('&', 'and') }
  ]
  return (
    <Box>
      <Typography variant='h1'>Assessment</Typography>
      <Box marginBottom='28px'>
        <Breadcrumbs
          items={breadcrumbItems}
          redirectTo={(path: string) => navigate(path)}
          background={BREADCRUMBS_COLOR[categoryTitle]}
        />
      </Box>
    </Box>
  )
}

export default BreadcrumbHeader
