// eslint-disable-next-line no-extend-native
Array.prototype.equalsUnordered = function (compare: any[]) {
  const firstArray = [...this]
  const secondArray = [...compare]

  return (
    JSON.stringify(firstArray.sort()) === JSON.stringify(secondArray.sort())
  )
}

declare global {
  interface Array<T> {
    equalsUnordered(array: any[]): T
  }
}

declare module '@mui/material/Typography/Typography' {
  interface TypographyPropsVariantOverrides {
    // style migrated from old theme, may be removed in the future
    largeTitle: true
    body1Medium: true
    body2Medium: true
    tableLabel: true
    subheading: true
    link: true
    // new styles for ehi design system
    h1Light: true
    h2Light: true
    h3Light: true
    h4Light: true
    h5Light: true
    h6Light: true
    body20: true
    body20Light: true
    body18: true
    body18Light: true
    body16: true
    body16Light: true
    body14: true
    body14Light: true
    tableBold: true
    h7: true
    formLabel: true
    linkSmall: true
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsVariantOverrides {
    chip: true
    action: true
    underline: true
  }
  interface ButtonPropsSizeOverrides {
    xsmall: true
  }
}

declare module '@mui/material/styles/createTypography' {
  interface Typography {
    // style migrated from old theme, may be removed in the future
    largeTitle: React.CSSProperties
    body1Medium: React.CSSProperties
    body2Medium: React.CSSProperties
    body2Short: React.CSSProperties
    tableLabel: React.CSSProperties
    subheading: React.CSSProperties
    link: React.CSSProperties
    // new styles for ehi design system
    h1Light: React.CSSProperties
    h2Light: React.CSSProperties
    h3Light: React.CSSProperties
    h4Light: React.CSSProperties
    h5Light: React.CSSProperties
    h6Light: React.CSSProperties
    body20: React.CSSProperties
    body20Light: React.CSSProperties
    body18: React.CSSProperties
    body18Light: React.CSSProperties
    body16: React.CSSProperties
    body16Light: React.CSSProperties
    body14: React.CSSProperties
    body14Light: React.CSSProperties
    tableBold: React.CSSProperties
    h7: React.CSSProperties
    formLabel: React.CSSProperties
    linkSmall: React.CSSProperties
  }
  interface TypographyOptions {
    // style migrated from old theme, may be removed in the future
    largeTitle: React.CSSProperties
    body1Medium: React.CSSProperties
    body2Medium: React.CSSProperties
    body2Short: React.CSSProperties
    tableLabel: React.CSSProperties
    subheading: React.CSSProperties
    link: React.CSSProperties
    // new styles for ehi design system
    h1Light: React.CSSProperties
    h2Light: React.CSSProperties
    h3Light: React.CSSProperties
    h4Light: React.CSSProperties
    h5Light: React.CSSProperties
    h6Light: React.CSSProperties
    body20: React.CSSProperties
    body20Light: React.CSSProperties
    body18: React.CSSProperties
    body18Light: React.CSSProperties
    body16: React.CSSProperties
    body16Light: React.CSSProperties
    body14: React.CSSProperties
    body14Light: React.CSSProperties
    tableBold: React.CSSProperties
    h7: React.CSSProperties
    formLabel: React.CSSProperties
    linkSmall: React.CSSProperties
  }
}

export {}
