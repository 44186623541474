/* eslint-disable react/jsx-no-useless-fragment */
import React, { useState, useContext } from 'react'
import moment from 'moment'
import { Button, Grid, Typography } from '@mui/material'
import { Color } from 'components/SharedStyles'
import FinishAssessmentBanner from 'pages/Dashboard/ScoreDashboard/FinishAssessmentBanner'
import TotalAssessmentScore from 'pages/Dashboard/ScoreDashboard/TotalAssessmentScore'
import TopStrengthAndOpportunity from 'pages/Dashboard/ScoreDashboard/TopStrengthAndOpportunity'
import { DashboardContext } from 'context/Dashboard'
import { MicroServiceContext } from 'context/MicroService'
import { BenchmarkContext } from 'context/Benchmark'
import { useNavigate } from 'react-router-dom'
import {
  CurrentEpAssessments,
  EmployerAssessmentHistory,
  EmployerQuarterHistory,
  QuarterScore
} from 'model/assessment'
import {
  getLastFourQuarters,
  getPreviousAndCurrentScores
} from 'utils/dashboardUtils'
import FilterDropdown from 'components/FilterDropdown'
import AssessmentReport from './AssessmentReport'

const HistoryDashboard = () => {
  const [downloadClicked] = useState(false)
  const [selectedComponents] = useState<string[]>([
    'Current Score',
    'Top Strength & Opportunity',
    'Development Stage',
    'Key Recommendations',
    'Progress by Category',
    'Key Strengths'
  ])
  const showComponent = (name: string) => {
    return (
      (downloadClicked && selectedComponents.includes(name)) || !downloadClicked
    )
  }

  const {
    hasCompletedAnAssessment,
    currentEmployerAssessmentHistory,
    employerAssessmentHistory
  } = useContext(DashboardContext)

  const { hasAccessToViewDashboard } = useContext(MicroServiceContext)

  const { benchmark, benchmarksAreLoading } = useContext(BenchmarkContext)

  const latestAssessment: EmployerQuarterHistory | null =
    currentEmployerAssessmentHistory?.latestAssessment

  const latestAssessmentScore: number | undefined =
    latestAssessment && latestAssessment.acquiredScore
      ? latestAssessment.acquiredScore
      : undefined

  const latestAssessmentYear: number | undefined =
    latestAssessment && latestAssessment.endAt
      ? new Date(latestAssessment?.endAt).getFullYear()
      : undefined

  const latestAssessmentMonth: number | undefined =
    latestAssessment && latestAssessment.endAt
      ? new Date(latestAssessment?.endAt).getMonth() + 1
      : undefined

  const latestAssessmentQuarter: number | undefined = latestAssessmentMonth
    ? Math.ceil(latestAssessmentMonth / 3)
    : undefined

  // construct new CurrentEpAssessments object for new UI updates
  const previousYearAssessments: EmployerAssessmentHistory = {
    firstQuarter: employerAssessmentHistory.firstQuarter,
    secondQuarter: employerAssessmentHistory.secondQuarter,
    thirdQuarter: employerAssessmentHistory.thirdQuarter,
    fourthQuarter: employerAssessmentHistory.fourthQuarter
  }

  const currentYearAssessment: EmployerAssessmentHistory = {
    firstQuarter: currentEmployerAssessmentHistory.firstQuarter,
    secondQuarter: currentEmployerAssessmentHistory.secondQuarter,
    thirdQuarter: currentEmployerAssessmentHistory.thirdQuarter,
    fourthQuarter: currentEmployerAssessmentHistory.fourthQuarter
  }

  const currentEpAssessments: CurrentEpAssessments = getLastFourQuarters(
    previousYearAssessments,
    currentYearAssessment
  )

  // Get the current and previous scores for the graphs
  const [previousScore, currentScore]: QuarterScore[] | any[] =
    getPreviousAndCurrentScores(currentEpAssessments)

  const latestQuarterScore: QuarterScore | undefined = latestAssessmentScore
    ? ({
        id: latestAssessment?.id,
        acquiredScore: latestAssessmentScore,
        endAt: latestAssessment?.endAt,
        employerAssessmentCategories:
          latestAssessment?.employerAssessmentCategories,
        quarter:
          latestAssessment && latestAssessment.endAt
            ? moment(latestAssessment.endAt).quarter()
            : 0
      } as QuarterScore)
    : undefined

  const navigate = useNavigate()
  return (
    <>
      <Grid container>
        <Typography
          sx={{
            color: '#050B0E',
            fontWeight: 700,
            fontSize: '36px',
            letterSpacing: '0.72px',
            lineHeight: '44px'
          }}
        >
          Assessment History
        </Typography>
        <Button
          sx={{ marginLeft: 'auto', height: 44 }}
          variant='contained'
          onClick={() => {
            navigate('/equity-index/assessment')
          }}
        >
          Retake Assessment
        </Button>
      </Grid>
      <Grid marginTop='16px'>
        {/* TODO: We need to remove the styles for .Mui-disabled when we add the other dates */}
        <FilterDropdown
          disabled
          sx={{
            width: '340px',
            marginBottom: '5px',
            '.Mui-disabled': {
              cursor: 'not-allowed !important',
              backgroundColor: `${Color.grey100} !important`,
              color: `${Color.black80} !important`,
              '-webkit-text-fill-color': `${Color.black80} !important`,
              svg: {
                display: 'none'
              }
            }
          }}
          label='Past Assessments'
          values={[
            `Q${latestAssessmentQuarter} — ${latestAssessmentYear} (${moment(
              latestAssessment?.endAt
            ).format('MM/DD/YYYY')})`
          ]}
          options={[]}
          clearFilter={() => {}}
        />
      </Grid>
      <Grid
        container
        id='score-dashboard'
        spacing={3}
        sx={{ marginTop: '-6px' }}
      >
        {!hasCompletedAnAssessment && hasAccessToViewDashboard && (
          <Grid item xs={12}>
            <FinishAssessmentBanner />
          </Grid>
        )}
        <>
          {hasAccessToViewDashboard && (
            <>
              {showComponent('Current Score') && (
                <Grid item lg={4.5} xs={12}>
                  <TotalAssessmentScore
                    latestScore={latestAssessmentScore}
                    latestYear={latestAssessmentYear}
                    latestQuarter={latestAssessmentQuarter}
                    acquiredScore={currentScore && currentScore.acquiredScore}
                    previousAcquiredScore={
                      previousScore ? previousScore.acquiredScore : 0
                    }
                    previousQuarter={previousScore ? previousScore.quarter : 1}
                    previousQuarterDate={
                      previousScore ? previousScore.endAt : ''
                    }
                    noAssessmentsCompleted={!hasCompletedAnAssessment}
                    benchmark={benchmark}
                    benchmarksAreLoading={benchmarksAreLoading}
                  />
                </Grid>
              )}
              {showComponent('Top Strength & Opportunity') && (
                <Grid item lg={7.5} xs={12}>
                  <TopStrengthAndOpportunity
                    benchmark={benchmark}
                    benchmarksAreLoading={benchmarksAreLoading}
                    currentScore={currentScore || latestQuarterScore}
                    previousScore={previousScore}
                  />
                </Grid>
              )}
              <Grid item xs={12}>
                <AssessmentReport />
              </Grid>
            </>
          )}
        </>
      </Grid>
    </>
  )
}

export default HistoryDashboard
