import React, { useContext } from 'react'
import {
  Box,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography
} from '@mui/material'
import { DividerLine } from '..'
import { Answer } from 'model/assessment'
import { AssessmentContext } from 'context/Assessment'

const SingleSelectQuestion = () => {
  const {
    activeSelectedAnswerIds,
    setActiveSelectedAnswerIds,
    activeQuestion
  } = useContext(AssessmentContext)
  const answers: Answer[] = activeQuestion!.assessmentAnswers

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    const answerId = (e.target as HTMLInputElement).value
    if (activeSelectedAnswerIds.includes(answerId)) {
      setActiveSelectedAnswerIds([])
    } else {
      setActiveSelectedAnswerIds([answerId])
    }
  }
  return (
    <FormControl
      sx={{ padding: '0 32px', boxSizing: 'border-box', width: '100%' }}
    >
      <RadioGroup value={activeSelectedAnswerIds}>
        {answers.map(answer => (
          <Box key={answer.id} sx={{ width: '100%' }}>
            <DividerLine item xs={12} />
            <FormControlLabel
              control={
                <Radio
                  onClick={e => {
                    handleClick(e)
                  }}
                  value={answer.id}
                  sx={{
                    marginRight: '20px',
                    padding: 0,
                    fontSize: '20px',
                    '& .MuiSvgIcon-root': {
                      height: '20px',
                      width: '20px'
                    }
                  }}
                />
              }
              label={
                <Typography variant='body2' color='black'>
                  {answer.answerText}
                </Typography>
              }
              sx={{
                margin: '8px 0',
                padding: '8px 0',
                width: '100%',
                '&:hover': {
                  backgroundColor: '#F7F7F7'
                }
              }}
            />
          </Box>
        ))}
      </RadioGroup>
    </FormControl>
  )
}

export default SingleSelectQuestion
