/* eslint-disable react/require-default-props */
import React from 'react'
import { Paper, PaperProps, Typography, Grid, Box } from '@mui/material'
import CategoryProgressCircle from 'pages/Dashboard/ScoreDashboard/CategoryProgress/CategoryProgressCircle'
import { styled } from '@mui/material/styles'
import { theme } from 'components/SharedStyles'
import { Category } from 'model/assessment'
import { Color } from '@mathison-inc/components'
import { orderProgressByCategory } from 'utils/dashboardUtils'

interface IProps {
  categoryScores?: Category[] | undefined
  noAssessmentsCompleted: boolean
  isVisible: boolean
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>
  downloadClicked: boolean
}

const CategoryProgressContainer = styled(Paper)<PaperProps>(() => ({
  display: 'flex',
  width: '100%',
  minHeight: '386px',
  padding: '40px 0 50px 0'
}))

// TO-DO Adjust with actual stage levels
const findStage = (score: number) => {
  let stage = 'Discovering'
  if (score >= 18.75) {
    stage = 'Leading'
  } else if (score >= 12.5) {
    stage = 'Engaged'
  } else if (score >= 6.25) {
    stage = 'Aware'
  }
  return stage
}

const CategoryProgress = ({
  categoryScores,
  noAssessmentsCompleted,
  isVisible,
  setIsVisible,
  downloadClicked
}: IProps) => {
  // Sort categoryScores By Category Order
  const orderedCategoryScores: Category[] | undefined =
    orderProgressByCategory(categoryScores)

  return (
    <CategoryProgressContainer variant='outlined'>
      <Grid container>
        {orderedCategoryScores &&
          orderedCategoryScores.map((category, idx) => (
            <Grid
              item
              key={category.id}
              sx={{
                borderRight: idx !== 3 ? '2px solid #E5E5E5' : 'none',
                padding: '0 40px 0 40px',
                width: '25%',
                [theme.breakpoints.down(1300)]: {
                  width: '50%',
                  borderRight: idx % 2 === 0 ? '2px solid #E5E5E5' : 'none',
                  paddingBottom: idx < 2 ? '50px' : 0
                },
                [theme.breakpoints.down(930)]: {
                  width: '100%',
                  borderRight: 'none',
                  paddingBottom: idx < 3 ? '50px' : 0
                }
              }}
            >
              <CategoryProgressCircle
                categoryScore={category && category.acquiredScore}
                idx={idx}
                noAssessmentsCompleted={noAssessmentsCompleted}
                isVisible={isVisible}
                setIsVisible={setIsVisible}
                downloadClicked={downloadClicked}
              />
              <Typography
                variant='body1'
                sx={{
                  marginTop: '50px',
                  fontWeight: 700,
                  color: noAssessmentsCompleted ? '#ACADAF' : Color.black,
                  whiteSpace: 'nowrap'
                }}
              >
                {category.name.replace('&', 'and')}
              </Typography>
              <Box sx={{ display: 'flex' }}>
                <Typography
                  variant='body2'
                  sx={{
                    color: noAssessmentsCompleted ? '#ACADAF' : 'black',
                    marginRight: '10px'
                  }}
                >
                  Stage:{' '}
                </Typography>
                {!noAssessmentsCompleted && (
                  <Typography variant='body2' sx={{ fontWeight: 700 }}>
                    {category.acquiredScore &&
                      findStage(category.acquiredScore)}
                  </Typography>
                )}
              </Box>
            </Grid>
          ))}
      </Grid>
    </CategoryProgressContainer>
  )
}

export default CategoryProgress
