import React, { useContext } from 'react'
import {
  Paper,
  PaperProps,
  Typography,
  Grid,
  Button,
  ButtonProps
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { Answer, Question } from 'model/assessment'
import CheckRoundedIcon from '@mui/icons-material/CheckRounded'
import { AssessmentContext } from 'context/Assessment'

const QuestionContainer = styled(Paper)<PaperProps>(() => ({
  width: '100%',
  padding: '32px',
  marginBottom: '24px'
}))

const EditButton = styled(Button)<ButtonProps>(() => ({
  minWidth: '40px',
  height: '28px',
  fontSize: '14px',
  fontWeight: 600,
  padding: '4px 8px',
  fontFamily: 'IBM Plex Sans'
}))

interface Props {
  answeredQuestion: Question
}

const InactiveQuestion = ({ answeredQuestion }: Props) => {
  const { clearQuestionSelections } = useContext(AssessmentContext)

  const { selectedAnswerIds } = answeredQuestion
  const questionLabel = answeredQuestion?.order
    ? `0${answeredQuestion.order}`.slice(-2)
    : ''

  return (
    <Grid item xs={12} lg={7}>
      <QuestionContainer variant='outlined'>
        <Grid container justifyContent={'space-between'}>
          <Typography
            variant='subtitle1'
            fontWeight={700}
            letterSpacing={'0.12em'}
            textTransform='uppercase'
            marginBottom={2}
          >
            {questionLabel ? `Question ${questionLabel}` : 'Follow-up'}
          </Typography>
          <EditButton
            variant='contained'
            color='secondary'
            onClick={() => clearQuestionSelections(answeredQuestion.id)}
          >
            Edit
          </EditButton>
        </Grid>
        <Typography variant='h5' marginBottom={2} width='70%'>
          {answeredQuestion?.questionText}
        </Typography>
        {answeredQuestion?.assessmentAnswers.map(
          (answer: Answer, index: number) => {
            return (
              selectedAnswerIds.includes(answer.id) && (
                <Grid
                  container
                  key={index}
                  sx={{
                    width: '70%',
                    flexWrap: 'nowrap',
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <CheckRoundedIcon sx={{ fontSize: '17px' }} />
                  <Typography variant='body2' color='black' marginLeft={1}>
                    {answer.answerText}
                  </Typography>
                </Grid>
              )
            )
          }
        )}
      </QuestionContainer>
    </Grid>
  )
}

export default InactiveQuestion
