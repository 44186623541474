/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-console */
import React, { useContext } from 'react'
import moment from 'moment'
import { Grid, Typography, Snackbar, SnackbarProps, Box } from '@mui/material'
import {
  getLastFourQuarters,
  getPreviousAndCurrentScores
} from 'utils/dashboardUtils'
import { styled } from '@mui/material/styles'
import { DashboardContext } from 'context/Dashboard'
import { INITIAL_EMPLOYER_ASSESSMENT_HISTORY } from 'context/Dashboard/constant'
import { MicroServiceContext } from 'context/MicroService'
import { BenchmarkContext } from 'context/Benchmark'
import { RecommendationsContext } from 'context/Recommendation'
import {
  CurrentEpAssessments,
  EmployerAssessmentHistory,
  EmployerQuarterHistory,
  Category,
  QuarterScore
} from 'model/assessment'
import { Recommendation } from 'model/recommendation'
import TotalAssessmentScore from './TotalAssessmentScore'
import DevelopmentStage from './DevelopmentStage'
import CategoryProgress from './CategoryProgress'
import KeyStrengths from './KeyStrengths'
import FinishAssessmentBanner from './FinishAssessmentBanner'
import GoalsTable from './GoalsTable'
import TopStrengthAndOpportunity from './TopStrengthAndOpportunity'
import KeyRecommendations from './KeyRecommendations'

interface IProps {
  isVisible: boolean
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>
  downloadClicked: boolean
  selectedComponents: string[]
}

export const StyledSnackbar = styled(Snackbar)<SnackbarProps>(() => ({
  backgroundColor: '#050B0E',
  color: 'white',
  borderRadius: '8px',
  boxShadow: '3px 3px 12px 2px rgba(155, 157, 159, 0.3)'
}))

const ScoreDashboard = ({
  isVisible,
  setIsVisible,
  downloadClicked,
  selectedComponents
}: IProps) => {
  const {
    employerAssessmentHistory,
    currentEmployerAssessmentHistory,
    latestAssessmentData,
    hasCompletedAnAssessment
  } = useContext(DashboardContext)

  const { hasAccessToViewDashboard } = useContext(MicroServiceContext)

  const { benchmark, benchmarksAreLoading } = useContext(BenchmarkContext)

  const { recommendations, isLoading } = useContext(RecommendationsContext)

  const latestAssessment: EmployerQuarterHistory | null = latestAssessmentData

  const latestAssessmentCategories: Category[] | undefined =
    latestAssessment && latestAssessment.employerAssessmentCategories
      ? latestAssessment.employerAssessmentCategories
      : undefined

  const latestAssessmentScore: number | undefined =
    latestAssessment && latestAssessment.acquiredScore
      ? latestAssessment.acquiredScore
      : undefined

  const latestAssessmentYear: number | undefined =
    latestAssessment && latestAssessment.endAt
      ? new Date(latestAssessment?.endAt).getFullYear()
      : undefined

  const latestAssessmentMonth: number | undefined =
    latestAssessment && latestAssessment.endAt
      ? new Date(latestAssessment?.endAt).getMonth() + 1
      : undefined

  const latestAssessmentQuarter: number | undefined = latestAssessmentMonth
    ? Math.ceil(latestAssessmentMonth / 3)
    : undefined

  // construct new CurrentEpAssessments object for new UI updates
  const previousYearAssessments: EmployerAssessmentHistory = {
    firstQuarter: employerAssessmentHistory.firstQuarter,
    secondQuarter: employerAssessmentHistory.secondQuarter,
    thirdQuarter: employerAssessmentHistory.thirdQuarter,
    fourthQuarter: employerAssessmentHistory.fourthQuarter
  }

  const currentYearAssessment: EmployerAssessmentHistory = {
    firstQuarter: currentEmployerAssessmentHistory.firstQuarter,
    secondQuarter: currentEmployerAssessmentHistory.secondQuarter,
    thirdQuarter: currentEmployerAssessmentHistory.thirdQuarter,
    fourthQuarter: currentEmployerAssessmentHistory.fourthQuarter
  }

  const currentEpAssessments: CurrentEpAssessments = getLastFourQuarters(
    previousYearAssessments,
    currentYearAssessment
  )

  // Get the current and previous scores for the graphs
  const [previousScore, currentScore]: QuarterScore[] | any[] =
    getPreviousAndCurrentScores(currentEpAssessments)

  const latestQuarterScore: QuarterScore | undefined = latestAssessmentScore
    ? ({
        id: latestAssessment?.id,
        acquiredScore: latestAssessmentScore,
        endAt: latestAssessment?.endAt,
        employerAssessmentCategories:
          latestAssessment?.employerAssessmentCategories,
        quarter:
          latestAssessment && latestAssessment.endAt
            ? moment(latestAssessment.endAt).quarter()
            : 0
      } as QuarterScore)
    : undefined

  // Recommendations Section - Get the latest recommendations
  const filteredRecommendations = recommendations.filter(
    recommendation =>
      recommendation.status !== 'archived' &&
      recommendation.employerAssessmentId === latestAssessment?.id &&
      recommendation.assessmentQuestionText !== null
  )

  const keyRecommendations: Recommendation[] = filteredRecommendations.slice(
    0,
    3
  )

  const showComponent = (name: string) => {
    return (
      (downloadClicked && selectedComponents.includes(name)) || !downloadClicked
    )
  }

  const emptyCategoryScores =
    INITIAL_EMPLOYER_ASSESSMENT_HISTORY.firstQuarter
      ?.employerAssessmentCategories

  return (
    <Grid container id='score-dashboard' spacing={3} sx={{ marginTop: '-6px' }}>
      {!hasCompletedAnAssessment && hasAccessToViewDashboard && (
        <Grid item xs={12}>
          <FinishAssessmentBanner />
        </Grid>
      )}
      {hasAccessToViewDashboard && (
        <>
          {showComponent('Current Score') && (
            <Grid item lg={4.5} xs={12}>
              <TotalAssessmentScore
                latestScore={latestAssessmentScore}
                latestYear={latestAssessmentYear}
                latestQuarter={latestAssessmentQuarter}
                acquiredScore={currentScore && currentScore.acquiredScore}
                previousAcquiredScore={
                  previousScore ? previousScore.acquiredScore : 0
                }
                previousQuarter={previousScore ? previousScore.quarter : 1}
                previousQuarterDate={previousScore ? previousScore.endAt : ''}
                noAssessmentsCompleted={!hasCompletedAnAssessment}
                benchmark={benchmark}
                benchmarksAreLoading={benchmarksAreLoading}
              />
            </Grid>
          )}
          {showComponent('Top Strength & Opportunity') && (
            <Grid item lg={7.5} xs={12}>
              <TopStrengthAndOpportunity
                benchmark={benchmark}
                benchmarksAreLoading={benchmarksAreLoading}
                currentScore={currentScore || latestQuarterScore}
                previousScore={previousScore}
              />
            </Grid>
          )}
          {showComponent('Development Stage') && (
            <Grid item xs={12}>
              <DevelopmentStage
                acquiredScore={
                  (currentScore && currentScore.acquiredScore) ||
                  latestAssessmentScore
                }
                noAssessmentsCompleted={!hasCompletedAnAssessment}
              />
            </Grid>
          )}
          {showComponent('Key Recommendations') && (
            <Grid item xs={12}>
              {keyRecommendations &&
                keyRecommendations.length > 0 &&
                !isLoading && (
                  <>
                    <Typography
                      variant='h5'
                      sx={{
                        fontFamily: 'IBM Plex Sans',
                        fontSize: '20px',
                        lineHeight: '28px',
                        letterSpacing: '-0.04px',
                        marginBottom: '16px'
                      }}
                    >
                      Key Recommendations
                    </Typography>
                    <KeyRecommendations recommendations={keyRecommendations} />
                  </>
                )}
            </Grid>
          )}
          {showComponent('Progress by Category') && (
            <>
              <Typography
                variant='h5'
                margin='24px 0 -8px 24px'
                color={hasCompletedAnAssessment ? 'black' : '#ACADAF'}
              >
                Progress by Category
              </Typography>
              <Grid item xs={12}>
                <CategoryProgress
                  categoryScores={
                    currentScore
                      ? currentScore.employerAssessmentCategories
                      : latestAssessmentCategories || emptyCategoryScores
                  }
                  noAssessmentsCompleted={!hasCompletedAnAssessment}
                  isVisible={isVisible}
                  setIsVisible={setIsVisible}
                  downloadClicked={downloadClicked}
                />
              </Grid>
            </>
          )}
        </>
      )}
      {showComponent('Key Strengths') && <KeyStrengths />}
      {!downloadClicked && (
        <>
          <Typography
            variant='h5'
            margin='24px 0 -8px 24px'
            color={hasCompletedAnAssessment ? 'black' : '#ACADAF'}
          >
            Completed Goals
          </Typography>
          <Grid item xs={12}>
            <GoalsTable />
          </Grid>
        </>
      )}
    </Grid>
  )
}

export default ScoreDashboard
