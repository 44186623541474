import React from 'react'
import { Box, Grid, Typography } from '@mui/material'
import DeltaArrowComponent from './DeltaArrowComponent'

interface IProps {
  delta: number
  currentScore: number
  previousScore: number | undefined
  quarterDetails: string
}

const TopComponentDelta = ({
  delta,
  currentScore,
  previousScore,
  quarterDetails
}: IProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'flex-end'
      }}
    >
      {previousScore ? (
        <>
          <DeltaArrowComponent
            delta={delta}
            currentScore={currentScore}
            previousScore={previousScore}
          />
          <Box sx={{ padding: '0 10px' }}>
            <Typography
              sx={{
                fontFamily: 'IBM Plex Sans',
                fontSize: '12px',
                fontWeight: 'bold',
                letterSpacing: '2.04px',
                textTransform: 'uppercase'
              }}
            >
              {quarterDetails}
            </Typography>
          </Box>
        </>
      ) : null}
    </Box>
  )
}
export default TopComponentDelta
