import { Color } from '@mathison-inc/components'

export const tableStyles = {
  border: 'none',
  '& .MuiDataGrid-columnSeparator': {
    visibility: 'visible'
  },
  '& .MuiDataGrid-cell:focus': { outline: 'none' },
  '& .MuiDataGrid-cell .MuiTypography-body1': { fontSize: '14px' },
  '& .MuiDataGrid-columnHeader--filtered': {
    '& [aria-label~=filter].MuiDataGrid-iconButtonContainer ': {
      visibility: 'hidden',
      width: 0
    },
    '& .MuiDataGrid-columnHeaderDraggableContainer': {
      width: 'auto'
    }
  },
  '& .MuiDataGrid-columnSeparator .MuiSvgIcon-root': {
    color: '#E5E5E5',
    transform: 'scale(.5,2)'
  },
  '& .MuiDataGrid-columnHeadersInner': {
    height: '44px',
    '& .MuiDataGrid-columnHeaderTitle': {
      fontWeight: 600
    }
  },
  '& .MuiIconButton-root': {
    color: '#050B0E'
  },

  '& .MuiDataGrid-row': {
    cursor: 'default',
    '&:hover': {
      backgroundColor: Color.white
    }
  },
  '& .MuiDataGrid-footerContainer': {
    paddingTop: '32px',
    '& .MuiTablePagination-toolbar': {
      minHeight: '40px',
      '& p': {
        margin: '10px 0'
      },
      '& .MuiIconButton-root': {
        backgroundColor: Color.grey50,
        marginLeft: '8px'
      }
    }
  }
}
