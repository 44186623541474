/* eslint-disable no-nested-ternary */
import React from 'react'
import { Box, Grid, Typography } from '@mui/material'
import {
  ArrowDropDownRounded as ArrowDropDownIcon,
  ArrowDropUpRounded as ArrowDropUpIcon
} from '@mui/icons-material'
import { animated, useSpring, easings } from 'react-spring'
import { Benchmark } from 'model/assessment'

interface IProps {
  benchmark: Benchmark | undefined
  acquiredScore: number | undefined
}

const AverageScore = ({ benchmark, acquiredScore }: IProps) => {
  // Calculate the average and percentage increase
  const avg = benchmark?.benchmarkSummary?.avg || 0

  const catDelta: number =
    avg && acquiredScore ? acquiredScore - avg : acquiredScore || 0

  const { scoreIncrease } = useSpring({
    from: { scoreIncrease: 0 },
    scoreIncrease: avg && acquiredScore ? (catDelta / avg) * 100 : 0,
    config: { duration: 2000, easing: easings.easeInOutQuart }
  })

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        marginTop: '15px',
        width: '100%',
        justifyContent: 'center'
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-start',
          marginLeft: '-20px'
        }}
      >
        <Grid container display='flex' width='inherit'>
          {avg > 0 && (
            <>
              <Grid
                item
                xs={10}
                display='flex'
                justifyContent='flex-end'
                alignItems='center'
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '10px 5px 10px 10px',
                    backgroundColor: catDelta > 0 ? '#EBF6EF' : '#FFECED',
                    borderBottomLeftRadius: '8px',
                    borderTopLeftRadius: '8px'
                  }}
                >
                  <animated.h3
                    style={{
                      fontWeight: 700,
                      fontSize: '12px',
                      lineHeight: '12px',
                      letterSpacing: '0.17em',
                      color: '#050B0E',
                      margin: 0
                    }}
                  >
                    {scoreIncrease.to(value => Math.round(value))}
                  </animated.h3>
                  <Box sx={{ display: 'inline-block' }}>
                    <Typography
                      sx={{
                        fontWeight: 700,
                        fontSize: '12px',
                        lineHeight: '12px',
                        letterSpacing: '0.17em',
                        color: '#050B0E',
                        margin: 0
                      }}
                    >
                      %
                    </Typography>
                  </Box>
                </Box>
              </Grid>
              <Grid
                item
                xs={2}
                display='flex'
                justifyContent='center'
                alignItems='center'
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: catDelta > 0 ? '#EBF6EF' : '#FFECED',
                    height: '100%',
                    borderBottomRightRadius: '8px',
                    borderTopRightRadius: '8px'
                  }}
                >
                  {catDelta > 0 ? (
                    <ArrowDropUpIcon
                      style={{
                        color: '#32A763'
                      }}
                    />
                  ) : (
                    <ArrowDropDownIcon
                      style={{
                        color: '#FD404C'
                      }}
                    />
                  )}
                </Box>
              </Grid>
            </>
          )}
        </Grid>
      </Box>

      <Box sx={{ marginLeft: '20px' }}>
        <Typography
          variant='subtitle1'
          sx={{
            fontWeight: 700,
            fontSize: '12px',
            lineHeight: '16px',
            letterSpacing: '2.04px',
            color: '#050B0E',
            textTransform: 'uppercase',
            display: 'flex'
          }}
        >
          {catDelta >= 0
            ? `Above Mathison Avg`
            : catDelta < 0
            ? `Below Mathison Avg`
            : null}
          <Typography
            sx={{
              color: '#747779',
              fontWeight: 700,
              marginLeft: '8px',
              fontSize: '12px',
              letterSpacing: '2.04px',
              lineHeight: '16px'
            }}
          >
            ({Math.round(avg)})
          </Typography>
        </Typography>
      </Box>
    </Box>
  )
}

export default AverageScore
