import { useContext, useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { MicroServiceContext } from 'context/MicroService'
import { AssessmentBenchmarks } from 'model/assessment'
import { GET_BENCHMARKS } from './gql'

const useBenchmark = () => {
  const { maestroClient } = useContext(MicroServiceContext)
  const [assessmentBenchmarks, setAssessmentBenchmarks] = useState<
    AssessmentBenchmarks | undefined
  >(undefined)

  // GET benchmarks for all EPs
  const { data: rawBenchmarks, loading: benchmarksAreLoading } = useQuery(
    GET_BENCHMARKS,
    { client: maestroClient, variables: {} }
  )

  // state management
  useEffect(() => {
    if (rawBenchmarks) {
      setAssessmentBenchmarks(rawBenchmarks)
    }
  }, [rawBenchmarks])

  return {
    assessmentBenchmarks,
    benchmarksAreLoading
  }
}

export default useBenchmark
