import React, { useState, useEffect, useContext } from 'react'
import {
  Box,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio
} from '@mui/material'
import { DividerLine } from '..'
import { Answer } from 'model/assessment'
import { AssessmentContext } from 'context/Assessment'

const MultiSelectQuestion = () => {
  const {
    activeQuestion,
    activeSelectedAnswerIds,
    setActiveSelectedAnswerIds
  } = useContext(AssessmentContext)
  const answers: Answer[] = activeQuestion?.assessmentAnswers || []
  const [radioSelected, setRadioSelected] = useState(false)
  const [checkSelected, setCheckSelected] = useState(false)

  useEffect(() => {
    if (activeQuestion) {
      if (
        !activeQuestion.selectedAnswerIds.equalsUnordered(
          activeSelectedAnswerIds
        )
      ) {
        setActiveSelectedAnswerIds([...activeQuestion.selectedAnswerIds])
      }
    }
  }, [activeQuestion])

  useEffect(() => {
    if (activeSelectedAnswerIds.length === 0) {
      setRadioSelected(false)
      setCheckSelected(false)
      return
    }
    const answer: any = answers.find(answer => !answer.hasWeight)
    if (answer && activeSelectedAnswerIds.includes(answer.id)) {
      setRadioSelected(true)
      setCheckSelected(false)
    } else {
      setCheckSelected(true)
      setRadioSelected(false)
    }
  }, [answers, activeSelectedAnswerIds])

  const handleCheckboxChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    selected: any
  ) => {
    const value = (e.target as HTMLInputElement).value

    let newActiveSelectedAnswerIds = [...activeSelectedAnswerIds]

    if (selected) {
      newActiveSelectedAnswerIds.push(value)
    } else {
      newActiveSelectedAnswerIds = newActiveSelectedAnswerIds.filter(
        (activeSelectedAnswerId: string) => activeSelectedAnswerId !== value
      )
    }

    setActiveSelectedAnswerIds(newActiveSelectedAnswerIds)
  }

  const handleRadioClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const value = (e.target as HTMLInputElement).value
    if (activeSelectedAnswerIds.length > 0) {
      setActiveSelectedAnswerIds([])
    } else {
      setActiveSelectedAnswerIds([value])
    }
  }
  return (
    <FormControl
      sx={{ padding: '0 32px', boxSizing: 'border-box', width: '100%' }}
    >
      <FormGroup sx={{ width: '100%' }}>
        {answers?.length > 0 &&
          answers
            .filter(answer => answer.hasWeight)
            .map(answer => {
              let answerIsSelected = false
              if (activeSelectedAnswerIds.includes(answer.id)) {
                answerIsSelected = true
              }
              return (
                <Box key={answer.id} sx={{ width: '100%' }}>
                  <DividerLine item xs={12} />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={answerIsSelected}
                        onChange={handleCheckboxChange}
                        disabled={radioSelected}
                        value={answer.id}
                        sx={{
                          marginRight: '20px',
                          padding: 0,
                          fontSize: '20px',
                          color: '#050B0E',
                          '& .MuiSvgIcon-root': {
                            height: '20px',
                            width: '20px'
                          }
                        }}
                      />
                    }
                    label={answer.answerText}
                    sx={{
                      fontFamily: 'IBM Plex Sans',
                      margin: '8px 0',
                      color: radioSelected ? 'rgba(0, 0, 0, 0.26)' : '#050B0E',
                      padding: '8px 0 8px 0',
                      width: '100%',
                      display: 'flex',
                      alignItems: 'flex-start',
                      backgroundColor: answerIsSelected ? '#F7F7F7' : 'none',
                      '& .MuiFormControlLabel-label': {
                        fontSize: '14px',
                        lineHeight: '18px'
                      },
                      '&:hover': {
                        backgroundColor: '#F7F7F7'
                      }
                    }}
                  />
                </Box>
              )
            })}
      </FormGroup>
      {answers
        .filter(answer => !answer.hasWeight)
        .map(answer => {
          let answerIsSelected = false
          if (activeSelectedAnswerIds.includes(answer.id)) {
            answerIsSelected = true
          }
          return (
            <RadioGroup
              value={answer.id}
              key={answer.id}
              sx={{ width: '100%' }}
            >
              <DividerLine item xs={12} />
              <FormControlLabel
                control={
                  <Radio
                    onClick={handleRadioClick}
                    value={answer.id}
                    checked={answerIsSelected}
                    disabled={checkSelected}
                    sx={{
                      marginRight: '20px',
                      padding: 0,
                      fontSize: '20px',
                      color: '#050B0E',
                      '& .MuiSvgIcon-root': {
                        height: '20px',
                        width: '20px'
                      }
                    }}
                  />
                }
                label={answer.answerText}
                sx={{
                  fontFamily: 'IBM Plex Sans',
                  padding: '8px 50px 8px 0',
                  margin: '8px 0 8px 0',
                  width: '100%',
                  color: checkSelected ? 'rgba(0, 0, 0, 0.26)' : '#050B0E',
                  backgroundColor: answerIsSelected ? '#F7F7F7' : 'none',
                  '&:hover': {
                    backgroundColor: '#F7F7F7'
                  },
                  '& .MuiFormControlLabel-label': {
                    fontSize: '14px',
                    lineHeight: '18px'
                  }
                }}
              />
            </RadioGroup>
          )
        })}
    </FormControl>
  )
}

export default MultiSelectQuestion
