import React, { useContext } from 'react'
import { Paper, PaperProps, Typography, Box } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Question } from 'model/assessment'
import CategoryQuestionListItem from './CategoryQuestionListItem'
import { Color } from 'components/SharedStyles'
import { CategoryContext } from 'context/Category'

const QuestionListContainer = styled(Paper)<PaperProps>(() => ({
  width: '100%',
  padding: '24px 24px 8px 24px'
}))

const CategoryQuestionList = () => {
  const { employerAssessmentQuestions } = useContext(CategoryContext)

  return (
    <QuestionListContainer variant='outlined' sx={{ marginTop: '32px' }}>
      <Box sx={{ display: 'flex', marginBottom: '18px', width: '100%' }}>
        <Typography
          variant='tableBold'
          sx={{
            borderRight: `1px solid ${Color.border}`,
            paddingRight: '75px',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          Completed
        </Typography>
        <Typography
          variant='tableBold'
          sx={{
            marginLeft: '12px',
            display: 'flex',
            alignItems: 'center'
          }}
        >
          Question
        </Typography>
      </Box>
      {employerAssessmentQuestions &&
        employerAssessmentQuestions.map((question: Question) => (
          <CategoryQuestionListItem key={question.id} question={question} />
        ))}
    </QuestionListContainer>
  )
}

export default CategoryQuestionList
