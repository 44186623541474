import { gql } from '@apollo/client'

export const GET_BENCHMARKS = gql`
  query GetBenchmarks {
    employerAssessmentBenchmark {
      id
      benchmarkSummary
      endDate
      benchmarkYear
      benchmarkType
      quarter
    }
  }
`
