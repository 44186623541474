import React, { useState } from 'react'
import { Color } from '@mathison-inc/components'
import {
  FormControl,
  InputLabel,
  Typography,
  MenuItem,
  Button,
  Select,
  styled,
  Checkbox
} from '@mui/material'

const Dropdown = styled(Select)({
  borderRadius: '8px',
  border: '1px solid #E5E5E5',
  '& .MuiSvgIcon-root': {
    color: Color.black
  },
  '& .MuiMenu-list': {}
})

const CategoryDropdown = ({
  label,
  values,
  onChange,
  options,
  clearFilter,
  selected,
  ...props
}: any) => {
  const [checkedValues, setCheckedValues] = useState<string[]>([])

  const handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    option: string
  ) => {
    const { checked } = event.target

    const updatedValues = checked
      ? [...checkedValues, option]
      : checkedValues.filter((val: string) => val !== option)

    setCheckedValues(updatedValues)
  }

  const handleMenuItemClick = (option: string) => {
    const isChecked = checkedValues.includes(option)
    if (isChecked) {
      const updatedValues = checkedValues.filter(val => val !== option)
      setCheckedValues(updatedValues)
    } else {
      const updatedValues = [...checkedValues, option]
      setCheckedValues(updatedValues)
    }
  }

  const handleClearAll = () => {
    setCheckedValues([])
    clearFilter()
  }

  return (
    <FormControl {...props}>
      <InputLabel variant='filled' id='demo-multiple-checkbox-label'>
        <Typography variant='body1'>{label}</Typography>
      </InputLabel>
      <Dropdown
        MenuProps={{
          MenuListProps: {
            sx: {
              backgroundColor: Color.white,
              padding: '16px',
              maxHeight: '324px'
            }
          }
        }}
        labelId='demo-multiple-checkbox-label'
        id='demo-multiple-checkbox'
        multiple
        sx={{ backgroundColor: Color.grey50 }}
        value={values}
        label={label}
        renderValue={(value: any) => (
          <Typography variant='body16'>{value.join(', ')}</Typography>
        )}
        variant='filled'
        onChange={onChange}
      >
        {options.map((option: string) => (
          <MenuItem
            key={option}
            onClick={() => handleMenuItemClick(option)}
            sx={{
              height: '36px',
              marginTop: '8px',
              borderRadius: 0,
              '&.Mui-selected': { backgroundColor: Color.grey200 }
            }}
            value={option}
          >
            <Checkbox
              value={option}
              checked={checkedValues.includes(option)}
              onChange={e => handleCheckboxChange(e, option)}
            />
            <Typography variant='body2'>{option}</Typography>
          </MenuItem>
        ))}
        <div>
          <Button
            sx={{
              textDecoration: 'underline',
              '&:hover': {
                backgroundColor: 'unset',
                textDecoration: 'underline'
              },
              paddingTop: '8px'
            }}
            disableRipple
            disableTouchRipple
            onClick={handleClearAll}
          >
            <Typography variant='body2Medium'>Clear all</Typography>
          </Button>
        </div>
      </Dropdown>
    </FormControl>
  )
}

export default CategoryDropdown
