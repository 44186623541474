import { EmployerAssessmentData } from 'model/assessment'

export const INITIAL_EMPLOYER_ASSESSMENT: EmployerAssessmentData = {
  employerAssessment: {
    id: '',
    totalScore: 0,
    acquiredScore: 0,
    progress: 0,
    status: '',
    employerAssessmentCategories: [
      {
        id: '1',
        categoryId: '',
        name: '',
        slug: '',
        description: '',
        totalScore: 0,
        acquiredScore: 0,
        progress: 0,
        isCompleted: false
      },
      {
        id: '2',
        categoryId: '',
        name: '',
        slug: '',
        description: '',
        totalScore: 0,
        acquiredScore: 0,
        progress: 0,
        isCompleted: false
      },
      {
        id: '3',
        categoryId: '',
        name: '',
        slug: '',
        description: '',
        totalScore: 0,
        acquiredScore: 0,
        progress: 0,
        isCompleted: false
      },
      {
        id: '4',
        categoryId: '',
        name: '',
        slug: '',
        description: '',
        totalScore: 0,
        acquiredScore: 0,
        progress: 0,
        isCompleted: false
      }
    ]
  }
}
