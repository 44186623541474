/* eslint-disable no-console */
import React from 'react'
import { Paper, PaperProps, Typography, Box, Divider } from '@mui/material'
import { styled } from '@mui/material/styles'
import { EIScoreBlock, ScoreDelta, Color } from 'components'
import AverageScore from 'components/EIBenchmark/AverageScore'
import { Benchmark } from 'model/assessment'

const TotalScoreContainer = styled(Paper)<PaperProps>(() => ({
  width: '100%',
  height: '483px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '32px 0 38px'
}))

interface IProps {
  latestScore: number | undefined
  latestYear: number | undefined
  latestQuarter: number | undefined
  acquiredScore: number | undefined
  previousAcquiredScore: number | undefined
  previousQuarter: number | undefined
  previousQuarterDate: string | undefined
  noAssessmentsCompleted: boolean
  benchmark: Benchmark | undefined
  benchmarksAreLoading: boolean | undefined
}

const TotalAssessmentScore = ({
  latestScore,
  latestYear,
  latestQuarter,
  acquiredScore,
  previousAcquiredScore,
  previousQuarter,
  previousQuarterDate,
  noAssessmentsCompleted,
  benchmark,
  benchmarksAreLoading
}: IProps) => {
  // calculate increase or decrease from previous score (if it exists)
  return (
    <TotalScoreContainer variant='outlined'>
      <Typography
        variant='h6'
        sx={{
          fontSize: '16px',
          lineHeight: '20px',
          letterSpacing: '2px',
          marginBottom: '16px',
          color: noAssessmentsCompleted ? '#ACADAF' : 'black'
        }}
      >
        CURRENT SCORE
      </Typography>
      <EIScoreBlock
        noAssessmentsCompleted={noAssessmentsCompleted}
        acquiredScore={acquiredScore || latestScore}
      />
      {previousAcquiredScore ? (
        <>
          <ScoreDelta
            acquiredScore={acquiredScore}
            previousAcquiredScore={previousAcquiredScore}
            previousQuarter={previousQuarter}
            previousQuarterDate={previousQuarterDate}
            noAssessmentsCompleted={noAssessmentsCompleted}
          />

          {benchmark && !benchmarksAreLoading && latestScore && (
            <>
              <Divider
                style={{
                  background: '#E5E5E5',
                  width: '300px',
                  height: '1px',
                  marginTop: '16px'
                }}
              />
              <AverageScore benchmark={benchmark} acquiredScore={latestScore} />
            </>
          )}
        </>
      ) : (
        <Box>
          {latestQuarter && latestYear && (
            <Typography
              variant='subtitle1'
              sx={{
                textAlign: 'center',
                fontWeight: 700,
                letterSpacing: '0.17em',
                color: Color.black
              }}
            >
              {`Q${latestQuarter} — ${latestYear}`}
            </Typography>
          )}
          {benchmark && !benchmarksAreLoading && latestScore && (
            <>
              <Divider
                style={{
                  background: '#E5E5E5',
                  width: '289px',
                  height: '1px',
                  marginTop: '16px'
                }}
              />
              <AverageScore benchmark={benchmark} acquiredScore={latestScore} />
            </>
          )}
        </Box>
      )}
    </TotalScoreContainer>
  )
}

export default TotalAssessmentScore
