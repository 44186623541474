import React, { useState } from 'react'
import PageCarouselSlide from './PageCarouselSlide'
import { CarouselSlide } from 'model/assessment'
import Paper, { PaperProps } from '@mui/material/Paper'
import Box, { BoxProps } from '@mui/material/Box'
import IconButton, { IconButtonProps } from '@mui/material/IconButton'
import { styled } from '@mui/material/styles'
import { ArrowLeftIcon, ArrowRightIcon } from 'components'
import { theme, Color } from 'components/SharedStyles'

const CarouselContainer = styled(Paper)<PaperProps>(() => ({
  height: '900px',
  marginTop: '30px',
  borderRadius: '8px',
  padding: 0,
  [theme.breakpoints.up('lg')]: {
    height: '755px'
  }
}))

const SlideContainer = styled(Box)<BoxProps>({
  height: '900px',
  display: 'flex',
  overflow: 'hidden',
  [theme.breakpoints.up('sm')]: {
    width: '600px'
  },
  [theme.breakpoints.up('md')]: {
    width: '800px'
  },
  [theme.breakpoints.up('lg')]: {
    width: '1100px',
    height: '755px'
  }
})

interface PageCarouselProps {
  slides: CarouselSlide[]
}

const ArrowButton = styled(IconButton)<IconButtonProps>(({ color }) => ({
  width: 48,
  height: 48,
  backgroundColor: color === 'primary' ? Color.main : Color.grey200,
  '&:hover': {
    //reset default styles
    backgroundColor:
      color === 'primary'
        ? `${Color.main} !important`
        : `${Color.grey200} !important`,
    filter: 'unset !important',
    opacity: 'unset !important'
  }
}))

const NavContainer = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: 'inherit',
  padding: '16px 60px 32px'
}))

const PageCarousel = ({ slides }: PageCarouselProps) => {
  const [activeIndex, setActiveIndex] = useState(0)

  const onClick = (direction: string) => {
    if (direction === 'left') {
      setActiveIndex(activeIndex - 1)
    } else {
      setActiveIndex(activeIndex + 1)
    }
  }
  return (
    <CarouselContainer variant='outlined' elevation={0}>
      <SlideContainer>
        <Box
          sx={{
            transform: `translateX(-${activeIndex * 100}%)`,
            transition: 'transform 0.3s',
            width: '100%',
            display: 'flex',
            WebkitTransformStyle: 'preserve-3d',
            WebkitBackfaceVisibility: 'hidden'
          }}
        >
          {slides.map((slide, idx) => (
            <PageCarouselSlide key={idx} slide={slide} idx={idx} />
          ))}
        </Box>
      </SlideContainer>
      <Box
        sx={{
          width: 'inherit',
          margin: '-100px 60px 8px 60px',
          borderTop: `1.5px solid ${Color.main}`
        }}
      />
      <NavContainer>
        {activeIndex > 0 ? (
          <ArrowButton
            disableRipple
            color={'secondary'}
            onClick={() => onClick('left')}
          >
            <ArrowLeftIcon color='secondary' />
          </ArrowButton>
        ) : (
          <div></div>
        )}
        {activeIndex < 3 && (
          <ArrowButton
            disableRipple
            color={'primary'}
            onClick={() => onClick('right')}
          >
            <ArrowRightIcon />
          </ArrowButton>
        )}
      </NavContainer>
    </CarouselContainer>
  )
}
export default PageCarousel
