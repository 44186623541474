import React, { useContext } from 'react'
import { Grid, Typography } from '@mui/material'
import { Color } from 'components/SharedStyles'

import { KeyStrength } from 'model'
import { DashboardContext } from 'context/Dashboard'

const KeyStrengths = () => {
  const { employerAssessmentKeyStrengths } = useContext(DashboardContext)

  const colors = ['#B83282', '#38009F', '#068D8E', '#375FC6']

  return (
    <>
      {employerAssessmentKeyStrengths &&
        employerAssessmentKeyStrengths.length > 0 && (
          <>
            <Typography variant='h5' margin='24px 0 -8px 24px' color='black'>
              Key Strengths
            </Typography>
            <Grid item xs={12}>
              <Grid
                container
                sx={{
                  padding: '32px 0 32px',
                  backgroundColor: Color.white,
                  border: '1px solid #E5E5E5',
                  borderRadius: '8px',
                  marginLeft: '0px'
                }}
              >
                {employerAssessmentKeyStrengths.map(
                  (keyStrength: KeyStrength, index: number) => (
                    <Grid
                      item
                      xs={12 / employerAssessmentKeyStrengths.length}
                      key={index}
                      sx={{
                        borderRight:
                          index !== employerAssessmentKeyStrengths.length - 1
                            ? '2px solid #E5E5E5'
                            : 'none',
                        padding: '0 32px'
                      }}
                    >
                      <Typography
                        variant='h7'
                        sx={{
                          letterSpacing: '0.14em',
                          fontSize: '14px',
                          paddingBottom: '8px',
                          textTransform: 'uppercase',
                          color:
                            keyStrength.categoryName === 'Defining & Tracking'
                              ? colors[0]
                              : keyStrength.categoryName ===
                                'Attracting & Sourcing'
                              ? colors[1]
                              : keyStrength.categoryName ===
                                'Interviewing & Engaging'
                              ? colors[2]
                              : colors[3]
                        }}
                      >
                        {keyStrength.categoryName}
                      </Typography>
                      <Typography variant='body1'>
                        {keyStrength.keyStrengthText}
                      </Typography>
                    </Grid>
                  )
                )}
              </Grid>
            </Grid>
          </>
        )}
    </>
  )
}

export default KeyStrengths
