import React, { useEffect, useState, useContext } from 'react'
import {
  Button,
  ButtonProps,
  Box,
  Modal,
  FormGroup,
  FormControl,
  FormControlLabel,
  Checkbox,
  Typography,
  IconButton
} from '@mui/material'
import { styled } from '@mui/material/styles'
import html2canvas from 'html2canvas'
import { jsPDF as JsPDF } from 'jspdf'
import { Color } from 'components/SharedStyles'
import CloseIcon from '@mui/icons-material/Close'
import { DashboardContext } from 'context/Dashboard'
import { RecommendationsContext } from 'context/Recommendation'
import { Recommendation } from 'model/recommendation'
import { EmployerQuarterHistory } from 'model/assessment'

const DownloadButton = styled(Button)<ButtonProps>(() => ({
  backgroundColor: '#F2F3F3',
  textDecoration: 'none',
  padding: '12px 16px',
  borderRadius: '8px',
  fontSize: '16px',
  lineHeight: '24px',
  fontFamily: 'IBM Plex Sans',
  '&:hover': {
    backgroundColor: '#F2F3F3',
    textDecoration: 'none'
  }
}))

interface IProps {
  isVisible: boolean
  setIsVisible: React.Dispatch<React.SetStateAction<boolean>>
  downloadClicked: boolean
  setDownloadClicked: React.Dispatch<React.SetStateAction<boolean>>
  setSnackbarOpen: React.Dispatch<React.SetStateAction<boolean>>
  selectedComponents: string[]
  setSelectedComponents: React.Dispatch<any>
}

const DownloadDashboardButton = ({
  isVisible,
  setIsVisible,
  downloadClicked,
  setDownloadClicked,
  setSnackbarOpen,
  selectedComponents,
  setSelectedComponents
}: IProps) => {
  const { employerAssessmentKeyStrengths, latestAssessmentData } =
    useContext(DashboardContext)
  const { recommendations } = useContext(RecommendationsContext)
  const latestAssessment: EmployerQuarterHistory | null = latestAssessmentData
  const [downloaded, setDownloaded] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [counter, setCounter] = useState(0)

  const isClicked: Function = () => {
    setIsVisible(true)
    setDownloadClicked(true)
  }

  const handleChange = (componentName: string) => {
    if (selectedComponents.includes(componentName)) {
      const newSelected = selectedComponents.filter(
        name => name !== componentName
      )
      setSelectedComponents(newSelected)
      return
    }
    setSelectedComponents([...selectedComponents, componentName])
  }

  useEffect(() => {
    if (counter > 0) return
    if (isVisible && downloadClicked && !downloaded) {
      setCounter(counter + 1)
      const date = new Date().toDateString().slice(4).split(' ').join('_')
      const scoreDashboard: any = document.getElementById('score-dashboard')

      html2canvas(scoreDashboard)
        .then(canvas => {
          const img = canvas.toDataURL('image/png')
          const pdf = new JsPDF('p', 'px')
          pdf.internal.pageSize.width = scoreDashboard.offsetWidth
          pdf.internal.pageSize.height = scoreDashboard.offsetHeight

          pdf.addImage(
            img,
            'PNG',
            10,
            10,
            scoreDashboard.offsetWidth - 40,
            scoreDashboard.offsetHeight - 40
          )
          pdf.save(`EHI_Dashboard_${date}.pdf`)
          setDownloaded(true)
          setSnackbarOpen(true)
          // Clear the downloadClicked event to prevent multiple downloads
          setDownloadClicked(false)
        })
        .then(() => {
          // After PDF is downloaded, reset downloaded event
          setDownloaded(false)
          setSelectedComponents([
            'Current Score',
            'Top Strength & Opportunity',
            'Development Stage',
            'Key Recommendations',
            'Progress by Category',
            'Key Strengths'
          ])
          setOpenModal(false)
          setCounter(0)
        })
    }
  }, [
    isVisible,
    downloadClicked,
    setDownloadClicked,
    downloaded,
    setDownloaded,
    setSnackbarOpen,
    setSelectedComponents,
    selectedComponents,
    counter,
    setCounter
  ])

  // Recommendations Section - Get the latest recommendations
  const filteredRecommendations = recommendations.filter(
    recommendation =>
      recommendation.status !== 'archived' &&
      recommendation.employerAssessmentId === latestAssessment?.id &&
      recommendation.assessmentQuestionText !== null
  )

  const keyRecommendations: Recommendation[] = filteredRecommendations.slice(
    0,
    3
  )

  const dashboardComponents = [
    'Current Score',
    'Top Strength & Opportunity',
    'Development Stage'
  ]

  if (keyRecommendations?.length > 0) {
    dashboardComponents.push('Key Recommendations', 'Progress by Category')
  } else {
    dashboardComponents.push('Progress by Category')
  }

  if (employerAssessmentKeyStrengths?.length > 0) {
    dashboardComponents.push('Key Strengths')
  }

  return (
    <Box>
      <DownloadButton
        disableRipple
        disabled={downloadClicked}
        onClick={() => setOpenModal(true)}
      >
        Download as PDF
      </DownloadButton>
      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            width: '600px',
            padding: '64px 24px 24px 24px',
            backgroundColor: Color.white,
            transform: 'translate(-50%, -50%)',
            borderRadius: '8px'
          }}
        >
          <IconButton
            disableRipple
            onClick={() => {
              setOpenModal(false)
              setSelectedComponents(dashboardComponents)
            }}
            sx={{
              position: 'absolute',
              top: 24,
              right: 24,
              padding: 0,
              '&:hover': {
                backgroundColor: 'transparent'
              }
            }}
          >
            <CloseIcon sx={{ color: 'black', fontSize: '20px' }} />
          </IconButton>
          <Typography variant='h3' sx={{ marginBottom: '26px' }}>
            What would you like to include in your download?
          </Typography>
          <FormControl
            sx={{ marginBottom: '20px', marginLeft: '12px', width: '100%' }}
          >
            <FormGroup>
              {dashboardComponents.map(component => (
                <FormControlLabel
                  key={component}
                  control={
                    <Checkbox
                      disableRipple
                      checked={selectedComponents.includes(component)}
                      onChange={() => handleChange(component)}
                      name={component}
                      sx={{
                        marginRight: '12px',
                        '&:hover': {
                          backgroundColor: 'transparent'
                        }
                      }}
                    />
                  }
                  label={component}
                />
              ))}
            </FormGroup>
          </FormControl>
          <Button
            disableRipple
            disabled={selectedComponents.length === 0}
            onClick={() => setSelectedComponents([])}
            sx={{
              border: 'none',
              marginBottom: '20px',
              marginLeft: '10px',
              padding: 0,
              textDecoration: 'underline',
              fontWeight: 700,
              '&:hover': {
                backgroundColor: 'transparent',
                textDecoration: 'underline'
              }
            }}
          >
            Clear All
          </Button>
          <Box sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
            <Button
              variant='contained'
              disableRipple
              disabled={downloadClicked || selectedComponents.length === 0}
              onClick={() => {
                if (selectedComponents.length) isClicked(true)
              }}
              sx={{
                backgroundColor: Color.black,
                color: Color.white,
                '&:hover': {
                  backgroundColor: Color.black
                }
              }}
            >
              Confirm
            </Button>
            <Button
              variant='contained'
              disableRipple
              onClick={() => {
                setOpenModal(false)
                setSelectedComponents(dashboardComponents)
              }}
              sx={{
                backgroundColor: Color.grey100,
                color: Color.black,
                marginRight: '16px',
                '&:hover': {
                  backgroundColor: Color.grey100
                }
              }}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  )
}

export default DownloadDashboardButton
