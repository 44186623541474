import React from 'react'
import { Box, Paper, PaperProps, Typography, styled } from '@mui/material'
import { theme } from 'components/SharedStyles'
import TopComponentDelta from 'components/EIBenchmark/TopComponentDelta'
import TopComponentProgressBar from 'components/EIBenchmark/TopComponentProgressBar'
import { Color } from 'components'

const TopComponentsContainer = styled(Paper)<PaperProps>(() => ({
  height: '225px',
  display: 'flex',
  flexDirection: 'column',
  padding: '24px',
  boxShadow: 'none',
  border: '1px solid #E5E5E5',
  borderRadius: '8px',
  [theme.breakpoints.down(1370)]: {
    padding: '32px 40px 46px 40px'
  },
  [theme.breakpoints.down('lg')]: {
    padding: '32px 83px 46px 50px'
  },
  [theme.breakpoints.down(1000)]: {
    padding: '32px 40px 46px 40px'
  }
}))

const getCategorySubtitle = (category: string) => {
  switch (category) {
    case 'defining-tracking':
      return 'Defining & Tracking'
    case 'interviewing-engaging':
      return 'Interviewing & Engaging'
    case 'onboarding-advancing':
      return 'Onboarding & Advancing'
    case 'attracting-sourcing':
      return 'Attracting & Sourcing'
    default:
      return ''
  }
}

interface IProps {
  type: 'strength' | 'opportunity'
  currentScore: number | undefined
  previousScore: number | undefined
  benchmarkScore: number | undefined
  benchmarkScoreLoading: boolean
  category: string | undefined
  quarterDetails: string | undefined
}

const TopComponent = ({
  type,
  currentScore,
  previousScore,
  benchmarkScore,
  benchmarkScoreLoading,
  category,
  quarterDetails
}: IProps) => {
  const componentTitle: string =
    type === 'strength' ? 'Top Strength' : 'Top Opportunity'
  const componentSubtitle: string = category
    ? getCategorySubtitle(category)
    : ''

  return (
    <TopComponentsContainer>
      <Box display='flex' flexDirection='row'>
        <Box sx={{ width: '60%' }}>
          <Typography
            sx={{
              fontFamily: 'IBM Plex Sans',
              fontSize: '20px',
              fontWeight: 'bold',
              letterSpacing: '-0.48px',
              color: currentScore ? Color.black : Color.grey500
            }}
          >
            {componentTitle}:{' '}
            <span style={{ fontWeight: '400' }}>{componentSubtitle}</span>
          </Typography>
        </Box>
        <Box sx={{ width: '40%' }}>
          {currentScore && quarterDetails && (
            <TopComponentDelta
              currentScore={currentScore}
              previousScore={previousScore}
              delta={currentScore - previousScore!}
              quarterDetails={quarterDetails}
            />
          )}
        </Box>
      </Box>
      <Box sx={{ width: '100%', padding: '45px 0 0' }}>
        <TopComponentProgressBar
          benchmarkScore={benchmarkScore}
          benchmarkScoreLoading={benchmarkScoreLoading}
          currentScore={currentScore}
          category={category}
        />
      </Box>
    </TopComponentsContainer>
  )
}
export default TopComponent
