import React, { useContext } from 'react'
import { Box, Typography, BoxProps } from '@mui/material'
import { styled } from '@mui/material/styles'
import { theme } from 'components/SharedStyles'
import { AssessmentOverviewContext } from 'context/AssessmentOverview'

const AssessmentProgressContainer = styled(Box)<BoxProps>(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '24px',
  height: '100%',
  backgroundColor: '#F1FAFF',
  borderRadius: '8px',
  boxSizing: 'border-box',
  [theme.breakpoints.down(1350)]: {
    flexDirection: 'column-reverse',
    justifyContent: 'center',
    alignItems: 'center'
  },
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'row',
    justifyContent: 'space-around'
  },
  [theme.breakpoints.down(650)]: {
    flexDirection: 'column-reverse',
    justifyContent: 'center',
    alignItems: 'center'
  }
}))

const StyledSvg = styled('svg')(() => ({
  height: '100px',
  width: '100px'
}))

const StyledCircle = styled('circle')(() => ({
  fill: 'transparent',
  transformOrigin: '50% 50%',
  transform: 'rotate(270deg)'
}))

const AssessmentProgress = () => {
  const { progress } = useContext(AssessmentOverviewContext)
  return (
    <AssessmentProgressContainer>
      <Typography
        variant='subtitle1'
        sx={{
          fontWeight: 700,
          lineHeight: '16px',
          letterSpacing: '0.17em',
          width: '80px',
          color: '#0079B6',
          [theme.breakpoints.down(1350)]: {
            padding: '10px 0 20px'
          },
          [theme.breakpoints.down('lg')]: {
            padding: '0'
          },
          [theme.breakpoints.down(650)]: {
            padding: '10px 0 20px'
          }
        }}
      >
        {progress ?? 0}% COMPLETE
      </Typography>
      <Box>
        <StyledSvg>
          <StyledCircle
            cx='50'
            cy='50'
            r='40'
            style={{
              strokeWidth: 5,
              stroke: '#FFFFFF',
              strokeDasharray: '251',
              strokeDashoffset: 0
            }}
          />
          <StyledCircle
            cx='50'
            cy='50'
            r='40'
            style={{
              strokeWidth: 12,
              stroke: '#0079B6',
              strokeDasharray: '251',
              strokeDashoffset: 251 - 2.51 * progress
            }}
          />
        </StyledSvg>
      </Box>
    </AssessmentProgressContainer>
  )
}

export default AssessmentProgress
