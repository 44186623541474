import React from 'react'
import { Box, Typography } from '@mui/material'
import QuestionsTable from './QuestionsTable'

const AssessmentReport = () => {
  return (
    <Box>
      <Typography sx={{ fontSize: '18px', fontWeight: 700, margin: '25px 0' }}>
        Assessment Report
      </Typography>
      <QuestionsTable />
    </Box>
  )
}
export default AssessmentReport
