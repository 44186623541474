import { useLazyQuery, useMutation } from '@apollo/client'
import { GET_FOLLOWUP_QUESTIONS, UPDATE_QUESTION } from 'context/Assessment/gql'

const useAssessment = () => {
  const [updateQuestion] = useMutation(UPDATE_QUESTION)
  const [loadFollowupQuestion] = useLazyQuery(GET_FOLLOWUP_QUESTIONS, {
    fetchPolicy: 'cache-and-network'
  })

  return {
    updateQuestion,
    loadFollowupQuestion
  }
}

export default useAssessment
