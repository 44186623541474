import React from 'react'
import { Box, BoxProps } from '@mui/material'
import { Color } from 'components/SharedStyles'
import { styled } from '@mui/material/styles'

interface ProgressBarProps extends BoxProps {
  progress?: number
}

const ProgressBarBackground = styled(Box, {
  shouldForwardProp: prop =>
    prop !== 'categoryCompleted' &&
    prop !== 'hasActiveAssessment' &&
    prop !== 'category' &&
    prop !== 'progress'
})<ProgressBarProps>(({ progress }) => ({
  border: `1px solid ${Color.black}`,
  margin: 'auto',
  boxSizing: 'border-box',
  borderRadius: '100px',
  width: 261,
  height: 24,
  backgroundColor: Color.grey50
}))

const ProgressBarForeground = styled(Box, {
  shouldForwardProp: prop =>
    prop !== 'hasActiveAssessment' && prop !== 'category' && prop !== 'progress'
})<ProgressBarProps>(({ progress }) => ({
  borderRadius: progress && progress < 95 ? '100px 0 0 100px' : '100px',
  width: progress ? `${progress}%` : '0%',
  height: '100%',
  background: '#000'
}))

const ProgressBar = ({ progress }: any) => {
  return (
    <Box>
      <ProgressBarBackground progress={progress}>
        <ProgressBarForeground progress={progress} />
      </ProgressBarBackground>
    </Box>
  )
}

export default ProgressBar
