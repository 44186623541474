import React, { useContext } from 'react'
import { Grid, GridProps } from '@mui/material'
import QuestionContent from './QuestionContent'
import QuestionTermsDropdown from './QuestionTermsDropdown'
import { styled } from '@mui/material/styles'
import { AssessmentContext } from 'context/Assessment'

const ActiveQuestionContainer = styled(Grid)<GridProps>(() => ({
  width: '100%',
  height: 'auto',
  display: 'flex',
  flexDirection: 'row'
}))

const ActiveQuestion = () => {
  const { activeQuestion } = useContext(AssessmentContext)
  return (
    <ActiveQuestionContainer container>
      <Grid item xs={12} lg={7}>
        <QuestionContent />
      </Grid>
      <Grid item xs={12} lg={5}>
        {activeQuestion?.description && <QuestionTermsDropdown />}
      </Grid>
    </ActiveQuestionContainer>
  )
}

export default ActiveQuestion
