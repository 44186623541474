import React, { useContext, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Button, Grid, Paper, PaperProps, Typography, Box } from '@mui/material'
import {
  ArrowDropDownRounded as ArrowDropDownIcon,
  ArrowDropUpRounded as ArrowDropUpIcon
} from '@mui/icons-material'
import { Color, theme } from 'components/SharedStyles'
import CategoryQuestionList from './CategoryQuestionList'
import { ArrowCircleRightIcon } from 'components'
import { Question } from 'model/assessment'
import { CATEGORY_COLOR_4_TEXT } from 'constants/'
import { styled } from '@mui/material/styles'
import { AssessmentOverviewContext } from 'context/AssessmentOverview'
import CategoryProgressBar from './CategoryProgressBar'
import { getCategoryDescriptionBySlug } from 'utils/dashboardUtils'
import { CategoryContext } from 'context/Category'

const CategoryPaper = styled(Paper)<PaperProps>(() => ({
  width: '100%',
  marginBottom: '24px',
  minWidth: '540px',
  [theme.breakpoints.up('lg')]: {
    minHeight: '164px',
    display: 'flex',
    flexDirection: 'column'
  }
}))

const progressToStatus = (progress?: number) => {
  switch (progress) {
    case 0:
      return 'Start section'

    case 100:
      return 'Revise section'

    default:
      return 'Continue section'
  }
}
const CategoryBox = () => {
  const { hasActiveAssessment } = useContext(AssessmentOverviewContext)
  const { slug, progress, name, employerAssessmentQuestions } =
    useContext(CategoryContext)
  const [showProgress, setShowProgress] = useState(false)
  const navigate = useNavigate()

  const handleClick = () => {
    const activeQuestionIndex = employerAssessmentQuestions?.findIndex(
      (question: Question) => !question.isCompleted
    )
    if (activeQuestionIndex === -1) {
      navigate(`/equity-index/assessment/${slug}/1`)
    } else {
      navigate(`/equity-index/assessment/${slug}/${activeQuestionIndex! + 1}`)
    }
  }

  const categoryDescription: string = getCategoryDescriptionBySlug(slug)

  return (
    <CategoryPaper variant='outlined'>
      <Box
        sx={{
          display: 'flex',
          [theme.breakpoints.down('lg')]: {
            flexDirection: 'column'
          }
        }}
      >
        <Grid lg={4} xs={12} item>
          <Typography
            color={
              hasActiveAssessment ? CATEGORY_COLOR_4_TEXT[name] : Color.grey500
            }
            variant='h6'
            marginBottom={3}
          >
            {name.replace('&', 'and')}
          </Typography>
          <Typography
            variant='body2'
            marginRight={4}
            color={hasActiveAssessment ? Color.black : Color.grey500}
          >
            {categoryDescription}
          </Typography>
        </Grid>
        <Grid
          lg={5}
          xs={12}
          item
          sx={{
            [theme.breakpoints.up('lg')]: {
              borderRight: `1px solid ${Color.border}`,
              borderLeft: `1px solid ${Color.border}`
            },
            [theme.breakpoints.down('lg')]: {
              padding: '32px',
              marginBottom: '8px'
            }
          }}
        >
          <Typography
            variant='subtitle2'
            marginBottom={4}
            color={hasActiveAssessment ? Color.black : Color.grey500}
          >
            {`${progress ?? 0}% COMPLETE`}
          </Typography>
          <CategoryProgressBar />
        </Grid>
        <Grid
          item
          xs={12}
          lg={3}
          sx={{
            height: 101,
            [theme.breakpoints.up('lg')]: {
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              alignItems: 'flex-end'
            },
            [theme.breakpoints.down('lg')]: {
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center'
            }
          }}
        >
          <Button
            variant='contained'
            onClick={handleClick}
            disabled={!hasActiveAssessment}
            disableRipple
            endIcon={
              <ArrowCircleRightIcon
                style={{ width: 16, height: 16, marginLeft: '8px' }}
              />
            }
            sx={{
              width: '80%',
              maxWidth: 225,
              height: 40,
              whiteSpace: 'nowrap',
              '&:hover': {
                backgroundColor: '#050B0E'
              }
            }}
          >
            {progressToStatus(progress)}
          </Button>
          <Button
            variant='outlined'
            disableRipple
            disabled={!hasActiveAssessment}
            endIcon={showProgress ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            onClick={() => setShowProgress(!showProgress)}
            sx={{
              width: '80%',
              maxWidth: 225,
              height: 40,
              whiteSpace: 'nowrap',
              '&:hover': {
                backgroundColor: 'transparent'
              }
            }}
          >
            {showProgress ? 'Hide progress' : 'Show progress'}
          </Button>
        </Grid>
      </Box>
      {showProgress && employerAssessmentQuestions && <CategoryQuestionList />}
    </CategoryPaper>
  )
}
export default CategoryBox
