/* eslint-disable react/jsx-no-useless-fragment */
import React, { createContext, useEffect, useMemo } from 'react'
import useDashboard from 'hooks/useDashboard'
import {
  Answer,
  Category,
  EpLatestAssessments,
  Question,
  EmployerQuarterHistory
} from 'model/assessment'
import { KeyStrength } from 'model'
import useEmployerAssessmentHistory from 'hooks/useEmployerAssessmentHistory'
import { INITIAL_EMPLOYER_ASSESSMENT_HISTORY } from './constant'

type DashboardContextData = {
  latestAssessmentData: EmployerQuarterHistory | null
  employerAssessmentHistory: EpLatestAssessments
  currentEmployerAssessmentHistory: EpLatestAssessments
  employerAssessmentKeyStrengths: KeyStrength[]
  hasCompletedAnAssessment: boolean
  questions: { employerAssessmentQuestions: Question[]; categoryName: string }[]
}

export const DashboardContext = createContext<DashboardContextData>({
  latestAssessmentData: null,
  employerAssessmentHistory: INITIAL_EMPLOYER_ASSESSMENT_HISTORY,
  currentEmployerAssessmentHistory: INITIAL_EMPLOYER_ASSESSMENT_HISTORY,
  employerAssessmentKeyStrengths: [],
  hasCompletedAnAssessment: false,
  questions: []
})

const preprocessQuestions = (latestAssessmentCategories: any) => {
  return latestAssessmentCategories?.map((category: Category) => {
    return {
      categoryName: category.name,
      employerAssessmentQuestions: category.employerAssessmentQuestions?.map(
        question => {
          // eslint-disable-next-line no-plusplus
          return {
            id: question.id,
            questionText: question.questionText,
            category: category.name,
            selectedAnswers: question.assessmentAnswers
              .filter((answer: Answer) =>
                question.selectedAnswerIds.includes(answer.id)
              )
              .map((answer: Answer) => answer.answerText),
            employerFollowupQuestions: question.employerFollowupQuestions?.map(
              followupQuestion => {
                return {
                  questionText: followupQuestion.questionText,
                  selectedAnswers: followupQuestion.assessmentAnswers
                    .filter((followupAnswer: Answer) =>
                      followupQuestion.selectedAnswerIds.includes(
                        followupAnswer.id
                      )
                    )
                    .map((followupAnswer: Answer) => followupAnswer.answerText)
                }
              }
            )
          }
        }
      )
    }
  })
}

export const DashboardProvider = ({ children }: any): JSX.Element => {
  const {
    getEmployerAssessmentHistory,
    employerAssessmentHistoryData,
    latestAssessmentData,
    currentEmployerAssessmentHistoryData,
    loading,
    hasCompletedAnAssessment
  } = useEmployerAssessmentHistory()
  useEffect(() => {
    getEmployerAssessmentHistory()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { employerAssessmentKeyStrengths } = useDashboard()

  const employerAssessmentHistory =
    employerAssessmentHistoryData?.employerAssessmentHistory
  const employerAssessmentCategories =
    currentEmployerAssessmentHistoryData?.employerAssessmentHistory
      ?.latestAssessment?.employerAssessmentCategories

  const value = useMemo(() => {
    const questions = preprocessQuestions(employerAssessmentCategories)
    return {
      employerAssessmentHistory,
      latestAssessmentData,
      currentEmployerAssessmentHistory:
        currentEmployerAssessmentHistoryData?.employerAssessmentHistory,
      employerAssessmentKeyStrengths,
      hasCompletedAnAssessment,
      questions
    }
  }, [
    employerAssessmentCategories,
    employerAssessmentHistory,
    latestAssessmentData,
    currentEmployerAssessmentHistoryData,
    employerAssessmentKeyStrengths,
    hasCompletedAnAssessment
  ])
  if (loading) {
    return <></>
  }
  return (
    <DashboardContext.Provider value={value}>
      {children}
    </DashboardContext.Provider>
  )
}
