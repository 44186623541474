/* eslint-disable no-param-reassign */
export const fillEmployerAssessment = (
  updateQuery: any,
  blankStateAssessment: any
) =>
  updateQuery(() => {
    return {
      employerAssessment: {
        id: '',
        employerId: '',
        acquiredScore: 0,
        progress: 0,
        status: '',
        __typename: 'EmployerAssessment',
        employerAssessmentCategories: blankStateAssessment.categories.map(
          (category: any) => {
            category = {
              ...category,
              categoryId: '',
              acquiredScore: 0,
              progress: 0,
              isCompleted: false,
              employerAssessmentQuestions: []
            }
            return category
          }
        ),
        totalScore: 168
      }
    }
  })
