import React, { useState, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Button, Divider, Paper, Typography } from '@mui/material'
import {
  DataGridPro,
  GridValueGetterParams,
  GridRenderCellParams
} from '@mui/x-data-grid-pro'
import { DashboardContext } from 'context/Dashboard'
import { Color, theme } from 'components/SharedStyles'
import { CATEGORY_COLOR_1_BKGD, CATEGORY_COLOR_4_TEXT } from 'constants/'
import { Question } from 'model/assessment'
import CategoryHeaders from './CategoryHeaders'
import Filters from './CategoriesFilter'
import { tableStyles } from './styles'

const QuestionsTable = () => {
  const navigate = useNavigate()
  const { questions } = useContext(DashboardContext)
  const [filteredCategories, setFilteredCategories] = useState<string[]>([])

  const categories = questions.map((question: any) => ({
    label: question.categoryName,
    number: question.employerAssessmentQuestions.length
  }))

  const employerAssessmentQuestions = questions.map((question: any) => {
    if (filteredCategories.length === 0) {
      return question.employerAssessmentQuestions
    }
    if (filteredCategories.includes(question.categoryName)) {
      return question.employerAssessmentQuestions
    }
    return []
  })

  const rows = employerAssessmentQuestions
    .reduce((acc, array) => acc.concat(array), [])
    .reduce((acc: any, question: any, index: number) => {
      const baseNumber = index + 1
      acc.push({ ...question, questionNumber: baseNumber })
      const { employerFollowupQuestions } = question
      if (employerFollowupQuestions.length > 0) {
        employerFollowupQuestions.forEach(
          (followupQuestion: Question, followIndex: number) => {
            acc.push({
              ...followupQuestion,
              category: question.category,
              id: `${question.id}-${baseNumber}.${followIndex + 1}`,
              questionNumber: `${baseNumber}.${followIndex + 1}`
            })
            // eslint-disable-next-line no-plusplus
          }
        )
      }

      return acc
    }, [])

  return (
    <Paper
      variant='outlined'
      sx={{
        padding: '40px 32px',
        minHeight: '281px',
        minWidth: '540px',
        position: 'relative'
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <Filters
          filteredCategories={filteredCategories}
          setFilteredCategories={setFilteredCategories}
        />
        <Box sx={{ display: 'flex', gap: '16px', alignItems: 'center' }}>
          <Button
            sx={{
              background: '#F2F3F3',
              borderRadius: '8px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '44px',
              width: '100%',
              [theme.breakpoints.down(1146)]: {
                marginLeft: '10px',
                fontSize: '12px'
              }
            }}
            onClick={() => navigate('/action-plan/recommendations')}
          >
            View Recommendations
          </Button>
          <Button
            sx={{
              background: '#F2F3F3',
              borderRadius: '8px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '44px',
              width: '100%',
              [theme.breakpoints.down(1146)]: {
                fontSize: '12px'
              }
            }}
            onClick={() => navigate('/action-plan')}
          >
            View Action Plan
          </Button>
        </Box>
      </Box>
      <Divider sx={{ marginTop: '24px', marginBottom: '24px' }} />
      <CategoryHeaders categories={categories} />
      <DataGridPro
        rows={rows}
        getRowId={row => row.id}
        pagination
        disableColumnMenu
        disableRowSelectionOnClick
        getRowHeight={(params: any) => {
          if (
            (params.model.selectedAnswers.length > 1 &&
              !params.model.selectedAnswers.findIndex(
                (answer: any) => answer.length > 30
              )) ||
            params.model.selectedAnswers.length > 3
          ) {
            return 200
          }
          return 100
        }}
        pageSizeOptions={[5, 10, 25]}
        initialState={{
          pagination: {
            paginationModel: { pageSize: 25, page: 0 }
          }
        }}
        sx={tableStyles}
        columns={[
          {
            field: 'questionNumber',
            headerName: 'No.',
            flex: 1,
            minWidth: 75,
            headerClassName: 'headers',
            valueGetter: (params: GridValueGetterParams) => {
              return params?.row?.questionNumber
            },
            renderCell: (params: GridRenderCellParams) => {
              return (
                <Typography
                  sx={{
                    '&:last-child td, &:last-child th': { border: 0 },
                    height: 35,
                    width: 35,
                    backgroundColor: Color.grey100,
                    borderRadius: '50%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',

                    fontWeight: 700
                  }}
                >
                  {params.row.questionNumber}
                </Typography>
              )
            }
          },
          {
            field: 'questionText',
            headerName: 'Questions',
            flex: 8,
            minWidth: 348,
            headerClassName: 'headers',
            valueGetter: (params: GridValueGetterParams) => {
              return params?.row?.questionText
            },
            renderCell: (params: GridRenderCellParams) => {
              return (
                <Typography
                  sx={{
                    fontSize: '14px',
                    fontWeight: 400,
                    width: '90%',
                    display: '-webkit-box',
                    wordBreak: 'break-word',
                    WebkitBoxOrient: 'vertical',
                    WebkitLineClamp: '2',
                    whiteSpace: 'normal'
                  }}
                  textOverflow='ellipsis'
                  overflow='hidden'
                >
                  {params.row.questionText}
                </Typography>
              )
            }
          },
          {
            field: 'questionResponse',
            headerName: 'Response',
            flex: 5,
            minWidth: 100,
            headerClassName: 'headers',
            valueGetter: (params: GridValueGetterParams) => {
              return params?.row?.questionResponse
            },
            renderCell: (params: GridRenderCellParams) => {
              return (
                <Typography
                  variant='body1'
                  color={Color.black}
                  textTransform='capitalize'
                  component='p'
                  whiteSpace='break-spaces'
                >
                  {params.row.selectedAnswers.join('\n')}
                </Typography>
              )
            }
          },
          {
            field: 'questionCategory',
            headerName: 'Category',
            flex: 4,
            minWidth: 200,
            headerClassName: 'headers',
            valueGetter: (params: GridValueGetterParams) => {
              return params?.row?.category
            },
            renderCell: (params: GridRenderCellParams) => {
              return (
                <Typography
                  sx={{
                    background: CATEGORY_COLOR_1_BKGD[params.row.category],
                    border: `1px solid ${
                      CATEGORY_COLOR_4_TEXT[params.row.category]
                    }`,
                    borderRadius: '8px',
                    display: 'flex',
                    padding: '2px 8px',
                    width: 'fit-content',
                    fontSize: '12px',
                    fontWeight: 700,
                    textTransform: 'uppercase',
                    letterSpacing: '1.15px'
                  }}
                >
                  {params.row.category}
                </Typography>
              )
            }
          }
        ]}
      />
    </Paper>
  )
}
export default QuestionsTable
