import { gql } from '@apollo/client'
import { EmployerAssessment } from 'context/Assessment/gql'

export const CHECK_EMPLOYER_ASSESSMENT_STATUS = gql`
  query {
    employerAssessment {
      id
    }
  }
`

// Mutations

// TODO: Will need to ask what clientMutationId is
// TODO: Will need to resume the Todo above
export const CREATE_EMPLOYER_ASSESSMENT = gql`
  mutation {
    createEmployerAssessment(input: {}) {
      ...employerAssessment
    }
  }
  ${EmployerAssessment}
`

export const GET_KEY_STRENGTHS = gql`
  query {
    employerAssessmentKeyStrengths {
      keyStrengthText
      order
      categoryName
    }
  }
`
