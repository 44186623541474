import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Paper,
  Typography,
  Grid,
  Button,
  Divider,
  DividerProps,
  IconButton
} from '@mui/material'
import { GoalNode } from 'model/goalManager'
import { Color } from 'components/SharedStyles'
import { styled } from '@mui/material/styles'
import SwapVertRoundedIcon from '@mui/icons-material/SwapVertRounded'

import useGoals from 'hooks/useGoals'
import GoalList from './GoalList'
enum Column {
  customTitle = 'customTitle',
  category = 'category',
  completedAt = 'completedAt'
}
const VerticalDivider = styled(Divider)<DividerProps>(() => ({
  height: '40px'
}))
const GoalsTable = () => {
  const navigate = useNavigate()
  const [sortColumn, setSortColumn] = useState<Column | ''>('')
  const [isAscending, setIsAscending] = useState(true)
  const { getRecentFiveCompletedGoals } = useGoals()
  const goals = getRecentFiveCompletedGoals()
  const isGoalEmpty = goals?.length === 0
  const titleColor = isGoalEmpty ? Color.grey400 : Color.black

  switch (sortColumn) {
    case Column.category:
      if (isAscending) {
        goals.sort((a: GoalNode, b: GoalNode) =>
          b.node[sortColumn].localeCompare(a.node[sortColumn])
        )
      } else {
        goals.sort((a: GoalNode, b: GoalNode) =>
          a.node[sortColumn].localeCompare(b.node[sortColumn])
        )
      }

      break
    case Column.customTitle:
      if (isAscending) {
        goals.sort((a: GoalNode, b: GoalNode) =>
          (b.node[sortColumn] || b.node['title']).localeCompare(
            a.node[sortColumn] || a.node['title']
          )
        )
      } else {
        goals.sort((a: GoalNode, b: GoalNode) =>
          (a.node[sortColumn] || a.node['title']).localeCompare(
            b.node[sortColumn] || b.node['title']
          )
        )
      }

      break
    case Column.completedAt:
      if (isAscending) {
        goals.sort(
          (a: GoalNode, b: GoalNode) =>
            new Date(b.node[sortColumn]).getTime() -
            new Date(a.node[sortColumn]).getTime()
        )
      } else {
        goals.sort(
          (a: GoalNode, b: GoalNode) =>
            new Date(a.node[sortColumn]).getTime() -
            new Date(b.node[sortColumn]).getTime()
        )
      }
      break
  }

  return (
    <Paper
      variant='outlined'
      sx={{
        padding: '40px 32px 0 32px',
        minHeight: '281px',
        minWidth: '540px',
        position: 'relative'
      }}
    >
      <Grid container padding='0' marginBottom={'8px'}>
        <Grid item container lg={6} xs={4}>
          <Typography variant='h6' color={titleColor} paddingTop='8px'>
            Goal
          </Typography>
          <IconButton
            onClick={() => {
              setIsAscending(!isAscending)
              setSortColumn(Column.customTitle)
            }}
          >
            <SwapVertRoundedIcon color={isGoalEmpty ? 'disabled' : 'primary'} />
          </IconButton>
        </Grid>
        <VerticalDivider orientation='vertical' />

        <Grid item container lg={3.5} xs={3}>
          <Typography
            variant='h6'
            color={titleColor}
            paddingLeft='12px'
            paddingTop='8px'
          >
            Category
          </Typography>
          <IconButton
            onClick={() => {
              setIsAscending(!isAscending)
              setSortColumn(Column.category)
            }}
          >
            <SwapVertRoundedIcon color={isGoalEmpty ? 'disabled' : 'primary'} />
          </IconButton>
        </Grid>
        <VerticalDivider orientation='vertical' />

        <Grid item container lg={2} xs={3} flexWrap='nowrap'>
          <Typography
            variant='h6'
            color={titleColor}
            paddingLeft='12px'
            paddingTop='8px'
          >
            Completed date
          </Typography>
          <IconButton
            onClick={() => {
              setIsAscending(!isAscending)
              setSortColumn(Column.completedAt)
            }}
          >
            <SwapVertRoundedIcon color={isGoalEmpty ? 'disabled' : 'primary'} />
          </IconButton>
        </Grid>
      </Grid>
      <GoalList goals={goals} />
      <Divider sx={{ width: '100%', position: 'absolute', left: 0 }} />
      <Grid
        container
        padding='32px 0'
        justifyContent='space-between'
        alignItems='center'
      >
        <Typography variant='h5'>Want to see all your goals?</Typography>
        <Button variant='contained' onClick={() => navigate('/action-plan')}>
          Manage your goals
        </Button>
      </Grid>
    </Paper>
  )
}

export default GoalsTable
