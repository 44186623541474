import React from 'react'
import CategoryDropdown from './CategoryDropdown'

interface Props {
  filteredCategories: string[]
  setFilteredCategories: (filteredCategories: string[]) => void
}
const Filters = ({ setFilteredCategories, filteredCategories }: Props) => {
  return (
    <CategoryDropdown
      sx={{ width: '340px' }}
      label='Categories'
      values={filteredCategories}
      onChange={(e: any) => {
        setFilteredCategories(e.target.value)
      }}
      options={[
        'Defining & Tracking',
        'Attracting & Sourcing',
        'Interviewing & Engaging',
        'Onboarding & Advancing'
      ]}
      clearFilter={() => setFilteredCategories([])}
    />
  )
}
export default Filters
