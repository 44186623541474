/* eslint-disable @typescript-eslint/no-shadow */
import { useQuery, useMutation } from '@apollo/client'
import { CREATE_EMPLOYER_ASSESSMENT } from 'hooks/useDashboard/gql'
import { EmployerAssessment } from 'context/Assessment/gql'
import { GET_ASSESSMENT, SUBMIT_ASSESSMENT } from './gql'
import { INITIAL_ASSESSMENT } from './constants'

const useAssessmentOverview = () => {
  const { data: { assessment: blankStateAssessment } = INITIAL_ASSESSMENT } =
    useQuery(GET_ASSESSMENT)

  const [submitAssessment] = useMutation(SUBMIT_ASSESSMENT)
  const [createEmployerAssessment] = useMutation(CREATE_EMPLOYER_ASSESSMENT, {
    update(cache, { data: { createEmployerAssessment } }) {
      cache.modify({
        fields: {
          employerAssessment() {
            const employerAssessmentRef = cache.writeFragment({
              data: createEmployerAssessment,
              fragment: EmployerAssessment
            })

            return employerAssessmentRef
          }
        }
      })
    }
  })

  return {
    blankStateAssessment,
    submitAssessment,
    createEmployerAssessment
  }
}

export default useAssessmentOverview
