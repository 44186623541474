import { useQuery } from '@apollo/client'

import { GET_KEY_STRENGTHS } from './gql'

const useDashboard = () => {
  const { data } = useQuery(GET_KEY_STRENGTHS)
  const employerAssessmentKeyStrengths = data?.employerAssessmentKeyStrengths

  return { employerAssessmentKeyStrengths }
}

export default useDashboard
