import React, { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import {
  Typography,
  Grid,
  Box,
  BoxProps,
  IconButton,
  IconButtonProps
} from '@mui/material'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { styled } from '@mui/material/styles'
import { ArrowRightIcon } from 'components'
import { Color } from 'components/SharedStyles'
import { Question } from 'model/assessment'
import { CategoryContext } from 'context/Category'

const QuestionItemContainer = styled(Box)<BoxProps>(() => ({
  minHeight: '68px',
  boxSizing: 'border-box',
  padding: '16px 30px',
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  borderTop: `1px solid ${Color.border}`
}))

const CircleIconContainer = styled(Box)<BoxProps>(() => ({
  borderRadius: '50%',
  backgroundColor: '#32A763',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
}))

const CircleIconButton = styled(IconButton)<IconButtonProps>(() => ({
  backgroundColor: Color.grey200,
  width: '24px',
  height: '24px',
  padding: '6px'
}))

interface IProps {
  question: Question
}

const CategoryQuestionListItem = ({ question }: IProps) => {
  const navigate = useNavigate()
  const questionHasAnswer: boolean | undefined = question.isCompleted
  const handleClick = () => {
    navigate(`/equity-index/assessment/${slug}/${question.order}`)
  }
  const { slug } = useContext(CategoryContext)
  return (
    <QuestionItemContainer>
      <Box sx={{ minWidth: '130px' }}>
        {questionHasAnswer ? (
          <CircleIconContainer
            sx={{ marginLeft: '3px', width: '24px', height: '24px' }}
          >
            <CheckCircleIcon sx={{ fontSize: 29, color: '#F2F3F3' }} />
          </CircleIconContainer>
        ) : (
          <CheckCircleIcon sx={{ fontSize: 29, color: '#F2F3F3' }} />
        )}
      </Box>
      <Grid item display='flex' xs={10}>
        <Typography variant='body2' fontWeight={700} marginRight={'8px'}>
          {`0${question.order}`.slice(-2)}
        </Typography>
        <Typography variant='body2' sx={{ maxWidth: '70%' }}>
          {question.questionText}
        </Typography>
      </Grid>
      <CircleIconButton onClick={handleClick}>
        <ArrowRightIcon color='secondary' />
      </CircleIconButton>
    </QuestionItemContainer>
  )
}

export default CategoryQuestionListItem
