/* TODO: These models are created based on the mock server, modify these when we have the real API. */

// Data model for Dashboard and Assessment pages

export type CarouselSlide = {
  title: string
  content: string
  image?: string
  linkUrl?: string
}

export type CategoryScoreType = {
  name: string
  score: number
}

export type AllAssessments = {
  allAssessments: Assessment[] | EmployerAssessment[]
}
export type AssessmentData = {
  assessment: Assessment
}
export type Assessment = {
  id: string
  acquiredScore?: number
  totalScore?: number
  categories?: Category[]
  employerAssessmentCategories?: Category[]
}

export type EmployerAssessmentData = {
  employerAssessment: EmployerAssessment
}

export type AssessmentQuarter = {
  quarter: number
  year: number
  assessmentCompleted?: boolean | undefined
}

export type CurrentEpAssessment = {
  quarter: number
  year: number
  quarterAssessment: EmployerQuarterHistory | null
}

// Using numbers for keys (signifying the position in the graph) to map the quarter to the position in getLastFourQuarters method
export type CurrentEpAssessments = {
  1: CurrentEpAssessment | null
  2: CurrentEpAssessment | null
  3: CurrentEpAssessment | null
  4: CurrentEpAssessment | null
}

export interface EmployerAssessmentHistory {
  firstQuarter: EmployerQuarterHistory | null
  secondQuarter: EmployerQuarterHistory | null
  thirdQuarter: EmployerQuarterHistory | null
  fourthQuarter: EmployerQuarterHistory | null
}

export interface EpLatestAssessments extends EmployerAssessmentHistory {
  latestAssessment: EmployerQuarterHistory | null
}

export type EmployerQuarterHistory = {
  __typename?: string
  id: string
  acquiredScore: number
  endAt?: string
  employerAssessmentCategories: Category[]
}

export type EmployerAssessment = {
  id: string
  employerId?: string
  totalScore: number
  acquiredScore: number
  progress: number
  status: string
  employerAssessmentCategories: Category[]
}

export type Category = {
  id: string
  quarter?: number
  year?: number
  categoryId?: string
  name: string
  description?: string
  totalScore?: number
  acquiredScore?: number
  progress?: number
  slug: string
  questions?: Question[]
  isCompleted?: boolean
  employerAssessmentQuestions?: Question[]
  __typename?: string
}

export type AllQuestions = {
  allQuestions: Question[]
}
export type Question = {
  id: string
  assessmentQuestionId?: string
  employerFollowupQuestions: Question[]
  questionText: string
  description: string
  questionType: QuestionType | string
  order: number
  subOrder?: number | null
  isCompleted?: boolean
  selectedAnswerIds: string[]
  assessmentAnswers: Answer[]

  __typename?: string
}

export type Answer = {
  id: string
  answerText: string
  order: string
  hasFollowupQuestion: boolean
  followupQuestionIds: string[] | null
  hasWeight?: boolean
}

export enum QuestionType {
  SINGLE_SELECT = 'single_select',
  MULTI_SELECT = 'multi_select'
}

export enum CategoryStatus {
  STARTED,
  NOT_STARTED,
  COMPLETE
}

export type UpdateQuestionInput = {
  question: Question
  selectedAnswerIds: string[]
}

export type QuestionState = {
  questionsToSubmit: UpdateQuestionInput[]
  parentQuestionSelectedIds: string[]
  parentQuestionHasFollowup: boolean
}

export type CategoryAverage = {
  'attracting-sourcing': number
  'defining-tracking': number
  'interviewing-engaging': number
  'onboarding-advancing': number
}

export type BenchmarkSummary = {
  avg: number
  category_avg: CategoryAverage
  ep_count: number
}

export type Benchmark = {
  id: string
  benchmarkSummary: BenchmarkSummary
  endDate: string
  benchmarkYear: string
  benchmarkType: string
  quarter: number
}

export type AssessmentBenchmarks = {
  employerAssessmentBenchmark: Benchmark
}

export type BenchmarkCategory = {
  category: string
  benchmarkScore: number | undefined
  currentScore: number
  previousScore: number | undefined
  delta: number
  previousQuarter: string
  type: 'strength' | 'opportunity'
}

export type QuarterScore = {
  id?: string | undefined
  acquiredScore?: number | undefined
  endAt?: string | undefined
  employerAssessmentCategories?: Category[] | undefined
  quarter: number | undefined
}
