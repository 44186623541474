import { Typography, Box, Grid } from '@mui/material'
import { theme } from 'components/SharedStyles'
import React from 'react'

const CategoryHeaders = ({ categories }: any) => {
  return (
    <Grid container sx={{ paddingBottom: '40px' }}>
      {categories.map(
        (
          category: { number: React.Key | null | undefined; label: any },
          idx: any
        ) => (
          <Grid
            item
            key={category.number}
            sx={{
              borderRight: idx !== 3 ? '2px solid #E5E5E5' : 'none',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              width: '25%',
              [theme.breakpoints.down(1300)]: {
                width: '50%',
                borderRight: idx % 2 === 0 ? '2px solid #E5E5E5' : 'none',
                paddingBottom: idx < 2 ? '50px' : 0,
                paddingLeft: '55px'
              },
              [theme.breakpoints.down(1054)]: {
                width: '50%',
                borderRight:
                  idx % 2 === 0 ? '2px solid #E5E5E5 !important' : 'none',
                paddingBottom: idx < 2 ? '50px' : 0,
                paddingRight: '15px',
                letterSpacing: 'none'
              }
            }}
          >
            <Box
              key={category.number}
              display='flex'
              justifyContent='center'
              alignItems='center'
            >
              <Typography
                sx={{
                  fontWeight: 700,
                  letterSpacing: '2.04px',
                  textTransform: 'uppercase',
                  fontSize: '12px',
                  lineHeight: '16px',
                  paddingLeft: '15px'
                }}
              >{`${category.label}`}</Typography>
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: '24px',
                  letterSpacing: '-0.48%',
                  paddingLeft: '16px'
                }}
              >
                {category.number}
              </Typography>
            </Box>
          </Grid>
        )
      )}
    </Grid>
  )
}

export default CategoryHeaders
