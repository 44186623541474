import React from 'react'
import { Box, Button, ButtonProps, styled, Typography } from '@mui/material'
import { Color } from 'components'
import StarOutlineIcon from '@mui/icons-material/StarOutline'
import EastIcon from '@mui/icons-material/East'

interface StyledButtonProps extends ButtonProps {
  status: string
}

const StyledButton = styled(Button, {
  shouldForwardProp: prop => prop !== 'status'
})<StyledButtonProps>(({ status }) => ({
  borderRadius: '8px',
  background: status && status === 'recommended' ? '#F2F3F3' : 'transparent',
  padding: status && status === 'recommended' ? '10px 20px' : '0 0 10px 0',
  '&:hover': {
    background: status && status === 'recommended' ? Color.white : 'transparent'
  }
}))

interface IProps {
  status: string
  onClick: () => void
}

const KrButton = ({ status, onClick }: IProps) => {
  return (
    <StyledButton
      status={status}
      onClick={onClick}
      endIcon={
        status === 'recommended' ? (
          <StarOutlineIcon />
        ) : (
          <EastIcon sx={{ color: Color.white }} />
        )
      }
    >
      <Box
        sx={{
          borderBottom: status === 'recommended' ? 'none' : '1px solid white',
          paddingBottom: status === 'recommended' ? 0 : '5px'
        }}
      >
        <Typography
          variant='h4'
          sx={{
            fontSize: '14px',
            color: status === 'recommended' ? '#050B0E' : Color.white,
            textTransform: 'uppercase',
            lineHeight: '16px',
            letterSpacing: '2.04px'
          }}
        >
          {status === 'recommended'
            ? 'Save to Action Plan'
            : 'View in Action Plan'}
        </Typography>
      </Box>
    </StyledButton>
  )
}
export default KrButton
