import React, { useContext } from 'react'
import { Box, BoxProps } from '@mui/material'
import { Color } from 'components/SharedStyles'
import { CATEGORY_COLOR_1_BKGD, CATEGORY_BAR_GRADIENT } from 'constants/'
import { styled } from '@mui/material/styles'
import { CategoryContext } from 'context/Category'
import { AssessmentOverviewContext } from 'context/AssessmentOverview'

interface ProgressBarProps extends BoxProps {
  categoryCompleted?: boolean
  hasActiveAssessment?: boolean
  name: string
  progress?: number
}

const ProgressBarBackground = styled(Box, {
  shouldForwardProp: prop =>
    prop !== 'categoryCompleted' &&
    prop !== 'hasActiveAssessment' &&
    prop !== 'category' &&
    prop !== 'progress'
})<ProgressBarProps>(({ hasActiveAssessment, name }) => ({
  border: 'none',
  margin: 'auto',
  boxSizing: 'border-box',
  borderRadius: '100px',
  width: 261,
  height: 24,
  backgroundColor: hasActiveAssessment
    ? CATEGORY_COLOR_1_BKGD[name]
    : Color.grey50
}))

const ProgressBarForeground = styled(Box, {
  shouldForwardProp: prop =>
    prop !== 'hasActiveAssessment' && prop !== 'category' && prop !== 'progress'
})<ProgressBarProps>(({ hasActiveAssessment, name, progress }) => ({
  borderRadius: progress && progress < 95 ? '100px 0 0 100px' : '100px',
  width: progress ? `${progress}%` : '0%',
  height: '100%',
  background: hasActiveAssessment
    ? CATEGORY_BAR_GRADIENT[name]
    : progress
    ? Color.black
    : Color.grey50
}))

const CategoryProgressBar = () => {
  const { name, progress, isCompleted } = useContext(CategoryContext)
  const { hasActiveAssessment } = useContext(AssessmentOverviewContext)
  return (
    <Box>
      <ProgressBarBackground
        categoryCompleted={isCompleted}
        hasActiveAssessment={hasActiveAssessment}
        name={name}
        progress={progress}
      >
        <ProgressBarForeground
          hasActiveAssessment={hasActiveAssessment}
          name={name}
          progress={progress}
        />
      </ProgressBarBackground>
    </Box>
  )
}

export default CategoryProgressBar
