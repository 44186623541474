import { EpLatestAssessments } from 'model/assessment'

export const INITIAL_EMPLOYER_ASSESSMENT_HISTORY: EpLatestAssessments = {
  latestAssessment: {
    id: '',
    acquiredScore: 0,
    employerAssessmentCategories: [
      {
        id: '',
        name: 'Defining and Tracking',
        slug: 'defining-tracking',
        acquiredScore: 0
      },
      {
        id: '',
        name: 'Attracting and Sourcing',
        slug: 'attracting-sourcing',
        acquiredScore: 0
      },
      {
        id: '',
        name: 'Interviewing and Engaging',
        slug: 'interviewing-engaging',
        acquiredScore: 0
      },
      {
        id: '',
        name: 'Onboarding and Advancing',
        slug: 'onboarding-advancing',
        acquiredScore: 0
      }
    ]
  },
  firstQuarter: {
    id: '',
    acquiredScore: 0,
    employerAssessmentCategories: [
      {
        id: '',
        name: 'Defining and Tracking',
        slug: 'defining-tracking',
        acquiredScore: 0
      },
      {
        id: '',
        name: 'Attracting and Sourcing',
        slug: 'attracting-sourcing',
        acquiredScore: 0
      },
      {
        id: '',
        name: 'Interviewing and Engaging',
        slug: 'interviewing-engaging',
        acquiredScore: 0
      },
      {
        id: '',
        name: 'Onboarding and Advancing',
        slug: 'onboarding-advancing',
        acquiredScore: 0
      }
    ]
  },
  secondQuarter: {
    id: '',
    acquiredScore: 0,
    employerAssessmentCategories: [
      {
        id: '',
        name: 'Defining and Tracking',
        slug: 'defining-tracking',
        acquiredScore: 0
      },
      {
        id: '',
        name: 'Attracting and Sourcing',
        slug: 'attracting-sourcing',
        acquiredScore: 0
      },
      {
        id: '',
        name: 'Interviewing and Engaging',
        slug: 'interviewing-engaging',
        acquiredScore: 0
      },
      {
        id: '',
        name: 'Onboarding and Advancing',
        slug: 'onboarding-advancing',
        acquiredScore: 0
      }
    ]
  },
  thirdQuarter: {
    id: '',
    acquiredScore: 0,
    employerAssessmentCategories: [
      {
        id: '',
        name: 'Defining and Tracking',
        slug: 'defining-tracking',
        acquiredScore: 0
      },
      {
        id: '',
        name: 'Attracting and Sourcing',
        slug: 'attracting-sourcing',
        acquiredScore: 0
      },
      {
        id: '',
        name: 'Interviewing and Engaging',
        slug: 'interviewing-engaging',
        acquiredScore: 0
      },
      {
        id: '',
        name: 'Onboarding and Advancing',
        slug: 'onboarding-advancing',
        acquiredScore: 0
      }
    ]
  },
  fourthQuarter: {
    id: '',
    acquiredScore: 0,
    employerAssessmentCategories: [
      {
        id: '',
        name: 'Defining and Tracking',
        slug: 'defining-tracking',
        acquiredScore: 0
      },
      {
        id: '',
        name: 'Attracting and Sourcing',
        slug: 'attracting-sourcing',
        acquiredScore: 0
      },
      {
        id: '',
        name: 'Interviewing and Engaging',
        slug: 'interviewing-engaging',
        acquiredScore: 0
      },
      {
        id: '',
        name: 'Onboarding and Advancing',
        slug: 'onboarding-advancing',
        acquiredScore: 0
      }
    ]
  }
}
