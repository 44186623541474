import React from 'react'
import { theme, Color } from 'components/SharedStyles'
import { Box, Typography, Paper, PaperProps } from '@mui/material'
import { styled } from '@mui/material/styles'
import { EIStage } from 'components'
import DevelopmentStageChart from './DevelopmentStageChart'

interface IProps {
  acquiredScore: number | undefined
  noAssessmentsCompleted: boolean
}
interface DevelopmentContainerProps extends PaperProps {
  noAssessmentsCompleted: boolean
}

const DevelopmentStageContainer = styled(Paper, {
  shouldForwardProp: prop => prop !== 'noAssessmentsCompleted'
})<DevelopmentContainerProps>(({ noAssessmentsCompleted }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  minHeight: noAssessmentsCompleted ? '330px' : '384px',
  border: '1px solid #E5E5E5',
  borderRadius: '8px',
  padding: '40px 40px 50px 40px',
  [theme.breakpoints.down('md')]: {
    padding: '40px 20px 50px 20px'
  },
  [theme.breakpoints.down(1000)]: {
    padding: '40px 40px 65px 40px'
  }
}))

const DevelopmentStage = ({
  acquiredScore,
  noAssessmentsCompleted
}: IProps) => {
  return (
    <DevelopmentStageContainer
      noAssessmentsCompleted={noAssessmentsCompleted}
      variant='outlined'
    >
      <Box>
        <Typography
          variant='h6'
          sx={{
            fontSize: '16px',
            color: noAssessmentsCompleted ? '#ACADAF' : Color.black,
            letterSpacing: '0.14em'
          }}
        >
          DEVELOPMENT STAGE
        </Typography>
        <Box sx={{ display: 'flex' }}>
          <Typography
            variant='h6'
            sx={{
              fontSize: '16px',
              color: noAssessmentsCompleted ? '#ACADAF' : Color.black
            }}
          >
            Stage:
          </Typography>
          <EIStage acquiredScore={acquiredScore} />
        </Box>
      </Box>
      <DevelopmentStageChart
        acquiredScore={acquiredScore}
        noAssessmentsCompleted={noAssessmentsCompleted}
      />
    </DevelopmentStageContainer>
  )
}

export default DevelopmentStage
