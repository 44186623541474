/* eslint-disable no-console */
import { useLazyQuery } from '@apollo/client'
import moment from 'moment'
import { useState } from 'react'

import {
  GET_EMPLOYER_ASSESSMENT_HISTORY,
  CHECK_HAS_COMPLETED_ANY_ASSESSMENT
} from './gql'

const useEmployerAssessmentHistory = () => {
  const [isInitialized, setIsInitialized] = useState(false)
  const currentYear: number = moment().utc().year()
  // TODO: use current quarter to limit assessment queries (won't need to query previous year if we are in quarter 4)
  // Get previous year's employer assessment history
  const [onGetEmployerAssessmentHistory, { data: employerAssessmentHistory }] =
    useLazyQuery(GET_EMPLOYER_ASSESSMENT_HISTORY, {
      fetchPolicy: 'cache-and-network',
      variables: {
        year: (currentYear - 1).toString()
      }
    })
  // Get current year's employer assessment history
  const [
    onGetCurrentEmployerAssessmentHistory,
    { data: currentEmployerAssessmentHistory }
  ] = useLazyQuery(GET_EMPLOYER_ASSESSMENT_HISTORY, {
    fetchPolicy: 'cache-and-network',
    variables: {
      year: currentYear.toString()
    }
  })

  const [onGetLatestAssessmentHistory, { data: latestAssessmentHistory }] =
    useLazyQuery(GET_EMPLOYER_ASSESSMENT_HISTORY, {
      fetchPolicy: 'cache-and-network',
      variables: {}
    })

  const getEmployerAssessmentHistory = async () => {
    await onGetEmployerAssessmentHistory()
    await onGetCurrentEmployerAssessmentHistory()
    await onGetLatestAssessmentHistory()
    setIsInitialized(true)
  }

  const [
    onCheckHasCompletedAnyAssessment,
    { data: partialEmployerAssessmentHistory }
  ] = useLazyQuery(CHECK_HAS_COMPLETED_ANY_ASSESSMENT)
  const checkHasCompletedAnyAssessment = async () => {
    await onCheckHasCompletedAnyAssessment()
    setIsInitialized(true)
  }

  // Checking specific keys since __typename is included in the response
  const hasCompletedAnAssessmentCheckedInFullHistory =
    (employerAssessmentHistory &&
      employerAssessmentHistory.employerAssessmentHistory.latestAssessment !==
        null) ||
    (currentEmployerAssessmentHistory &&
      currentEmployerAssessmentHistory.employerAssessmentHistory
        .latestAssessment !== null) ||
    (latestAssessmentHistory &&
      latestAssessmentHistory.employerAssessmentHistory.latestAssessment !==
        null)

  // Checking specific keys since __typename is included in the response
  const hasCompletedAnAssessmentCheckedInPartialHistory =
    partialEmployerAssessmentHistory &&
    partialEmployerAssessmentHistory.employerAssessmentHistory
      .latestAssessment !== null

  const hasCompletedAnAssessment = !!(
    hasCompletedAnAssessmentCheckedInFullHistory ||
    hasCompletedAnAssessmentCheckedInPartialHistory
  )

  const currentAssessment: any =
    currentEmployerAssessmentHistory ||
    employerAssessmentHistory ||
    latestAssessmentHistory

  const latestAssessment: any =
    currentAssessment?.employerAssessmentHistory?.latestAssessment

  return {
    getEmployerAssessmentHistory,
    hasCompletedAnAssessment,
    checkHasCompletedAnyAssessment,
    employerAssessmentHistoryData: employerAssessmentHistory,
    latestAssessmentData: latestAssessment,
    currentEmployerAssessmentHistoryData: currentAssessment,
    loading: !isInitialized
  }
}

export default useEmployerAssessmentHistory
