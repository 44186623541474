import React from 'react'
import { useSpring, animated, easings } from 'react-spring'
import { Box, Grid, Typography } from '@mui/material'
import {
  ArrowDropDownRounded as ArrowDropDownIcon,
  ArrowDropUpRounded as ArrowDropUpIcon
} from '@mui/icons-material'

interface IProps {
  delta: number
  currentScore: number
  previousScore: number | undefined
}

const DeltaArrowComponent = ({
  delta,
  currentScore,
  previousScore
}: IProps) => {
  const previousScoreNumber: number = previousScore || 0
  const { scoreIncrease } = useSpring({
    from: { scoreIncrease: 0 },
    scoreIncrease:
      previousScoreNumber && currentScore
        ? (delta / previousScoreNumber) * 100
        : 0,
    config: { duration: 2000, easing: easings.easeInOutQuart }
  })
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        padding: '0 10px'
      }}
    >
      <Grid container display='flex' width='initial'>
        <Grid
          item
          xs={10}
          display='flex'
          justifyContent='flex-end'
          alignItems='center'
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              padding: '5px 5px 5px 10px',
              backgroundColor: delta > 0 ? '#EBF6EF' : '#FFECED',
              borderBottomLeftRadius: '8px',
              borderTopLeftRadius: '8px'
            }}
          >
            <animated.h3
              style={{
                fontWeight: 700,
                fontSize: '12px',
                lineHeight: '20px',
                letterSpacing: '0.17em',
                color: '#050B0E',
                margin: 0
              }}
            >
              {scoreIncrease.to((scoreIncreasePosition: number) =>
                scoreIncreasePosition.toFixed(0)
              )}
            </animated.h3>
            <Box sx={{ display: 'inline-block' }}>
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: '12px',
                  lineHeight: '12px',
                  letterSpacing: '0.17em',
                  color: '#050B0E',
                  margin: 0
                }}
              >
                %
              </Typography>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={2}
          display='flex'
          justifyContent='center'
          alignItems='center'
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              backgroundColor: delta > 0 ? '#EBF6EF' : '#FFECED',
              height: '100%',
              borderBottomRightRadius: '8px',
              borderTopRightRadius: '8px'
            }}
          >
            {delta > 0 ? (
              <ArrowDropUpIcon
                style={{
                  color: '#32A763'
                }}
              />
            ) : (
              <ArrowDropDownIcon
                style={{
                  color: '#FD404C'
                }}
              />
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}
export default DeltaArrowComponent
