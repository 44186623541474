import React from 'react'
import { Box, Grid, GridProps, BoxProps } from '@mui/material'
import { theme } from 'components/SharedStyles'
import { styled } from '@mui/material/styles'
import { DevelopmentStageBar } from 'components'

interface IProps {
  acquiredScore: number | undefined
  noAssessmentsCompleted: boolean
}

interface ChartContainerProps extends BoxProps {
  noAssessmentsCompleted: boolean
}

const ChartContainer = styled(Box, {
  shouldForwardProp: prop => prop !== 'noAssessmentsCompleted'
})<ChartContainerProps>(({ noAssessmentsCompleted }) => ({
  height: 'inherit',
  width: '100%',
  margin: noAssessmentsCompleted ? '40px 0 0px' : '75px 0 0',
  [theme.breakpoints.up('sm')]: {
    minHeight: '235px'
  },
  [theme.breakpoints.up('md')]: {
    minHeight: '205px'
  },
  [theme.breakpoints.up('lg')]: {
    minHeight: '175px'
  },
  [theme.breakpoints.up('xl')]: {
    minHeight: '159px'
  }
}))

interface ScoreBarContainerProps extends GridProps {
  noAssessmentsCompleted: boolean
}

const ScoreBarContainer = styled(Grid, {
  shouldForwardProp: prop => prop !== 'noAssessmentsCompleted'
})<ScoreBarContainerProps>(({ noAssessmentsCompleted }) => ({
  width: '100%',
  height: '53px',
  backgroundColor: noAssessmentsCompleted ? '#F7F7F7' : '#F1FAFF',
  position: 'relative'
}))

const DevelopmentStageChart = ({
  acquiredScore,
  noAssessmentsCompleted
}: IProps) => {
  return (
    <ChartContainer noAssessmentsCompleted={noAssessmentsCompleted}>
      <ScoreBarContainer
        container
        noAssessmentsCompleted={noAssessmentsCompleted}
      >
        <DevelopmentStageBar
          acquiredScore={acquiredScore}
          noAssessmentsCompleted={noAssessmentsCompleted}
        />
      </ScoreBarContainer>
    </ChartContainer>
  )
}

export default DevelopmentStageChart
