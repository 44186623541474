import React from 'react'
import { styled } from '@mui/material/styles'
import Chip, { ChipProps } from '@mui/material/Chip'
import { Color } from 'components/SharedStyles'
import { Typography } from '@mui/material'

interface Props {
  disabled?: boolean
}
const GoalTag = styled(({ label, ...props }: Props & ChipProps) => (
  <Chip
    {...props}
    label={<Typography variant='subtitle2'>{label}</Typography>}
  />
))(({ label }: ChipProps) => {
  const baseStyle = {
    width: 'fit-content',
    display: 'flex',
    justifyContent: 'center',
    height: 'auto',
    borderRadius: '8px',
    backgroundColor: Color.grey50,
    borderColor: Color.grey300,
    maxHeight: '24px',
    padding: '0 12px',
    '& .MuiChip-label': {
      padding: '2px 0 1px 1px'
    }
  }

  switch (label) {
    case 'DEFINING & TRACKING':
    case 'D&T':
      return {
        ...baseStyle,
        backgroundColor: Color.raspberry100,
        borderColor: Color.raspberry400,
        padding: '0 8px'
      }
    case 'ATTRACTING & SOURCING':
    case 'A&S':
      return {
        ...baseStyle,
        backgroundColor: Color.eggplant100,
        borderColor: Color.eggplant400,
        padding: '0 8px'
      }
    case 'ONBOARDING & ADVANCING':
    case 'O&A':
      return {
        ...baseStyle,
        backgroundColor: Color.iris100,
        borderColor: Color.iris400,
        padding: '0 8px'
      }
    case 'INTERVIEWING & ENGAGING':
    case 'I&E':
      return {
        ...baseStyle,
        backgroundColor: Color.turquoise100,
        borderColor: Color.turquoise400,
        padding: '0 8px'
      }
    case 'CURRENT':
      return {
        ...baseStyle,
        backgroundColor: Color.grey50,
        borderColor: Color.grey200,
        padding: '0 12px'
      }
    default:
      return baseStyle
  }
})

export default GoalTag
