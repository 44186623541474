import { Question } from 'model/assessment'
import React, { createContext } from 'react'

type CategoryContextData = {
  id: string
  categoryId?: string
  name: string
  description?: string
  totalScore?: number
  acquiredScore?: number
  progress?: number
  slug: string
  Questions?: Question[]
  isCompleted?: boolean
  employerAssessmentQuestions?: Question[]
}

export const CategoryContext = createContext<CategoryContextData>({
  id: '',
  name: '',
  slug: ''
})

const CategoryProvider = ({ children, category }: any): JSX.Element => {
  const {
    acquiredScore,
    categoryId,
    description,
    employerAssessmentQuestions,
    id,
    isCompleted,
    name,
    progress,
    slug,
    totalScore
  } = category
  if (!category.id) return <></>
  return (
    <CategoryContext.Provider
      value={{
        acquiredScore,
        categoryId,
        description,
        employerAssessmentQuestions,
        id,
        isCompleted,
        name,
        progress,
        slug,
        totalScore
      }}
    >
      {children}
    </CategoryContext.Provider>
  )
}

export default CategoryProvider
