import React from 'react'
import { Grid, Paper, PaperProps } from '@mui/material'
import { styled } from '@mui/material/styles'
import { theme } from 'components/SharedStyles'
import BreadcrumbHeader from './BreadcrumbHeader'
import QuestionDetails from './QuestionDetails'
import QuestionNavigation from './QuestionNavigation'

const QuestionPageContainer = styled(Paper)<PaperProps>(() => ({
  padding: '64px 104px',
  [theme.breakpoints.up('sm')]: {
    minHeight: '745px'
  },
  [theme.breakpoints.up('md')]: {
    minHeight: '720px'
  },
  [theme.breakpoints.up('lg')]: {
    minHeight: '685px'
  },
  [theme.breakpoints.up('xl')]: {
    minHeight: '685px'
  }
}))

const AssessmentQuestion = () => {
  return (
    <Grid
      container
      flexDirection='column'
      flexWrap='nowrap'
      margin='auto'
      maxWidth='1230px'
    >
      <BreadcrumbHeader />

      <QuestionPageContainer variant='outlined'>
        <QuestionDetails />
        <Grid
          item
          xs={12}
          sx={{ borderTop: '1px solid #000000', margin: '45px 0 16px 0' }}
        />
        <QuestionNavigation />
      </QuestionPageContainer>
    </Grid>
  )
}

export default AssessmentQuestion
