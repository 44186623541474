import { useQuery } from '@apollo/client'
import { GET_EMPLOYER_ASSESSMENT } from 'context/Assessment/gql'
import { INITIAL_EMPLOYER_ASSESSMENT } from 'context/Assessment/constants'
import useAssessmentOverview from 'hooks/useAssessmentOverview'
import { useEffect } from 'react'
import { fillEmployerAssessment } from './utils'

const useEmployerAssessment = () => {
  const {
    data: { employerAssessment } = INITIAL_EMPLOYER_ASSESSMENT,
    updateQuery,
    refetch
  } = useQuery(GET_EMPLOYER_ASSESSMENT, {
    fetchPolicy: 'cache-first'
  })

  const { blankStateAssessment } = useAssessmentOverview()

  useEffect(() => {
    if (blankStateAssessment.id) {
      if (employerAssessment === null) {
        fillEmployerAssessment(updateQuery, blankStateAssessment)
      }
    }
  }, [blankStateAssessment, employerAssessment, updateQuery])

  return {
    employerAssessment: employerAssessment ?? INITIAL_EMPLOYER_ASSESSMENT,
    updateEmployerAssessment: updateQuery,
    refetchEmployerAssessment: refetch
  }
}

export default useEmployerAssessment
