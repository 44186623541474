import React, { createContext, useMemo } from 'react'
import useBenchmark from 'hooks/useBenchmark'
import { Benchmark } from 'model/assessment'

type BenchmarkContextType = {
  benchmark: Benchmark | undefined
  benchmarksAreLoading: boolean
}

export const BenchmarkContext = createContext<BenchmarkContextType>({
  benchmark: undefined,
  benchmarksAreLoading: true
})

const BenchmarkProvider = ({ children }: any): JSX.Element => {
  const { assessmentBenchmarks, benchmarksAreLoading } = useBenchmark()

  const benchmark: Benchmark | undefined =
    assessmentBenchmarks?.employerAssessmentBenchmark

  const value = useMemo(
    () => ({ benchmark, benchmarksAreLoading }),
    [benchmark, benchmarksAreLoading]
  )

  return (
    <BenchmarkContext.Provider value={value}>
      {children}
    </BenchmarkContext.Provider>
  )
}

export default BenchmarkProvider
