import { AssessmentData } from 'model/assessment'

export const INITIAL_ASSESSMENT: AssessmentData = {
  assessment: {
    id: '',
    totalScore: 0,

    categories: [
      {
        id: '',
        name: '',
        slug: '',
        description: '',
        totalScore: 0
      },
      {
        id: '',
        name: '',
        slug: '',
        description: '',
        totalScore: 0
      },
      {
        id: '',
        name: '',
        slug: '',
        description: '',
        totalScore: 0
      },
      {
        id: '',
        name: '',
        slug: '',
        description: '',
        totalScore: 0
      }
    ]
  }
}
