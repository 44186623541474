import React, { useContext, useEffect } from 'react'
import { Navigate, Route, Routes, useLocation } from 'react-router-dom'
import { Grid } from '@mui/material'
import Dashboard from 'pages/Dashboard'
import Assessment from 'pages/Assessment'
import { DashboardProvider } from 'context/Dashboard'
import { MicroServiceContext } from 'context/MicroService'
import BenchmarkProvider from 'context/Benchmark'
import AssessmentHistory from 'pages/EIHistory'
import { LicenseInfo } from '@mui/x-data-grid-pro'
import { Recommend } from '@mui/icons-material'
import { RecommendationsProvider } from 'context/Recommendation'

const dataGridProLicenseKey: string =
  process.env.REACT_APP_MUI_DATA_GRID_PRO || ''

LicenseInfo.setLicenseKey(dataGridProLicenseKey)

const RouteProvider = () => {
  const { hasAccessToViewAssessment, hasAccessToViewDashboard } =
    useContext(MicroServiceContext)

  const { pathname } = useLocation()
  useEffect(() => {
    if (pathname === '/equity-index/history') {
      document.title = 'History | Mathison'
    }
    if (pathname === '/equity-index/assessment') {
      document.title = 'Assessment | Mathison'
    } else {
      document.title = 'EI Dashboard | Mathison'
    }
    return () => {
      document.title = 'Mathison'
    }
  }, [pathname])
  return (
    <Grid
      sx={{
        maxWidth: 1460,
        width: '90%',
        margin: 'auto',
        padding: '40px 0',
        position: 'relative',
        display: 'flex',
        justifyContent: 'center'
      }}
    >
      <Grid sx={{ width: '100%' }}>
        <Routes>
          <Route
            path='/equity-index/'
            element={
              hasAccessToViewAssessment && hasAccessToViewDashboard ? (
                <DashboardProvider>
                  <BenchmarkProvider>
                    <RecommendationsProvider>
                      <Dashboard />
                    </RecommendationsProvider>
                  </BenchmarkProvider>
                </DashboardProvider>
              ) : (
                <Navigate to='/action-plan' />
              )
            }
          />
          <Route path='/equity-index/assessment/*' element={<Assessment />} />
          <Route
            path='/equity-index/history/*'
            element={
              hasAccessToViewAssessment && hasAccessToViewDashboard ? (
                <DashboardProvider>
                  <BenchmarkProvider>
                    <AssessmentHistory />
                  </BenchmarkProvider>
                </DashboardProvider>
              ) : (
                <Navigate to='/action-plan' />
              )
            }
          />
        </Routes>
      </Grid>
    </Grid>
  )
}
export default RouteProvider
