import React from 'react'
import { Grid } from '@mui/material'
import { Recommendation } from 'model/recommendation'
import KeyRecommendation from './KeyRecommendation'

interface IProps {
  recommendations: Recommendation[]
}

const KeyRecommendations = ({ recommendations }: IProps) => {
  return (
    <Grid container sx={{ width: '100%', display: 'flex' }}>
      {recommendations.map((recommendation, index) => (
        <KeyRecommendation
          key={recommendation.id}
          employerGoalId={recommendation.employerGoalId}
          index={index}
          category={recommendation.category}
          title={recommendation.title}
          status={recommendation.status}
          numberOfRecommendations={recommendations.length}
        />
      ))}
    </Grid>
  )
}
export default KeyRecommendations
