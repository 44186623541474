/* eslint-disable no-console */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-plusplus */
import {
  Category,
  EmployerAssessmentHistory,
  CurrentEpAssessment,
  CurrentEpAssessments,
  AssessmentQuarter,
  BenchmarkCategory
} from 'model/assessment'
import moment from 'moment'

const getQuarterByMonth = (month: number): number => {
  return Math.ceil(month / 3)
}

enum Quarter {
  'firstQuarter',
  'secondQuarter',
  'thirdQuarter',
  'fourthQuarter'
}

export const getCurrentQuarterAndYear = (): AssessmentQuarter => {
  const month = moment().utc().month() + 1
  const year = moment().utc().year()
  // construct response
  const response: AssessmentQuarter = {
    quarter: getQuarterByMonth(month),
    year
  }
  return response
}

export const getLastFourQuarters = (
  previousYearAssessment: EmployerAssessmentHistory,
  currentYearAssessment: EmployerAssessmentHistory
): CurrentEpAssessments => {
  const recentFourQuarterAssessment: CurrentEpAssessments = {
    1: null,
    2: null,
    3: null,
    4: null
  }
  // set constant values needed for constructing the CurrentEpAssessments
  const currentQuarter: number = getCurrentQuarterAndYear().quarter
  const currentYear: number = getCurrentQuarterAndYear().year
  const previousYear: number = currentYear - 1
  let quarterCounter: number = 4 // total needed to construct object

  // start with this year's assessment and work backwards from the current quarter
  for (let i = 0; i < currentQuarter; i++) {
    const assessment: CurrentEpAssessment = {
      quarter: currentQuarter - i,
      year: currentYear,
      quarterAssessment:
        currentYearAssessment[
          Quarter[currentQuarter - i - 1] as keyof typeof currentYearAssessment
        ]
    }
    recentFourQuarterAssessment[
      quarterCounter as keyof typeof recentFourQuarterAssessment
    ] = assessment
    quarterCounter--
  }

  // finish with last year's assessment and how many quarters are left - starting from the 4th quarter and working backwards
  for (let i = 3; quarterCounter > 0; i--) {
    const assessment: CurrentEpAssessment = {
      quarter: i + 1,
      year: previousYear,
      quarterAssessment:
        previousYearAssessment[
          Quarter[i] as keyof typeof previousYearAssessment
        ]
    }
    recentFourQuarterAssessment[
      quarterCounter as keyof typeof recentFourQuarterAssessment
    ] = assessment
    quarterCounter--
  }

  return recentFourQuarterAssessment
}

export const constructCombinedEmployerAssessmentHistory = (
  recentFourQuarterAssessment: CurrentEpAssessments
): EmployerAssessmentHistory => {
  const employerAssessmentHistory: EmployerAssessmentHistory = {
    firstQuarter: null,
    secondQuarter: null,
    thirdQuarter: null,
    fourthQuarter: null
  }
  // construct new employerAssessmentHistory from recentFourQuarterAssessment
  employerAssessmentHistory.firstQuarter = recentFourQuarterAssessment[1]
    ? recentFourQuarterAssessment[1].quarterAssessment
    : null
  employerAssessmentHistory.secondQuarter = recentFourQuarterAssessment[2]
    ? recentFourQuarterAssessment[2].quarterAssessment
    : null
  employerAssessmentHistory.thirdQuarter = recentFourQuarterAssessment[3]
    ? recentFourQuarterAssessment[3].quarterAssessment
    : null
  employerAssessmentHistory.fourthQuarter = recentFourQuarterAssessment[4]
    ? recentFourQuarterAssessment[4].quarterAssessment
    : null

  return employerAssessmentHistory
}

export const getQuarterGraphLabels = (
  currentEpAssessments: CurrentEpAssessments
): AssessmentQuarter[] => {
  const quarterLabels: AssessmentQuarter[] = []

  // construct quarter label for first position
  const firstPositionLabel: AssessmentQuarter = {
    quarter: currentEpAssessments[1]?.quarter || 0,
    year: currentEpAssessments[1]?.year || 0,
    assessmentCompleted: !!currentEpAssessments[1]?.quarterAssessment
  }
  quarterLabels.push(firstPositionLabel)

  // construct quarter label for second position
  const secondPositionLabel: AssessmentQuarter = {
    quarter: currentEpAssessments[2]?.quarter || 0,
    year: currentEpAssessments[2]?.year || 0,
    assessmentCompleted: !!currentEpAssessments[2]?.quarterAssessment
  }
  quarterLabels.push(secondPositionLabel)

  // construct quarter label for third position
  const thirdPositionLabel: AssessmentQuarter = {
    quarter: currentEpAssessments[3]?.quarter || 0,
    year: currentEpAssessments[3]?.year || 0,
    assessmentCompleted: !!currentEpAssessments[3]?.quarterAssessment
  }
  quarterLabels.push(thirdPositionLabel)

  // construct quarter label for fourth position
  const fourthPositionLabel: AssessmentQuarter = {
    quarter: currentEpAssessments[4]?.quarter || 0,
    year: currentEpAssessments[4]?.year || 0,
    assessmentCompleted: !!currentEpAssessments[4]?.quarterAssessment
  }
  quarterLabels.push(fourthPositionLabel)

  return quarterLabels
}

// Updated to compare most recent assessment scores
export const getPreviousAndCurrentScores = (
  currentEpAsessments: CurrentEpAssessments
) => {
  if (!currentEpAsessments) return []
  const previousAndCurrentScores = []

  // Update to compare the last four quarters
  if (currentEpAsessments[1]?.quarterAssessment !== null)
    previousAndCurrentScores.push({
      quarter: currentEpAsessments[1]?.quarter,
      ...currentEpAsessments[1]?.quarterAssessment
    })
  if (currentEpAsessments[2]?.quarterAssessment !== null)
    previousAndCurrentScores.push({
      quarter: currentEpAsessments[2]?.quarter,
      ...currentEpAsessments[2]?.quarterAssessment
    })
  if (currentEpAsessments[3]?.quarterAssessment !== null)
    previousAndCurrentScores.push({
      quarter: currentEpAsessments[3]?.quarter,
      ...currentEpAsessments[3]?.quarterAssessment
    })
  if (currentEpAsessments[4]?.quarterAssessment !== null)
    previousAndCurrentScores.push({
      quarter: currentEpAsessments[4]?.quarter,
      ...currentEpAsessments[4]?.quarterAssessment
    })

  return previousAndCurrentScores.length > 1
    ? previousAndCurrentScores.slice(-2)
    : [undefined, ...previousAndCurrentScores]
}

export const getUserFirstCompletedQuarter = (
  employerAssessmentHistory: EmployerAssessmentHistory
): number => {
  if (!employerAssessmentHistory) return 0
  const { firstQuarter, secondQuarter, thirdQuarter, fourthQuarter } =
    employerAssessmentHistory
  const allScores = []
  allScores.push(firstQuarter, secondQuarter, thirdQuarter, fourthQuarter)
  const myFirstQuarter: number = allScores.findIndex(
    score =>
      score !== null &&
      score.acquiredScore !== null &&
      score.acquiredScore !== 0
  )
  return myFirstQuarter
}

export const getUserFirstCompletedPosition = (
  currentEpAssessments: CurrentEpAssessments
): number => {
  if (!currentEpAssessments) return 0

  const position: number =
    currentEpAssessments[1]?.quarterAssessment !== null
      ? 1
      : currentEpAssessments[2]?.quarterAssessment !== null
      ? 2
      : currentEpAssessments[3]?.quarterAssessment !== null
      ? 3
      : currentEpAssessments[4]?.quarterAssessment !== null
      ? 4
      : 0

  return position
}

// set category order by slug
const orderedCategories = [
  { slug: 'defining-tracking' },
  { slug: 'attracting-sourcing' },
  { slug: 'interviewing-engaging' },
  { slug: 'onboarding-advancing' }
]

// Used in Progress By Category
export const orderProgressByCategory = (
  categories?: Category[]
): Category[] | undefined => {
  const result: Category[] = []
  if (categories && categories.length > 0) {
    orderedCategories.forEach(orderedCategory => {
      const filteredCategory = categories.filter(
        category => orderedCategory.slug === category.slug
      )
      result.push(filteredCategory[0])
    })
  }
  return result
}

export const getCategoryDescriptionBySlug = (slug: string): string => {
  switch (slug) {
    case 'defining-tracking':
      return 'How your organization defines and communicates DEI goals, and the ways you plan to measure your progress.'
    case 'attracting-sourcing':
      return 'The ways you present your organization to the world and cast a wider net to extend your reach.'
    case 'interviewing-engaging':
      return "How you're creating an unbiased interview process with an inclusive approach to fill each role."
    case 'onboarding-advancing':
      return "The way you're welcoming new hires and helping them grow throughout their careers"
    default:
      return ''
  }
}

// Used in Category Progress By Quarter
export const groupScoresByCategory = (
  employerAssessmentHistory: EmployerAssessmentHistory
): Category[][] => {
  const categoryTemplate: Category[] = [
    {
      __typename: 'EmployerAssessmentSection',
      id: '',
      name: 'Defining and Tracking',
      slug: 'defining-tracking',
      acquiredScore: 0
    },
    {
      __typename: 'EmployerAssessmentSection',
      id: '',
      name: 'Attracting and Sourcing',
      slug: 'attracting-sourcing',
      acquiredScore: 0
    },
    {
      __typename: 'EmployerAssessmentSection',
      id: '',
      name: 'Interviewing and Engaging',
      slug: 'interviewing-engaging',
      acquiredScore: 0
    },
    {
      __typename: 'EmployerAssessmentSection',
      id: '',
      name: 'Onboarding and Advancing',
      slug: 'onboarding-advancing',
      acquiredScore: 0
    }
  ]

  const employerAssessmentCategoriesQ1: Category[] =
    employerAssessmentHistory && employerAssessmentHistory.firstQuarter !== null
      ? employerAssessmentHistory.firstQuarter.employerAssessmentCategories
      : categoryTemplate
  const employerAssessmentCategoriesQ2: Category[] =
    employerAssessmentHistory &&
    employerAssessmentHistory.secondQuarter !== null
      ? employerAssessmentHistory.secondQuarter?.employerAssessmentCategories
      : categoryTemplate
  const employerAssessmentCategoriesQ3: Category[] =
    employerAssessmentHistory && employerAssessmentHistory.thirdQuarter !== null
      ? employerAssessmentHistory.thirdQuarter?.employerAssessmentCategories
      : categoryTemplate
  const employerAssessmentCategoriesQ4: Category[] =
    employerAssessmentHistory &&
    employerAssessmentHistory.fourthQuarter !== null
      ? employerAssessmentHistory.fourthQuarter?.employerAssessmentCategories
      : categoryTemplate

  // Instantiate data template
  const assessmentCategories: Category[][] = [[], [], [], []]

  // Map through the category order and filter by slug to construct the assessmentCategories array
  orderedCategories.forEach((category: any, index: number) => {
    const sortedCategoryQ1 = employerAssessmentCategoriesQ1.filter(
      assessmentCategory => category.slug === assessmentCategory.slug
    )
    const sortedCategoryQ2 = employerAssessmentCategoriesQ2.filter(
      assessmentCategory => category.slug === assessmentCategory.slug
    )
    const sortedCategoryQ3 = employerAssessmentCategoriesQ3.filter(
      assessmentCategory => category.slug === assessmentCategory.slug
    )
    const sortedCategoryQ4 = employerAssessmentCategoriesQ4.filter(
      assessmentCategory => category.slug === assessmentCategory.slug
    )
    assessmentCategories[index].push(
      sortedCategoryQ1[0],
      sortedCategoryQ2[0],
      sortedCategoryQ3[0],
      sortedCategoryQ4[0]
    )
  })

  return assessmentCategories
}

export const dotLocator = [
  [85, 106],
  [78, 124],
  [68, 140],
  [53, 153],
  [37, 162],
  [18, 166],
  [-1, 167],
  [-20, 163],
  [-37, 154],
  [-51, 142],
  [-62, 126],
  [-70, 109],
  [-73, 90],
  [-71, 71],
  [-65, 53],
  [-55, 37],
  [-41, 23],
  [-25, 14],
  [-6, 8],
  [13, 7],
  [31, 11],
  [49, 19],
  [64, 30],
  [75, 45],
  [85, 63]
]

const getTopBenchmarkCategory = (
  categories: any,
  previousQuarterCategories: Category[],
  currentQuarterCategories: Category[],
  benchmarkType: 'strength' | 'opportunity' = 'strength'
): BenchmarkCategory => {
  // Get Top Current Category based on type
  const topCategory: Category =
    benchmarkType === 'strength'
      ? currentQuarterCategories.reduce((a, b) =>
          a &&
          a.acquiredScore &&
          b &&
          b.acquiredScore &&
          a.acquiredScore > b.acquiredScore
            ? a
            : b
        )
      : currentQuarterCategories.reduce((a, b) =>
          a &&
          a.acquiredScore &&
          b &&
          b.acquiredScore &&
          a.acquiredScore < b.acquiredScore
            ? a
            : b
        )

  const categoryName: string = topCategory.slug

  // Get the previous quarter category by category name of topCategory
  const previousCategory: Category | undefined =
    previousQuarterCategories.filter(
      category => category.slug === categoryName
    )[0]

  const previousScore =
    (previousCategory && previousCategory?.acquiredScore) || 0
  const currentScore = topCategory.acquiredScore || 0

  // Calculate delta
  const delta: number = previousScore ? currentScore - previousScore : 0

  // Get the benchmark category score based on topCategory
  const benchmarkScoreDecimal: number | undefined = categories
    ? categories[topCategory.slug]
    : undefined

  const benchmarkScore: number | undefined = benchmarkScoreDecimal
    ? Math.round(benchmarkScoreDecimal)
    : undefined

  const benchmarkCategory: BenchmarkCategory = {
    category: categoryName,
    benchmarkScore,
    currentScore,
    delta,
    previousQuarter: '',
    previousScore,
    type: benchmarkType
  }
  return benchmarkCategory
}

export const getBenchmarkTopOpportunityAndStrength = (
  categories: any,
  previousQuarter: number,
  previousYear: number,
  previousQuarterCategories: Category[],
  currentQuarterCategories: Category[]
): BenchmarkCategory[] => {
  if (currentQuarterCategories.length === 0) {
    return []
  }
  const topStrength: BenchmarkCategory = getTopBenchmarkCategory(
    categories,
    previousQuarterCategories,
    currentQuarterCategories,
    'strength'
  )
  const topOpportunity: BenchmarkCategory = getTopBenchmarkCategory(
    categories,
    previousQuarterCategories,
    currentQuarterCategories,
    'opportunity'
  )
  const previousQuarterInput: string = `Q${previousQuarter} — ${previousYear}`
  topStrength.previousQuarter = previousQuarterInput
  topOpportunity.previousQuarter = previousQuarterInput
  return [topStrength, topOpportunity]
}
