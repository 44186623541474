/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-constructed-context-values */
import React, { createContext, useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Answer, Question, Category } from 'model/assessment'
import { MicroServiceContext } from 'context/MicroService'
import useAssessment from 'hooks/useAssessment'
import { INITIAL_QUESTION } from 'pages/Assessment/AssessmentQuestion/constant'
import useEmployerAssessment from 'hooks/useEmployerAssessment'

type AssessmentContextData = {
  submitAnswers: (callback?: () => void) => void
  followupIds: string[]
  lastFollowupAnswers: Answer[] | undefined
  parentQuestion: Question
  currentCategory: Category | undefined
  categoryQuestions: Question[]
  questionOrder: string | undefined
  activeQuestion?: Question
  clearQuestionSelections: Function
  onLoadFollowupQuestions: Function
  onUpdateAnswer: Function
  activeSelectedAnswerIds: string[]
  setActiveSelectedAnswerIds: Function
  syncSelectedAnswersToCache: Function
  activeCachedAnswers: Answer[]
}

export const AssessmentContext = createContext<AssessmentContextData>({
  submitAnswers: () => {},
  onLoadFollowupQuestions: () => {},
  followupIds: [],
  clearQuestionSelections: () => {},
  lastFollowupAnswers: [],
  parentQuestion: INITIAL_QUESTION,
  currentCategory: { id: '', name: '', slug: '' },
  categoryQuestions: [],
  questionOrder: '',
  onUpdateAnswer: () => {},
  activeSelectedAnswerIds: [],
  setActiveSelectedAnswerIds: () => {},
  syncSelectedAnswersToCache: () => {},
  activeCachedAnswers: []
})

export const AssessmentProvider = ({ children }: any): JSX.Element => {
  const { hasAccessToViewAssessment } = useContext(MicroServiceContext)
  const { updateQuestion, loadFollowupQuestion } = useAssessment()

  const [activeQuestion, setActiveQuestion] = useState<Question>()
  const [activeSelectedAnswerIds, setActiveSelectedAnswerIds] = useState<
    string[]
  >([])
  const {
    employerAssessment,
    updateEmployerAssessment,
    refetchEmployerAssessment
  } = useEmployerAssessment()

  useEffect(() => {
    return () => {
      refetchEmployerAssessment()
    }
  }, [])
  const { slug, questionOrder } = useParams()
  const { employerAssessmentCategories } = employerAssessment

  const currentCategoryIndex = employerAssessmentCategories.findIndex(
    (category: any) => category.slug === slug
  )
  const currentCategory = employerAssessmentCategories[currentCategoryIndex]
  const parentQuestion: Question =
    currentCategory?.employerAssessmentQuestions[Number(questionOrder) - 1] ||
    {}
  const activeCachedAnswers = parentQuestion.assessmentAnswers?.filter(
    (answer: Answer) => activeSelectedAnswerIds.includes(answer.id)
  )
  const followupQuestionIds = parentQuestion.assessmentAnswers?.find(
    (assessmentAnswer: Answer) =>
      parentQuestion.selectedAnswerIds.includes(assessmentAnswer.id) &&
      assessmentAnswer.hasFollowupQuestion
  )?.followupQuestionIds

  const isThereLoadedFollowupQuestions =
    parentQuestion.employerFollowupQuestions?.length !== 0

  useEffect(() => {
    const { employerFollowupQuestions, selectedAnswerIds } = parentQuestion
    const checkFollowupQuestionsAsActiveQuestion = () => {
      if (employerFollowupQuestions?.length > 0) {
        const firstNotAnsweredQuestion = employerFollowupQuestions?.find(
          (followupQuestion: Question) =>
            followupQuestion.selectedAnswerIds?.length === 0
        )
        if (firstNotAnsweredQuestion !== undefined) {
          setActiveQuestion(firstNotAnsweredQuestion)
        }
        if (firstNotAnsweredQuestion === undefined) {
          setActiveQuestion(employerFollowupQuestions.at(-1))
        }
      }
    }
    checkFollowupQuestionsAsActiveQuestion()

    const checkParentQuestionAsActiveQuestion = () => {
      const isparentQuestionAnswered = selectedAnswerIds?.length !== 0
      if (isparentQuestionAnswered === false) {
        setActiveQuestion(parentQuestion)
        return
      }
      if (isThereLoadedFollowupQuestions === false) {
        setActiveQuestion(parentQuestion)
      }
    }
    checkParentQuestionAsActiveQuestion()
  }, [isThereLoadedFollowupQuestions, parentQuestion])

  useEffect(() => {
    const prefillAnswers = () => {
      if (isThereLoadedFollowupQuestions) {
        setActiveSelectedAnswerIds(
          parentQuestion?.employerFollowupQuestions.at(-1)!.selectedAnswerIds
        )
      } else {
        setActiveSelectedAnswerIds(parentQuestion.selectedAnswerIds)
      }
    }
    if (parentQuestion.id) {
      prefillAnswers()
    }
  }, [isThereLoadedFollowupQuestions, parentQuestion])

  const submitAnswers = (callback?: () => void) => {
    if (!hasAccessToViewAssessment) return

    updateQuestion({
      variables: {
        id: parentQuestion.id,
        employerAssessmentId: employerAssessment.id,
        selectedAnswerIds: parentQuestion.selectedAnswerIds,
        followupQuestions: parentQuestion.employerFollowupQuestions?.map(
          (followupQuestion: Question) => ({
            id: followupQuestion.assessmentQuestionId || followupQuestion.id,
            selectedAnswerIds: followupQuestion.selectedAnswerIds
          })
        )
      }
    }).then(() => callback && callback())
  }
  const syncSelectedAnswersToCache = () => {
    onUpdateAnswer([...activeSelectedAnswerIds])
  }

  const onUpdateAnswer = (answers: any) => {
    updateEmployerAssessment((prev: any) => {
      const result = JSON.parse(JSON.stringify({ ...prev }))

      if (activeQuestion!.employerFollowupQuestions?.length === 0) {
        result.employerAssessment.employerAssessmentCategories[
          currentCategoryIndex
        ].employerAssessmentQuestions[
          parentQuestion.order - 1
        ].selectedAnswerIds = answers
      } else {
        result.employerAssessment.employerAssessmentCategories[
          currentCategoryIndex
        ].employerAssessmentQuestions[
          parentQuestion.order - 1
        ].employerFollowupQuestions[
          activeQuestion?.subOrder! - 1
        ].selectedAnswerIds = answers
      }
      return result
    })
  }

  const onLoadFollowupQuestions = async () => {
    const {
      data: { assessmentQuestions }
    } = await loadFollowupQuestion({
      variables: {
        assessmentQuestionIds: JSON.stringify(followupQuestionIds)
      }
    })
    updateEmployerAssessment((prev: any) => {
      const result = JSON.parse(JSON.stringify({ ...prev }))

      result.employerAssessment.employerAssessmentCategories[
        currentCategoryIndex
      ].employerAssessmentQuestions[
        parentQuestion.order - 1
      ].employerFollowupQuestions = assessmentQuestions.map(
        (assessmentQuestion: Question) => {
          const newAssessmentQuestion = {
            ...assessmentQuestion,
            assessmentQuestionId: ''
          }
          newAssessmentQuestion.selectedAnswerIds = []
          return newAssessmentQuestion
        }
      )

      return result
    })
  }
  const clearQuestionSelections = (questionId: string) => {
    updateEmployerAssessment((prev: any) => {
      const result = JSON.parse(JSON.stringify({ ...prev }))
      if (parentQuestion.id === questionId) {
        result.employerAssessment.employerAssessmentCategories[
          currentCategoryIndex
        ].employerAssessmentQuestions[
          parentQuestion.order - 1
        ].selectedAnswerIds = []
        result.employerAssessment.employerAssessmentCategories[
          currentCategoryIndex
        ].employerAssessmentQuestions[
          parentQuestion.order - 1
        ].employerFollowupQuestions = []
      } else {
        result.employerAssessment.employerAssessmentCategories[
          currentCategoryIndex
        ].employerAssessmentQuestions[
          parentQuestion.order - 1
        ].employerFollowupQuestions = result.employerAssessment.employerAssessmentCategories[
          currentCategoryIndex
        ].employerAssessmentQuestions[
          parentQuestion.order - 1
        ].employerFollowupQuestions.map(
          (employerFollowupQuestion: Question) => {
            return { ...employerFollowupQuestion, selectedAnswerIds: [] }
          }
        )
      }

      return result
    })
  }
  return (
    <AssessmentContext.Provider
      value={{
        submitAnswers,
        activeSelectedAnswerIds,
        setActiveSelectedAnswerIds,
        activeQuestion,
        clearQuestionSelections,
        followupIds: followupQuestionIds || [],
        onLoadFollowupQuestions,
        lastFollowupAnswers: [],
        parentQuestion,
        currentCategory,
        categoryQuestions: [],
        questionOrder,
        activeCachedAnswers,
        onUpdateAnswer,
        syncSelectedAnswersToCache
      }}
    >
      {parentQuestion.id && children}
    </AssessmentContext.Provider>
  )
}
