import React, { ReactElement, useContext } from 'react'
import { Route, Routes, useNavigate } from 'react-router-dom'
import { MicroServiceContext } from 'context/MicroService'
import HistoryDashboard from './HistoryDashboard'

const AssessmentHistory = (): ReactElement => {
  const navigate = useNavigate()
  const { hasAccessToViewAssessment } = useContext(MicroServiceContext)

  if (!hasAccessToViewAssessment) {
    navigate('/')
  }

  return (
    <Routes>
      <Route path='' element={<HistoryDashboard />} />
    </Routes>
  )
}

export default AssessmentHistory
