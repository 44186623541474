import React from 'react'
import { CircularProgress, Box, Grid, Typography, Divider } from '@mui/material'
import EmptyState from './EmptyState'
import { GoalNode } from 'model/goalManager'
import { Color } from 'components/SharedStyles'
import { GoalTag } from 'components'
import { styled } from '@mui/material/styles'
import moment from 'moment' // Todo: change to date-fns
interface IProps {
  goals: GoalNode[]
}
const TwoLineTruncateTypography = styled(Typography)`
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box !important;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  white-space: normal;
  width: 80%;
`
const parseGoalCategoryToFull = (category: string) => {
  const letters = category?.split('_')
  return `${letters[0].toUpperCase()} & ${letters[1]?.toUpperCase()}`
}

const GoalList = ({ goals }: IProps) => {
  if (goals === undefined) {
    return (
      <Box sx={{ display: 'flex' }} paddingTop='48px' paddingBottom='48px'>
        <CircularProgress size={40} thickness={4} sx={{ margin: 'auto' }} />
      </Box>
    )
  }
  if (goals.length === 0) return <EmptyState />
  return (
    <>
      {goals.map(goalNode => {
        const goal = goalNode.node
        return (
          <div key={goal.id}>
            <Divider sx={{ width: '100%' }} />
            <Grid container padding='24px 0'>
              <Grid item container lg={6} xs={4}>
                <TwoLineTruncateTypography variant='body1' color={Color.black}>
                  {goal.customTitle || goal.title}
                </TwoLineTruncateTypography>
              </Grid>

              <Grid item container lg={3.5} xs={3} paddingLeft='12px'>
                <GoalTag label={parseGoalCategoryToFull(goal.category)} />
              </Grid>

              <Grid
                item
                container
                lg={2}
                xs={3}
                flexWrap='nowrap'
                paddingLeft='12px'
              >
                <Typography variant='body1' color={Color.black}>
                  {moment(goal.completedAt).format('MMMM YYYY')}
                </Typography>
              </Grid>
            </Grid>
          </div>
        )
      })}
    </>
  )
}

export default GoalList
