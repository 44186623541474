import { gql } from '@apollo/client'

export const CHECK_HAS_COMPLETED_ANY_ASSESSMENT = gql`
  query ($year: String) {
    employerAssessmentHistory(year: $year) {
      latestAssessment {
        id
        acquiredScore
        endAt
        employerAssessmentCategories {
          id
          name
          slug
          acquiredScore
        }
      }
    }
  }
`

export const GET_EMPLOYER_ASSESSMENT_HISTORY = gql`
  query ($year: String) {
    employerAssessmentHistory(year: $year) {
      latestAssessment {
        id
        acquiredScore
        endAt
        employerAssessmentCategories {
          id
          name
          slug
          acquiredScore
          totalScore
          employerAssessmentQuestions {
            id
            questionText
            selectedAnswerIds
            employerFollowupQuestions {
              id
              questionText
              assessmentAnswers {
                id
                answerText
              }
              selectedAnswerIds
            }
            assessmentAnswers {
              id
              answerText
            }
          }
        }
      }
      firstQuarter {
        id
        acquiredScore
        endAt
        employerAssessmentCategories {
          id
          name
          slug
          acquiredScore
        }
      }
      secondQuarter {
        id
        acquiredScore
        endAt
        employerAssessmentCategories {
          id
          name
          slug
          acquiredScore
        }
      }

      thirdQuarter {
        id
        acquiredScore
        endAt
        employerAssessmentCategories {
          id
          name
          slug
          acquiredScore
        }
      }

      fourthQuarter {
        id
        acquiredScore
        endAt
        employerAssessmentCategories {
          id
          name
          slug
          acquiredScore
        }
      }
    }
  }
`
