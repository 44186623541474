import React from 'react'
import { Typography, Modal, Box, IconButton, Button } from '@mui/material'

import { Color } from 'components/SharedStyles'
import CloseIcon from '@mui/icons-material/Close'

const FollowUpModal = ({ followupCallback, setFollowupCallback }: any) => {
  return (
    <Modal
      open={followupCallback}
      onClose={() => setFollowupCallback(undefined)}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          width: '600px',
          padding: '38px 24px 24px 24px',
          backgroundColor: Color.white,
          transform: 'translate(-50%, -50%)',
          borderRadius: '8px'
        }}
      >
        <IconButton
          disableRipple
          onClick={() => setFollowupCallback(undefined)}
          sx={{
            position: 'absolute',
            top: 24,
            right: 24,
            padding: 0,
            '&:hover': {
              backgroundColor: 'transparent'
            }
          }}
        >
          <CloseIcon sx={{ color: 'black', fontSize: '20px' }} />
        </IconButton>
        <Typography variant='h3' sx={{ marginBottom: '26px' }}>
          Are you sure?
        </Typography>
        <Typography variant='body1' sx={{ margin: '0 0 26px 0', width: '90%' }}>
          All sections of this question must be completed for your selected
          answers to be saved.
        </Typography>
        <Box sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
          <Button
            variant='contained'
            disableRipple
            onClick={() => {
              followupCallback()
              setFollowupCallback(undefined)
            }}
            sx={{
              backgroundColor: Color.black,
              color: Color.white,
              '&:hover': {
                backgroundColor: Color.black
              }
            }}
          >
            Continue without Saving
          </Button>
          <Button
            variant='contained'
            disableRipple
            onClick={() => setFollowupCallback(undefined)}
            sx={{
              backgroundColor: Color.grey100,
              color: Color.black,
              marginRight: '16px',
              '&:hover': {
                backgroundColor: Color.grey100
              }
            }}
          >
            Cancel and go back
          </Button>
        </Box>
      </Box>
    </Modal>
  )
}

export default FollowUpModal
