import { useState, useEffect, useContext } from 'react'
import { useQuery, gql } from '@apollo/client'
import { MicroServiceContext } from 'context/MicroService'

export const RECOMMENDATION = gql`
  query Recommendation {
    recommendations {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          employerGoalId
          category
          title
          status
          employerAssessmentId
          assessmentQuestionText
        }
      }
    }
  }
`

const useRecommendations = () => {
  const [recommendationConnection, setRecommendationConnection] =
    useState<any>()
  const { currentAccount } = useContext(MicroServiceContext)
  const { data: rawRecos, loading } = useQuery(RECOMMENDATION, {
    variables: { employerId: currentAccount.employer.id }
  })

  useEffect(() => {
    if (rawRecos) {
      const { recommendations: recos } = rawRecos
      setRecommendationConnection(recos)
    }
  }, [rawRecos])

  return { recommendationConnection, isLoading: loading }
}

export default useRecommendations
