/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-shadow */
import { useState, useEffect, useContext } from 'react'
import { useQuery, gql } from '@apollo/client'
import { GoalNode } from 'model/goalManager'
import { MicroServiceContext } from 'context/MicroService'

const GoalInput = gql`
  fragment goalInput on EmployerGoal {
    customTitle
    activeStatus
    startDate
    endDate
    owner {
      id
      name
      primaryEmail
    }
    members {
      id
      name
      primaryEmail
    }
    note
    isCompleted
    completedAt
  }
`
export const EMPLOYER_GOALS = gql`
  query EmployerGoal($employerId: String!) {
    employerGoals(employerId: $employerId, first: 50) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        cursor
        node {
          id
          title
          employerId
          activeStatus
          category
          onTrack
          phase
          description
          assessmentVersion
          assessmentQuestion
          kcUrls
          goalId
          scoreDelta
          ...goalInput
        }
      }
    }
  }
  ${GoalInput}
`
const useGoals = () => {
  const [employerGoals, setEmployerGoals] = useState<any>()
  const { currentAccount } = useContext(MicroServiceContext)
  const { data: rawGoals } = useQuery(EMPLOYER_GOALS, {
    variables: { employerId: currentAccount.employer.id }
  })

  useEffect(() => {
    if (rawGoals) {
      const { employerGoals } = rawGoals
      setEmployerGoals(employerGoals)
    }
  }, [rawGoals])

  const getRecentFiveCompletedGoals = () => {
    if (employerGoals) {
      const items = employerGoals.edges

      return items
        .filter((item: GoalNode) => item.node.isCompleted)
        .sort(
          (a: GoalNode, b: GoalNode) =>
            new Date(b.node.completedAt).getTime() -
            new Date(a.node.completedAt).getTime()
        )
        .slice(0, 5)
    }
  }

  return { employerGoals, getRecentFiveCompletedGoals }
}

export default useGoals
