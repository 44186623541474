import React, { createContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { EmployerAssessment } from 'model/assessment'
import { INITIAL_EMPLOYER_ASSESSMENT } from './constants'
import useEmployerAssessment from 'hooks/useEmployerAssessment'
import useAssessmentOverview from 'hooks/useAssessmentOverview'
import useEmployerAssessmentHistory from 'hooks/useEmployerAssessmentHistory'

type AssessmentOverviewContextData = {
  employerAssessment: EmployerAssessment
  hasActiveAssessment?: boolean
  assessmentCompleted?: boolean
  onSubmitAssessment: (input: any, navigateTo?: string) => void
  hasCompletedAnAssessment?: boolean
  employerAssessmentCategories: any
  progress: number
  createEmployerAssessment: Function
}

export const AssessmentOverviewContext =
  createContext<AssessmentOverviewContextData>({
    employerAssessment: INITIAL_EMPLOYER_ASSESSMENT.employerAssessment,
    onSubmitAssessment: () => {},
    employerAssessmentCategories: {},
    progress: 0,
    createEmployerAssessment: () => {}
  })

export const AssessmentOverviewProvider = ({ children }: any): JSX.Element => {
  const { submitAssessment, createEmployerAssessment } = useAssessmentOverview()

  const { employerAssessment, updateEmployerAssessment } =
    useEmployerAssessment()
  const { checkHasCompletedAnyAssessment, hasCompletedAnAssessment, loading } =
    useEmployerAssessmentHistory()
  const navigate = useNavigate()
  const onSubmitAssessment = async (input: any, navigateTo?: string) => {
    await submitAssessment(input)
    updateEmployerAssessment(() => INITIAL_EMPLOYER_ASSESSMENT)
    navigateTo && navigate(navigateTo)
  }

  useEffect(() => {
    checkHasCompletedAnyAssessment()
  }, [])

  const {
    employerAssessmentCategories = [],
    id = '',
    progress = 0
  } = employerAssessment

  return (
    <AssessmentOverviewContext.Provider
      value={{
        employerAssessment,
        createEmployerAssessment,
        hasActiveAssessment: !!id,
        assessmentCompleted: progress === 100,
        onSubmitAssessment,
        hasCompletedAnAssessment: loading
          ? undefined
          : hasCompletedAnAssessment,
        employerAssessmentCategories,
        progress
      }}
    >
      {children}
    </AssessmentOverviewContext.Provider>
  )
}
