import React, { useContext } from 'react'
import { Button, Box, ButtonProps, BoxProps } from '@mui/material'
import { styled } from '@mui/material/styles'
import { Color } from 'components/SharedStyles'

import { MicroServiceContext } from 'context/MicroService'
import { AssessmentOverviewContext } from 'context/AssessmentOverview'

const StartButtonContainer = styled(Box)<BoxProps>(() => ({
  backgroundColor: Color.blue50,
  boxSizing: 'border-box',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 24,
  borderRadius: 8,
  height: '100%'
}))

const StartButton = styled(Button)<ButtonProps>(() => ({
  height: 60,
  width: 186,
  whiteSpace: 'nowrap'
}))

const AssessmentStartButton = () => {
  const { hasAccessToViewAssessment } = useContext(MicroServiceContext)
  const { createEmployerAssessment } = useContext(AssessmentOverviewContext)
  const handleClick = async () => {
    createEmployerAssessment()
  }

  return (
    <StartButtonContainer>
      <StartButton
        variant='contained'
        disableRipple
        disabled={!hasAccessToViewAssessment}
        onClick={handleClick}
      >
        Start Assessment
      </StartButton>
    </StartButtonContainer>
  )
}

export default AssessmentStartButton
