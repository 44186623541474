import * as Components from '@mathison-inc/components'
import * as Icons from '@mathison-inc/icons'
import GoalTag from './GoalTag'
import Divider from './Divider'

export const {
  InfoBanner,
  Dialog,
  Breadcrumbs,
  EIScoreBlock,
  ScoreDelta,
  DevelopmentStageBar,
  EIStage,
  Color,
  theme
} = Components
export const { ArrowLeftIcon, ArrowRightIcon, ArrowCircleRightIcon } = Icons
export { GoalTag, Divider }
